import React from 'react';

const LivestreamCard = ({ LivestreamLink }) => {
    return (
        <div className="liveStreamBlock">
            <div className='liveStreamBlock-welcomeText'>{`👋 Welcome to Livetream.`}</div>
            <div className='liveStreamBlock-footer'>
                <div className='liveStreamBlock-title'>
                    <a className='live-stream-Link' href={LivestreamLink} target='_blank'>Join Live Stream</a>
                </div>
                <div className='liveStreamBlock-iconWrap'>
                    <i class="fa-solid fa-video"></i>
                </div>
            </div>
        </div>
    );
}

export default LivestreamCard;
