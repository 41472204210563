
import $ from 'jquery';
import React, { Fragment } from 'react';
import { unmountComponentAtNode, createPortal } from "react-dom";
import VGPopUp from './VGPopUp';
import { utils } from '../../utils/Utils';
import { LivestreamType, MobileCallBackAction } from '../../Vagaroconnect/Binding/ChatBoatEnums';

const CommonMessagepopup = (props) => {
    const Data = props.data

    const PopupTitle = () => {
        if (Data.isTextMessagingActivated === false && props.isPlanActive === false) {
            return <Fragment></Fragment>;
        }
        if (props.isPlanActive === true) {
            return (
                <Fragment></Fragment>
            )
        }
        if (props.isRestricted === true) {
            return (
                <Fragment>Restricted Content</Fragment>
            )
        }
        if (props.isLiveStream === true) {
            return (
                <Fragment>Live Stream</Fragment>
            )
        }
        if (props.isEnableLiveStream === true) {
            return (
                <Fragment>Alert</Fragment>
            )
        }
    };

    const PopupBody = () => {
        if (Data.isTextMessagingActivated === false && props.isPlanActive === false) {
            return (
                <Fragment>
                    <div className='upgradeText-block'>
                        <img className='upgradeText-block_img' src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_in-app_upgrade2.png" alt="" />
                        <h2 className='upgradeText-block_title'>Upgrade to a Text Plan</h2>
                        <p className='upgradeText-block_info'>Customer is currently offline. Upgrade to a text plan to continue sending the message as SMS.</p>
                    </div>
                </Fragment>
            )
        }
        if (props.isPlanActive === true) {
            return (
                <Fragment>
                    <div className='textingUnlocked-block'>
                        <img className='textingUnlocked-block_img' src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/textingUnlockedImg-2.png" alt="" />
                        <h2 className='textingUnlocked-block_title'>Texting Unlocked</h2>
                        <p className='textingUnlocked-block_info'>Congratulations! Start texting your customers with the power of SMS. It may take up to 48 hours for your number to be approved.</p>
                    </div>
                </Fragment>
            )
        }
        if (props.isRestricted === true) {
            return (
                <Fragment>
                    <div className='restricted-questionBlock'>
                        <h4 className='restricted-question'>What does that mean?</h4>
                        <p className='restricted-answer'>There are a list of restricted content and language that the phone company prohibits businesses from sending in a text message to their customers.<br /><br />
                            If the content is deemed is illegal, harmful, unwanted, inappropriate, objectionable, it will be flagged. Also any variation in spelling of that content can also be flagged.</p>
                    </div>
                    <div className='restricted-questionBlock'>
                        <h4 className='restricted-question'>What can I do?</h4>
                        <p className='restricted-answer'>Remove the restricted content, and then you can send your text message.</p>
                    </div>
                </Fragment>
            )
        }
        if (props.isLiveStream === true) {
            return (
                <Fragment>
                    <div className='textingUnlocked-block'>
                        <p className='textingUnlocked-block_info'>Join To Live stream.</p>
                    </div>
                </Fragment>
            )
        }
        if (props.isEnableLiveStream === true) {
            return (
                <Fragment>
                    <div className='textingUnlocked-block'>
                        <p className='textingUnlocked-block_info'>Please Enable Live Stream</p>
                    </div>
                </Fragment>
            )
        }
    }

    const Footer = () => {
        if (Data.isTextMessagingActivated === false && props.isPlanActive === false) {
            return (
                <Fragment>
                    <button className="btn btn-secondary vcgradientbtn vcsecondary-btn upgradeText-cancelbtn" onClick={() => handlepopupClose()}>
                        <div className='vcsecondaryInner'>
                            Cancel
                        </div>
                    </button>
                    <button className="btn btn-primary upgradeText-viewPlanBtn vcgradientbtn vcprimary-btn" onClick={() => { OpenTextMessagingPlanPopup(false); handlepopupClose(); }}>View Plans</button>
                </Fragment >
            )
        }
        if (props.isPlanActive === true) {
            return (
                <Fragment>
                    <button className="btn btn-primary textingUnlocked-okBtn vcgradientbtn vcprimary-btn" onClick={() => handlepopupClose()}>OK</button>
                </Fragment >
            )
        }
        if (props.isRestricted === true) {
            return (
                <Fragment>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => handlepopupClose()}>OK</button>
                </Fragment >
            )
        }
        if (props.isLiveStream === true) {
            return (
                <Fragment>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => handlepopupClose()}>close</button>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => HandleOpenLivetreamUrl()}>Open Live stream</button>
                </Fragment >
            )
        }
        if (props.isEnableLiveStream === true) {
            return (
                <Fragment>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => handlepopupClose()}>close</button>
                    <button className="btn btn-primary vcgradientbtn vcprimary-btn" onClick={() => EnableLiveSream()}>Enable Live stream</button>
                </Fragment >
            )
        }
    }

    const OpenTextMessagingPlanPopup = (isEnable2WaySMS) => {
        if (props.initialData.TextMessagingModifyRights.toLowerCase() === "false") {
            utils.ShowAlert("Alert", "You Don't Have Rights!!", "Close");
            return <></>;
        }
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        let isandroid = /VagaroAndroidPhone/i.test(navigator.userAgent);
        let isandroidTab = /VagaroAndroidTablet/i.test(navigator.userAgent);

        if (props.isAndroidIOSMobile || isIpad || isIpadold || isandroid || isandroidTab) {
            utils.CallBackGivenToMobile(MobileCallBackAction.OpenTextMessaging, "")
        } else {
            var jsonData = JSON.stringify({ isEnable2WaySMS: isEnable2WaySMS }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "OpenTextMessagingPlanPopup","Data": "' + jsonData + '"}', '*');
        }
    }


    const handlepopupClose = () => {
        if (props.isRestricted) {
            $("body").removeClass("ris-popup");
        }
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };

    const HandleOpenLivetreamUrl = () => {
        if (props.isFromConnectApp) {
            if (props?.isIOSMobile && props?.isCustomerChat) {
                utils.callbackTOMobileforLiveStream(MobileCallBackAction.CustomerLiveStream, { "encConverstationId": props.ConversationId, "EncMerchantId": props.initialData.encStaticMerchantid, "EncUserid": props?.initialData?.encStaticUserid, "v2api": props?.initialData?.VagaroCoreAPIURLV2, "url": "https://livestream.bookitall.com"  });
            }
            if (props.isAttendee) {
                utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLiveStreamLink, { "ConversationId": props.ConversationId, "Type": LivestreamType.Attendee })
            } else {
                utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLiveStreamLink, { "ConversationId": props.ConversationId, "Type": LivestreamType.Host })
            }
        } else {
            window.parent.postMessage('{"eventType": "redirectToNewTab","Data": "' + props.data + '"}', '*');
        }
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };

    const EnableLiveSream = () => {
        if (props.isFromConnectApp) {
            utils.CallBackGivenToMobile(MobileCallBackAction.OpenLiveStream, "");
        } else {
            window.parent.postMessage('{"eventType": "OpenLiveStream","Data": ""}', '*');
        }
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
    };

    const popUpShow = () => {
        setTimeout(() => {
            return true
        }, 100);
    }

    return createPortal(
        <VGPopUp
            show={popUpShow}
            title={PopupTitle()}
            onHide={() => handlepopupClose()}
            body={PopupBody()}
            footer={Footer()}
            dialogClassName={props.isPlanActive === false ? "vgn-modal-alert vgn-modal-small modal-mb-half upgradeText-modal" : props.isRestricted === true ? "vgn-modal-alert vgn-modal-small modal-mb-half restrictedText-modal" : props.isLiveStream === true ? "vgn-modal-alert vgn-modal-small modal-mb-half livestream-modal" : "vgn-modal-alert vgn-modal-small modal-mb-half textingUnlocked-modal"}
            isShowFooter={true}
            isStatic={props.isFromConnectApp && true}
        />,
        document.getElementById("OpenDialog1")
    )
}

export default CommonMessagepopup;