import React, { Fragment, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import MultiGroupSelectEmployee from '../component/Controls/MultiGroupSelectEmployee';
import { AsyncAjaxDelete, AsyncAjaxGet, AsyncAjaxPost, AsyncAjaxPut, HeaderDetail, SyncAjaxDelete } from '../component/Common/AsyncAjaxServiceV2';
import { ResponseCode, isAndroidIOSTabIpad, utils, upsertChannelTimer, getRecentConversationData, removeConversationItem, StoreLocalConversation, getConversationData, HandleHistory, isIOSTabIpad } from '../utils/Utils';
import $ from "jquery";
import './Vagaroconnect.scss';
import { EmployeeDetailPopover, EmployeeDetails } from './EmployeeDetails';
import ChatArea from './ChatArea';
import { useState } from 'react';
import SliderComponent from '../component/Common/SliderComponent';

import CustommerDetails from './CustommerDetails';
import { ChatListSelection, CommonMethods, ConnectLivestreamType, LeftControlNames, LivestreamType, MassageTypeEnum, MobileCallBackAction, NewDummyData, SelectionControlTypeEnum, StatusEnum, ThunkApiEnum, VagaroConnectMessageType } from './Binding/ChatBoatEnums';
import { StenCiledContaint } from './Binding/StenciedData';
import EditoerComponent from './Editoer/EditoerComponent';
import Convertions from './Convertions';
import MerchantsSingleSelecttwo from '../component/Controls/MerchantsSingleSelecttwo';
import { useDispatch } from 'react-redux';
import useGenerateToken from '../component/Hooks/useGenerateToken';
import { useChatContext } from './Store/ChatProvider';
import ProgressBar from 'react-bootstrap/ProgressBar';
import SuccessAlert from '../component/Common/SuccessAlert';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Usericon } from './CommonComponent/Icons';
import { useSwipeable } from 'react-swipeable';
import CommonMessagepopup from '../component/Common/CommonMessagepopup';
import CommonPopup from '../component/Common/CommonPopup';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { RemoveTypingUser, ResetTypingUser } from '../component/Slice/TypingUser';
import { NewFeaturePopup, NewFeatures, NewFeaturesList, NewFeaturesString } from '../component/Common/FeaturesPopup';
import VagaroAI from './setting/VagaroAI';
import { ThunkAPI } from '../component/Common/AsyncThunk';
import { setStatus } from '../component/Slice/BusinessSettings';
import ReactDOMServer from 'react-dom/server';
import VCard from './VCard';
import LivestreamCard from './LivestreamCard';

const VagaroConnect = (props) => {
    const {
        chatSelection,
        chatSelectionID,
        ChatboadSelection,
        connectionStatus,
        connection,
        CommonMethod,
        CommonMethodRef,
        textmarketing,
        Addtextmarketing,
        AddCommonMethod,
        setIsCustomer,
        smsavail,
        setConnection,
        issmsavail, SetFirstUserData, FirstUserData, StoreTextMarketingData, UserTextMarketingData, badWordStore, EmployeeDataStore, GetEmployeeIdFromHas, setChatSelectionID, setAIControl, isLivestreamEnable, setLivestreamEnable } = useChatContext();


    const {
        isFromConnectApp,
        isIOSMobile,
        setBusinessedtailPageCustomer,
        merchantId,
        LoginUserIDV2,
        ShowFirst,
        setFirstShow,
        ModifyEmployee,
        ModifyCustomer,
        ViewEmployee,
        ViewCustomer,
        setShowFirstLoader,
        ReactLastName,
        ReactFirstName,
        Domain,
        LoginUserIdHash,
        ReactUserID,
        ManageOtherPeopleCalendarViewRights,
        FullName,
        ReactBusinessName,
        defaultSize,
        newChat,
        setNewChat,
        ReactProfileImage,
        ReactCdnUrl,
        ManageCustomerViewRights,
        isAndroidIOSMobile,
        isCustomerChat,
        isIframeOpened,
        setIsIframeOpened,
        isLeftOption,
        setLeftOption,
        isIframLoad,
        initialData,
        activeTabState,
        ManageCustomerModifyRights,
        MerchantChannelId,
        CountryID,
        ReactBusinessId,
        ModifyFeatureSubscription
    } = props;
    const [convertionData, setConvertionData] = useState([]);
    const [convertionDataCount, setConvertionDataCount] = useState([]);
    const [isNewChatStart, setNewChatStart] = useState(false);
    const convertionDataRef = useRef([]);
    const [isNewChat, setIsNewChat] = useState(true);
    const [NewChatRender, setNewChatRender] = useState(true);
    const isNewChatFreeez = useRef(false);
    const LoadDataCountRef = useRef(0);
    const ConversationCountRef = useRef(0);
    const [newChatSelectedEmployee, setnewChatSelectedEmployee] = useState("");
    const [allserviceprovider, setallserviceprovider] = useState([]);
    const [ServiceproviderasperMultilocation, setServiceproviderasperMultilocation] = useState([]);
    const [serviceprovider, setserviceprovider] = useState({ optionSelected: null });
    const [serviceproviderName, setserviceproviderName] = useState("");
    const [UserFromConversation, setUserFromConversation] = useState(false);
    const dispatch = useDispatch();

    const leftsideOptionPenalRef = useRef(null);
    const leftsideConversetionPenalRef = useRef(null);
    const rightsidePenal = useRef(null);
    const matchMediaScreen_1024 = useRef(window.matchMedia("screen and (max-width: 1024px)"));
    const matchMediaScreen_767 = useRef(window.matchMedia("screen and (max-width: 767px)"));
    const [messageConvertions, setMessageConvertions] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [tempChatData, setTempChatData] = useState([]);
    const [expandMinimizeShow, setExpandMinimizeShow] = useState(defaultSize);
    const [upgradeUpCellShow, setupgradeUpCellShow] = useState(true);
    const [expandMinimizeChatBox, setExpandMinimizeChatBox] = useState(1);
    const [chatAreaShow, setChatAreaShow] = useState(false);
    const EditoerInput = EditoerComponent;
    const [convertionPageIndex, setConvertionPageIndex] = useState(1);
    const [paging, setPaging] = useState(true);
    const [tempConvertionData, setTempConvertionData] = useState([]);
    const [tempEmployye, settempEmployye] = useState([]);
    const [tempCustomer, settempCustomer] = useState([]);
    const [isConversionDataChanged, setConversionChanged] = useState(0);
    const [allserviceproviderforaddmember, setallserviceproviderforaddmember] = useState([]);
    const [unReadCountCustomer, setUnReadCountCustomer] = useState(0);
    const [unReadCountEmployee, setUnReadCountEmployee] = useState(0);
    const middleArea = useRef(null);
    const [pinCount, setPinCount] = useState(0);
    const [unReadConversionCustomer, setUnReadConvertionCustomer] = useState([]);
    const [unReadConversionEmployee, setUnReadConvertionEmployee] = useState([]);
    const [searchText, setsearchText] = useState("");
    const [customerData, setCustomerData] = useState(null);
    const [restrictResize, setrestrictResize] = useState(true);
    const [sendResponse, setsendResponse] = useState();
    const customerDataRef = useRef(null);
    const [activeKey, setActiveKey] = useState(['0', '1']);
    const [fromTextMessengerPhone, setfromTextMessengerPhone] = useState();
    const [istextmarketing, setistextmarketing] = useState(null);
    const [isStenciledForSMS, setStenciledForSMS] = useState(true);
    const isScrollEnable = useRef(true);
    const [scrollupdate, setscrollupdate] = useState(0);
    const RedirectToCustommerbyHasFlagRef = useRef(null);
    const [isViewChanging, setIsViewChanging] = useState(false);
    const groupMemberArrRef = useRef(null);
    const serviceProviderHashId = useRef(null);
    const serviceProviderDataRef = useRef(null);
    const conClickScroll = useRef(false);
    const [lastSeletion, setLastSelection] = useState([]);
    const [cancelToken, setCancelToken] = useState(null);
    const [connectionId, setConnectionId] = useState("");
    const sideoption = useRef(LeftControlNames.Customer);
    const [noRecord, setnoRecord] = useState(false);
    const [noRecordunread, setnoRecordUnread] = useState(false);
    const [chatListSelection, setChatListSelection] = useState(ChatListSelection.All);
    const firstTimeActiveChannelCalled = useRef(false);
    const pushNotificationConversationId = useRef("");
    const pushNotificationMessageId = useRef("");
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 0;
    let isIpadold = /iPad/i.test(navigator.userAgent);
    let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);
    const isFromAppResumeCallBack = useRef(false);
    const customerFromCustomerDetails = useRef("");
    const employeeGroupDetails = useRef("");
    const [timer, setTimer] = useState(upsertChannelTimer); // Initial timer set to 2 minutes in milliseconds
    const recentChatSelectionRef = useRef("");
    const multiLocationBusinessIdsRef = useRef("");
    const tablet = isAndroidIOSTabIpad()
    const ipad = isIOSTabIpad()
    const navigate = useNavigate();
    const [isFeaturePopup, setIsFeaturePopup] = useState(false);


    const handleAccordionToggle = (eventKey) => {
        setActiveKey(eventKey === activeKey ? null : eventKey);
    };
    const setConversetionChatSelection = (data, fromConversion = false, callfromConversionApi, fromwebhook = false, isFromAppBackground = false) => {
        // setIsViewChanging(true);
        // setSelecetedCustomerHash(data.customerIdHash);
        if (expandMinimizeShow !== 0) {
            if (callfromConversionApi !== undefined && callfromConversionApi === false) {
                if (typeof window && window.parent !== undefined) {
                    var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'ifream-chatActive' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
                    window.parent.postMessage('{"eventType": "iFrameRemoveClass","Data": "' + jsonData + '"}', '*');
                }
                setChatAreaShow(false);
            } else {
                if (typeof window && window.parent !== undefined) {
                    jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'ifream-chatActive' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
                    window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');
                }
                setChatAreaShow(true);
                AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("messagesAreaheaderback") })
            }
        }
        if (data.conversationId === "" && data.name === "New Chat") {
            setNewChat(true)
        } else {
            setNewChat(false)
        }
        if (chatSelection.ConversationId !== data.conversationId) {
            setPageIndex(1);
            setMessageConvertions([]);
            setTempChatData([]);
        }
        var id = "";
        var name = "";
        var conversationId = "";
        if (LeftControlNames.Customer === isLeftOption) {
            var isBlocked = false;
            if (fromConversion) {
                id = data.customerId;
                name = data.firstName + " " + data.lastName;
                conversationId = data.conversationId;
                isBlocked = data.isBlocked || false
            } else {
                id = data.userId;
                name = data.firstName + " " + data.lastName;
                conversationId = data.conversationId;
            }
            ChatboadSelection(SelectionControlTypeEnum.Customer, id, conversationId, name, null, data, false, isBlocked);
        } else {
            var isFromGroup = false;
            if (fromConversion) {
                if (data.isGroupChat && data.isGroupChat === true) {
                    id = isFromAppBackground ? data.customerId : data.conversationId;
                    conversationId = data.conversationId;
                    name = data.name;
                    isFromGroup = true
                } else {
                    id = data.customerId;
                    conversationId = data.conversationId;
                    name = data.firstName + " " + data.lastName;
                }
            }
            else if (fromwebhook) {
                if (data.isGroupChat && data.isGroupChat === true) {
                    id = data.conversationId;
                    conversationId = data.conversationId;
                    name = data.fullName;
                    isFromGroup = true;
                    data = null;
                } else {
                    id = data.customerId;
                    conversationId = data.conversationId;
                    name = data.fullName;
                }

            }
            else {
                id = data.memberId
                name = data.fullName
            }
            ChatboadSelection(SelectionControlTypeEnum.Employee, id, conversationId, name, null, data, isFromGroup);
        }
        if (isAndroidIOSMobile) {
            HandleHistory('push', "conversation");
        }
    };
    /* for design use only*/
    const [customer, setCustomer] = useState("");
    const CustomerId = useRef("");
    // START: for Toogle left side
    const [employeeData, setEmployeeData] = useState(null);
    const ToggleSidebar = () => {
        if (leftsideOptionPenalRef.current && leftsideOptionPenalRef.current.className.includes("leftside-open")) {
            leftsideOptionPenalRef.current.className = "leftside col leftside-collepas";
        } else {
            leftsideOptionPenalRef.current.className = "leftside col leftside-open";
        }
        if (window.OnResizeSelectCustomer !== undefined) {
            window.OnResizeSelectCustomer();
        }
        if (window.OnResizeSelectEmployee !== undefined) {
            window.OnResizeSelectEmployee();
        }
        utils.SetMassageAreaWidth(isCustomerChat === false && expandMinimizeShow === 0);
    }
    // END: for Toogle left side
    const VagaroAPIEnum = {
        GeteEmployeeList: `${merchantId}/VagaroConnect/employees`,
        GetConvertions: `connect/VagaroConnectChat/conversations?loggedInUserId=${LoginUserIDV2}&IsCustomer=${LeftControlNames.Customer === isLeftOption ? true : false}&PageSize=${20}&SearchKey=${searchText}&MerchantId=${merchantId}`,

        SendMessage: `connect/vagaroconnectchat/sendmessage`,
        GetConversionId: `connect/${merchantId}/getconversation`,
        GetUnreadCount: `connect/${merchantId}/messages/unread/count?LoggedInUserId=${LoginUserIDV2}`,
        FirstTimeGetConvertions: `connect/${merchantId}/conversations?loggedInUserId=${LoginUserIDV2}`,
        Textmarketing: `${merchantId}/VagaroConnect/gettextusage?UserId=${LoginUserIDV2}&Category=PromotionText`,
        SendSMS: `connect/${merchantId}/sendsms`,
        readunread: `connect/${merchantId}/readunread?loggedInUserId=${LoginUserIDV2}`,
        TextSend: `${merchantId}/VagaroConnect/text/send`,
        SMSStatus: `connect/${merchantId}/messages/sms/status`,
        upsertchannel: `connect/${merchantId}/upsertchannel`,
        deletechannel: `connect/${merchantId}/channel?UserId=${LoginUserIDV2}`,
        getActivechannel: `connect/${merchantId}/channels`,
        TextValidate: `${merchantId}/VagaroConnect/text/validate`,
        smseligibility: `${merchantId}/VagaroConnect/user/smseligibility`,
        badgecount: `connect/${merchantId}/badgecount?LoggedInUserId=${LoginUserIDV2}`,
        GetBadContainWords: `${merchantId}/VagaroConnect/badcontainwords`,
        ConnectFeature: `connect/${merchantId}/connectfeature?SenderId=${LoginUserIDV2}&Features=`,
        GetLivestream: `${merchantId}/VagaroConnect/savegetzoomconfiguration`,
        joinroom: `merchants/livestreamacs/joinroom`,
        Allbroadcast: `connect/${merchantId}/allbroadcast`,
    };

    const onSettingNavigate = () => {
        dispatch(setStatus(StatusEnum.Idle))
        if (isFromConnectApp) {
            utils.MobileDatePicker(`{"message": "","messageType": 0,"screenTitle": "","screenType": 0,"navType": 0,"action": "138" }`)
        } else {
            navigate('/setting')
        }
    }

    const [newChatData, setnewChatData] = useState(NewDummyData);
    const [newChatEmployeeSelection, setnewChatEmployeeSelection] = useState(false);

    useEffect(() => {
        if (isIframLoad === true) {
            if (initialData.isFromConnectApp === true) {
                if (typeof window.openCustomerChat === 'function') {
                    if (initialData.customerId !== undefined && initialData.userIdHash !== undefined && initialData.customerId !== "" && initialData.userIdHash !== "") {
                        window.openCustomerChat(initialData);
                    }
                }
                if (typeof window.PushNotificationConversation === 'function') {
                    if (initialData.pushNotificationConversationId !== undefined && initialData.pushNotificationMessageId !== undefined) {
                        window.PushNotificationConversation(initialData);
                    }
                }
            } else if (expandMinimizeShow === 2) {
                var SelectedChat = getRecentConversationData(LoginUserIdHash);
                if (SelectedChat !== null) {
                    if (SelectedChat.isForCustomer === false) {
                        onChange(LeftControlNames.Employee);
                    }
                    recentChatSelectionRef.current = SelectedChat;
                }
                console.log(SelectedChat)
            }
            if (!isCustomerChat && initialData.intialState !== 0) {
                ConnectFeature();
            }
        }
    }, [isIframLoad]);


    const checkFirstCallText = useRef(false);
    useEffect(() => {
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        if (isFromConnectApp && convertionData.length > 0 && chatSelectionID === "" && "Customers" === ActiveLeftOption && checkFirstCallText.current === false) {
            CheckTextSMSEligibility();
            checkFirstCallText.current = true;
        }

    }, [convertionData]);


    window.OnChangeCustomerForNewchat = (customerDetails) => {
        if (isNewChat) {
            CommonMethods.Actionclick("messagesAreaheaderback");
        }
        let isCustomerconversation = convertionDataRef.current.filter((d) => d.customerIdHash === customerDetails.userIdHash && d.isGroupChat === false);
        if (isCustomerconversation.length > 0) {
            setIsNewChat(false);
            setTimeout(() => { CommonMethod.Actionclick(customerDetails.userIdHash) }, 20)
            return
        } else {
            GetConvertionIdFromCustomerPage(customerDetails);
        }
    }

    const GetBadContainWords = async () => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.GetBadContainWords,
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        badWordStore(data.data.data);
                    }
                    else {
                        console.log(data)
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Bad Words",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Bad Words",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Bad Words",
                    "",
                    data
                );
            },
            false
        );
    }

    useEffect(() => {
        //GetLivestreamEnable();
    }, [isCustomerChat]);

    const GetLivestreamEnable = async () => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxPost(
            VagaroAPIEnum.GetLivestream,
            "",
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setLivestreamEnable(data.data.data);
                    }
                    else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "GetLivestreamEnable",

                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "GetLivestreamEnable",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "GetLivestreamEnable",
                    data
                );
            },
            false
        );
    }

    //Add By hariom
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    }

    const JoinroomRequest = (isFromCustomer, data) => {
        const RequestData = {};
        RequestData.appConfirmUniqueId = isFromCustomer ? chatSelection.ConversationId : data.conversationId;
        RequestData.attendeeType = 2;
        RequestData.className = "Connect Call";
        RequestData.AttendeeId = initialData.encStaticUserid;
        RequestData.EventType = 3;
        return RequestData;
    }

    const LiveStreamAllbroadcastRequest = () => {
        const RequestData = {};
        RequestData.ChanelId = chatSelection.AllData.isGroupChat ? props.MerchantChannelId.toString() : chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined ? chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "" : chatSelection.AllData.chanelId;
        RequestData.ConversationId = chatSelection.ConversationId;
        RequestData.Type = ConnectLivestreamType.CustomerToBusiness;
        RequestData.MerchantId = initialData.encStaticMerchantid;
        RequestData.LoginUserID = initialData.encStaticUserid === undefined || props.initialData.encStaticUserid === null || props.initialData.encStaticUserid === "" ? props.initialData.LoginUserIdHash : props.initialData.encStaticUserid;
        RequestData.Tempid = initialData.encStaticMerchantid;
        return RequestData;
    }

    const LiveStreamjoinroom = async (isFromCustomer, BroadCastdata) => {
        if (isFromCustomer) {
            setShowFirstLoader(true);
            HeaderDetail.encStaticUserid = initialData.encReactShopOwnerId;
        }
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxPost(
            VagaroAPIEnum.joinroom,
            JoinroomRequest(isFromCustomer, BroadCastdata),
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        if (isFromCustomer) {
                            HeaderDetail.encStaticUserid = initialData.encStaticUserid;
                            const RequestData = {};
                            const Url = `https://livestream.bookitall.com/livestream/meeting?aid=${chatSelection.ConversationId}&mid=${props.initialData.encStaticMerchantid}&uid=${props.initialData.encStaticUserid}&role=a&eventType=3&grp=US02`
                            const AttendeeUrl = `https://livestream.bookitall.com/livestream/meeting?aid=${chatSelection.ConversationId}&mid=${props.initialData.encStaticMerchantid}&uid=${props.initialData.encStaticUserid}&role=a&eventType=3&grp=US02`
                            LiveStreamAllbroadcast();
                            const LivestreamCardMsg = () => {
                                return (
                                    <LivestreamCard
                                        LivestreamLink={AttendeeUrl}
                                    />
                                )
                            }
                            const Livestream = ReactDOMServer.renderToString(LivestreamCardMsg());
                            RequestData.SenderId = LoginUserIDV2;
                            RequestData.fullname = ReactFirstName + " " + ReactLastName;
                            RequestData.message = Livestream;
                            RequestData.MemberIds = customer === undefined || customer === null || customer === "" ? null : customer.current;
                            RequestData.status = 0;
                            RequestData.isnote = false;
                            RequestData.attachments = [];
                            RequestData.isMessageFromCustomer = true;
                            RequestData.isDeleted = false;
                            RequestData.isGroupUpdateMessage = false;
                            RequestData.SentByHash = LoginUserIdHash;
                            RequestData.IsRemove = false;
                            RequestData.chanelId = chatSelection.AllData.isGroupChat ? props.MerchantChannelId.toString() : chatSelection.AllData.chanelId === "" || chatSelection.AllData.chanelId === undefined ? chatSelection.AllData.customerIdHash + props.ReactBusinessId.toString() + "" : chatSelection.AllData.chanelId;
                            RequestData.messageType = VagaroConnectMessageType.CustomerMessageSentByCustomer;
                            RequestData.isCustomerChat = true;
                            RequestData.isAvalible = "";
                            RequestData.isVcard = false;
                            RequestData.isGroupChat = false;
                            RequestData.isLiveStream = true;
                            RequestData.members = [];
                            var TempChatResponse = {
                                messageId: "",
                                tempMessageId: utils.generateCustomMessageID(),
                                message: RequestData.message,
                                messageDate: new Date(),
                                sentByHash: LoginUserIdHash,
                                fullName: RequestData.fullname,
                                isNote: RequestData.isnote,
                                isDeleted: RequestData.isDeleted,
                                sentBy: RequestData.SenderId,
                                isVcard: false,
                                isGroupUpdateMessage: false,
                                isAIMessage: false,
                                isLiveStream: true
                            };
                            const newData = convertionData.map(item => {
                                if (item.conversationId === chatSelection.AllData.conversationId) {
                                    return { ...item };
                                }
                                return item;
                            });
                            window.callBackChatConversation(TempChatResponse);
                            SendMessage(RequestData, '', newData, false);
                            RequestData.tempMessageId = TempChatResponse.tempMessageId;
                            if (isCustomerChat) {
                                RequestData.conversationId = chatSelection.ConversationId;
                            }
                            if (!isFromConnectApp) {
                                window.parent.postMessage('{"eventType": "redirectToNewTab","Data": "' + Url + '"}', '*');
                            } else {
                                utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLiveStreamLink, { "ConversationId": props.ConversationId, "Type": LivestreamType.Attendee })
                            }
                        } else {
                            $("body").addClass('inCommingCall');
                            let Url = "";
                            let isAttendee = true;
                            if (isCustomerChat || BroadCastdata.type === ConnectLivestreamType.EmployeeToEmployee || BroadCastdata.type === ConnectLivestreamType.EmployeeToGroup) {
                                Url = `https://livestream.bookitall.com/livestream/meeting?aid=${BroadCastdata.conversationId}&mid=${props.initialData.encStaticMerchantid}&uid=${props.initialData.encStaticUserid}&role=a&eventType=3&grp=US02`
                            } else {
                                isAttendee = false;
                                Url = `https://livestream.bookitall.com/livestream/meeting?aid=${BroadCastdata.conversationId}&mid=${props.initialData.encStaticMerchantid}&uid=${props.initialData.encStaticUserid}&role=h&eventType=3&grp=US02`
                            }
                            return ReactDOM.render(
                                <CommonMessagepopup
                                    data={Url}
                                    isFromConnectApp={isFromConnectApp}
                                    isAndroidIOSMobile={isAndroidIOSMobile}
                                    isLiveStream={true}
                                    ConversationId={BroadCastdata.conversationId}
                                    isAttendee={isAttendee}
                                    isIOSMobile={isIOSMobile}
                                    initialData={initialData}
                                    isCustomerChat={isCustomerChat}
                                />,
                                document.getElementById("OpenDialog1")
                            );
                        }
                    }
                    else {
                        HeaderDetail.encStaticUserid = initialData.encStaticUserid;
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "LiveStream Joinroom",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    HeaderDetail.encStaticUserid = initialData.encStaticUserid;
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "LiveStream Joinroom",
                        error
                    );
                }
            },
            function OnError(data) {
                HeaderDetail.encStaticUserid = initialData.encStaticUserid;
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "LiveStream Joinroom",
                    data
                );
            },
            false
        );
    }

    const LiveStreamAllbroadcast = async () => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxPost(
            VagaroAPIEnum.Allbroadcast,
            LiveStreamAllbroadcastRequest(),
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {

                    }
                    else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "LiveStream Allbroadcast",

                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "LiveStream Allbroadcast",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "LiveStream Allbroadcast",
                    data
                );
            },
            true
        );
    }

    //Add By hariom


    const getFeature = () => {
        const payloadData = NewFeaturesString;
        const featuresArray = payloadData.split(',');
        const reaction = featuresArray.find(feature => feature !== 'AI');
        if (initialData.IsVConnectAIEnableBetaRelease === true) {
            return payloadData
        } else {
            return reaction
        }
    }

    const ConnectFeature = async () => {
        const access_token = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.ConnectFeature + getFeature(),
            access_token,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        var RowData = data.data.data;
                        var isEnable = false;
                        NewFeaturesList.map(name => {
                            NewFeatures[name].Show = RowData[name]
                            if (RowData[name]) { isEnable = true; }
                            return "";
                        });
                        if (isEnable) {
                            setIsFeaturePopup(true);
                        }
                    }
                    else {
                        console.log(data)
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "ConnectFeature",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "ConnectFeature",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "ConnectFeature",
                    "",
                    data
                );
            },
            true
        );
    }

    const OnChangeCustomer = (event, data, selectedData) => {
        if (isAndroidIOSMobile) {
            if (convertionDataRef.current.filter(val => val.customerIdHash === selectedData.userIdHash).length > 0) {
                CommonMethod.Actionclick("messagesAreaheaderback")
            }
        }
        let isCustomerconversation = convertionData.filter((d) => d.customerIdHash === selectedData.userIdHash);
        if (isCustomerconversation.length > 0 && expandMinimizeShow === 2) {
            setIsNewChat(false);
            setTimeout(() => { CommonMethod.Actionclick(selectedData.userIdHash) }, 20)
            return
        }
        if (CommonMethod.IsElement(selectedData.userIdHash) && isLeftOption === "Customer") {
            CommonMethod.Actionclick(selectedData.userIdHash)
            if (isIOSMobile) { setTimeout(() => { CommonMethod.Actionclick(selectedData.userIdHash) }, 10) };
            return;
        }
        else {
            setnewChatData(prevChatData => [
                {
                    ...prevChatData[0],
                    name: data.firstname + " " + data.lastname,
                    customerId: event,
                    firstName: data.firstname,
                    lastName: data.lastname,
                    cdnUrl: selectedData.cdnUrl !== undefined ? selectedData.cdnUrl : "" || "",
                    profileImage: selectedData.photo !== undefined ? selectedData.photo : "" || ""
                },
                ...prevChatData.slice(1)
            ]);
            setCustomer(event);
            CustomerId.current = event;
            customerDataRef.current = selectedData;
            SetFirstUserData(event);
            setCustomerData(selectedData)
            CheckSmsEligibility(true);
            window.onCkeditoerResize();
            if (!isFromConnectApp && selectedData?.userIdHash) {
                let SetChats = getConversationData(2, selectedData?.userIdHash, LoginUserIdHash);
                StoreLocalConversation(2, "", "", selectedData?.userIdHash, SetChats?.Message !== undefined ? SetChats?.Message : "", true, LoginUserIdHash, true, selectedData, SetChats?.attechments.length > 0 ? SetChats.attechments : [])
                window.SetEditorValueFromLocalStorage();
            }
        }
        utils.CallBackGivenToMobileSelectTab(1);
        // setSelecetedCustomerHash(selectedData.userIdHash);
    };
    const checkFirstCall = useRef(false);
    const CheckSmsEligibility = (isnew) => {
        if (isnew && checkFirstCall.current === false) {
            SMSEligibility("", "", false);
            checkFirstCall.current = true;
        }
    }
    const EmployeeNewChat = (HasIDs, PreventUpdate = false) => {
        if (isAndroidIOSMobile) {
            setConversetionChatSelection(NewDummyData)
            utils.stopTabSelection()
        }
        setnewChatSelectedEmployee(HasIDs);
        let NewChat = document.getElementById('btnaddToNewChat');
        NewChat.click();
    }
    const Textmarketing = async (IsStenciled) => {
        // setShowFirstLoader(true);
        if (IsStenciled) {
            setStenciledForSMS(true);
        }
        const accesstoken = await useGenerateToken(dispatch);
        // if (LeftControlNames.Employee === isLeftOption && ViewEmployee === false) {
        //     setShowFirstLoader(false);
        //     utils.ShowAlert("Alert", "You have not been given access to this page. Contact your account admin or business owner.", "Close");
        // }
        // else {
        await AsyncAjaxGet(
            VagaroAPIEnum.Textmarketing,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data.data.responseCode === ResponseCode.EmptyResultSet) {
                        setistextmarketing(false);
                        setStenciledForSMS(false);
                        if ((FirstUserData.current !== undefined && FirstUserData.current !== null && FirstUserData.current !== "") || (chatSelectionID !== undefined && chatSelectionID !== null && chatSelectionID !== "")) {
                            CheckTextSMSEligibility();
                        }
                    }
                    else if (data.data.responseCode === ResponseCode.Success && data != null && data.data != null && data.data.data != null && data.data.data !== undefined) {
                        setistextmarketing(true);
                        const textData = data.data.data;
                        var percentage = ((textData.usage * 100) / textData.planUsageLimit).toFixed(2);
                        var variant = "success";
                        if (percentage >= 0 && percentage <= 90) {
                            variant = "success";
                        }
                        else if (percentage > 90 && percentage < 100) {
                            variant = "warning";
                        }
                        else if (percentage >= 100) {
                            variant = "danger";
                        }
                        setfromTextMessengerPhone(textData.fromTextMessengerPhone);
                        Addtextmarketing({ ...textData, percentage: percentage, variant: variant });
                        setStenciledForSMS(false);
                    }
                    else {
                        setStenciledForSMS(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Text Marketing",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    setStenciledForSMS(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Text Marketing",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                setStenciledForSMS(false);
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Text Marketing",
                    "",
                    data
                );
            }
        );
        // }
    }

    const CheckTextSMSEligibility = async (SuccessData) => {
        const accesstoken = await useGenerateToken(dispatch);
        if ((FirstUserData.current === undefined || FirstUserData.current === null || FirstUserData.current === "") && (chatSelectionID === undefined || chatSelectionID === null || chatSelectionID === "")) {
            return;
        }
        await AsyncAjaxGet(
            VagaroAPIEnum.smseligibility + `?UserId=${FirstUserData.current === undefined || FirstUserData.current === null || FirstUserData.current === "" ? chatSelectionID : FirstUserData.current}&SenderId=${LoginUserIDV2}`,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        issmsavail(data.data.data.isEligible);

                        StoreTextMarketingData(data.data.data)
                        if (data.data.data.isNumberApproved === true && data.data.data.isTextMessagingActivated === true) {
                            window.onTextMarketingAlerts(data.data.data, false);
                        }
                        else {
                            window.onTextMarketingAlerts(data.data.data, true);
                        }
                        if (SuccessData !== undefined && SuccessData !== null && SuccessData !== "") {
                            SuccessData(data.data.data);
                        }
                        return
                    }
                    else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "SMS Eligibility",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "SMS Eligibility",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "SMS Eligibility",
                    "",
                    data
                );
            }, false
        );
    }

    window.TextMarketingCallBack = () => {
        Textmarketing(true);
        if (dispatch) {
            ThunkAPI(dispatch, ThunkApiEnum.TextMarketing);
        };
    }

    window.activeTextmarketingPlan = () => {
        if (isFromConnectApp) {
            setistextmarketing(null);
        }
        if ((FirstUserData.current !== undefined && FirstUserData.current === null && FirstUserData.current === "") || (chatSelectionID !== undefined && chatSelectionID !== null && chatSelectionID !== "")) {
            CheckTextSMSEligibility(SuccessData => {
                return ReactDOM.render(
                    <CommonMessagepopup
                        data={SuccessData}
                        isFromConnectApp={isFromConnectApp}
                        isAndroidIOSMobile={isAndroidIOSMobile}
                        isPlanActive={true}
                        ConversationId={chatSelection.ConversationId}
                    />,
                    document.getElementById("OpenDialog1")
                );
            });
        }
        else {
            return ReactDOM.render(
                <CommonMessagepopup
                    data={""}
                    isFromConnectApp={isFromConnectApp}
                    isAndroidIOSMobile={isAndroidIOSMobile}
                    isPlanActive={true}
                    ConversationId={chatSelection.ConversationId}
                />,
                document.getElementById("OpenDialog1")
            );
        }

    }
    window.customerNumberadded = () => {
        if (chatSelection.ConversationId !== "" && isIframeOpened) {
            CheckTextSMSEligibility()
        }
    }

    const OnButtonOrAnyCLik = () => {
        return ReactDOM.render(
            <CommonPopup
                data={""}
                isFromConnectApp={isFromConnectApp}
                isAndroidIOSMobile={isAndroidIOSMobile}
                isPlanActive={true}
            />,
            document.getElementById("OpenDialog1")
        );

    }


    window.openCustomerChat = (customerData) => {
        /* open small window*/
        /* set customer conversaction and open it */
        customerData.profileImage = customerData.profileImage == "user-male-img_155.png" || customerData.profileImage == "user-female-img_155.jpg" || customerData.profileImage == "user-male-img_155_new.png" || customerData.profileImage == "user-img-staff" || customerData.profileImage == "user-femail-img" ? "" : customerData.profileImage
        customerFromCustomerDetails.current = customerData;
        if (!isFromConnectApp) {
            ExpandMinimizeButtonClick(2, true);
            setExpandMinimizeShow(2);
        }
        if (customerData.isChatForCustomer) {
            if (sideoption.current === LeftControlNames.Employee) {
                setConvertionPageIndex(1);
                setTempConvertionData([]);
                setIsNewChat(false)
                onChange(LeftControlNames.Customer);
                return;
            }
        } else {
            // GetEmployee();
            if (sideoption.current === LeftControlNames.Customer) {
                setConvertionPageIndex(1);
                setTempConvertionData([]);
                setIsNewChat(false);
                onChange(LeftControlNames.Employee);
                return;
            }
        }
        if (convertionData.length > 0) {
            window.OnChangeCustomerForNewchat(customerData);
            customerFromCustomerDetails.current = "";
        }
    }

    window.OnConnectAppResumeFromNative = () => {
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        isFromAppResumeCallBack.current = true;
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
        // When app resume from background
        if (chatSelection.Type === "None" || isNewChat || chatListSelection === ChatListSelection.Unread) {
            if (isNewChat) {
                isNewChatFreeez.current = true;
            }
        } else {
            setPageIndex(1);
            setTempChatData([]);
            setMessageConvertions([]);
            pushNotificationConversationId.current = chatSelection.ConversationId;
        }
        if (isAndroidIOSMobile || isIpad || isIpadold) {
            setChatSelectionID("")
            setTimeout(() => {
                ReadUnreadCount();
                setConvertionPageIndex(1);
                GetConvertion();
            }, 1000);
        } else {
            ReadUnreadCount();
            setConvertionPageIndex(1);
            GetConvertion();
        }
    }
    window.UpdateConversationFromNative = () => {
        if (isIOSMobile || isIpad || isIpadold) {
            setTimeout(() => {
                ReadUnreadCount();
                setConvertionPageIndex(1);
                GetConvertion();
            }, 50);
        }
    }
    window.OpenNewChatFromNative = () => {
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        unmountComponentAtNode(document.getElementById("OpenDialog1"));
        if (isNewChatStart) {
            // CommonMethods.Actionclick("divStartChating");
            setNewChatStart(false);
            if (isLeftOption === LeftControlNames.Employee) {
                GetEmployee(false);
            }
        } else if (!isNewChat && !isIpad && !isIpadold) {
            if (document.getElementsByClassName("chatActive").length > 0) {
                if (document.getElementById("addMemberCloseBtn") != null) {
                    CommonMethod.Actionclick("addMemberCloseBtn");
                }
                if (document.getElementsByClassName("detailsActive").length > 0) {
                    CommonMethod.Actionclick("rightMobileHeaderBack");
                }
                CommonMethod.Actionclick("messagesAreaheaderback");
            }
            if (isAndroidIOSTabIpad()) {
                CommonMethod.Actionclick("btnaddToNewChat");
            } else {
                CommonMethod.NewChatClick();
                HandleHistory("push", "NewChat")
            }

        } else {
            if (document.getElementById("newChatAreaback") !== null && !isIpad && !isIpadold) {
                CommonMethod.Actionclick("newChatAreaback")
            }
            if (isAndroidIOSTabIpad()) {
                if (document.getElementsByClassName('addMemberModal')?.length > 0) {
                    document.getElementsByClassName('btn-close')[0]?.click();
                }
                CommonMethod.Actionclick("btnaddToNewChat");
            } else {
                CommonMethod.NewChatClick();
                HandleHistory("push", "NewChat")
            }
        }
        if (chatSelection.ConversationId !== "") {
            window.DeleteChannelDetail(false);
        }
    };

    const GetEmployee = async (isLoadFirstime) => {
        const accesstoken = await useGenerateToken(dispatch);
        if ((LeftControlNames.Employee === isLeftOption && ViewEmployee === false) && !isCustomerChat && initialData.isAi === false) {
            setShowFirstLoader(false);
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            if (isAndroidIOSMobile) { setShowFirstLoader(true); }
            await AsyncAjaxGet(
                VagaroAPIEnum.GeteEmployeeList,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null && data.data.data !== undefined) {
                            setShowFirstLoader(false);
                            if (isLoadFirstime) {
                                EmployeeDataStore(data.data.data);
                            }
                            else {
                                var tempAllEmpForAdd = data.data.data.map((col) => {
                                    return { value: col.memberId, label: col.fullName, bID: props.ReactBusinessId, RoleID: col.roleId, RoleName: col.roleName, IsParent: false, profile: col.photo !== undefined && col.photo !== null && col.photo !== "" ? col.cdnUrl + "Original/" + col.photo : "", Avatarinital: col.photo !== undefined && col.photo !== null && col.photo !== "" ? "" : utils.setLetter1(col.firstName || "", col.lastName || ""), UserIdHash: col.memberIdHash, LoginUserIdHash: LoginUserIdHash, cell: col.cell };
                                })
                                var filterdata = data.data.data.filter((d) => LoginUserIdHash !== d.memberIdHash);
                                var tempAllEmp = filterdata.map((col) => {
                                    return { value: col.memberId, label: col.fullName, bID: props.ReactBusinessId, RoleID: col.roleId, RoleName: col.roleName, IsParent: false, profile: col.photo !== undefined && col.photo !== null && col.photo !== "" ? col.cdnUrl + "Original/" + col.photo : "", Avatarinital: col.photo !== undefined && col.photo !== null && col.photo !== "" ? "" : utils.setLetter1(col.firstName || "", col.lastName || ""), UserIdHash: col.memberIdHash, LoginUserIdHash: LoginUserIdHash, cell: col.cell };
                                })
                                serviceProviderDataRef.current = tempAllEmp;
                                setEmployeeData(data.data.data);
                                setallserviceprovider(tempAllEmp);
                                setallserviceproviderforaddmember(tempAllEmpForAdd);
                                setShowFirstLoader(false);
                            }
                        }
                        else {
                            console.log(data)
                            setShowFirstLoader(false);
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Employee List",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        setShowFirstLoader(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Employee List",
                            "",
                            error
                        );
                    }
                },
                function OnError(data) {
                    setShowFirstLoader(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Employee List",
                        "",
                        data
                    );
                }
            );
        }
    }
    const GetConvertion = async (ChatSelectionConverstionId = "", selectionForCollpasedChat = false) => {
        var isUnreadTab = chatListSelection === ChatListSelection.Unread ? true : false;
        if (isUnreadTab) {
            setnoRecordUnread(false);
            setnoRecord(false);
            setConvertionPageIndex(1);
        }
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === isLeftOption && ViewCustomer === false) || ("Employee" === isLeftOption && ViewEmployee === false)) && !isCustomerChat && initialData.isAi === false) {
            setShowFirstLoader(false);
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close", true, onChange);
        }
        else {
            await AsyncAjaxGet(
                VagaroAPIEnum.GetConvertions + `&PageIndex=${convertionPageIndex}&isUnread=${isUnreadTab}`,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (
                            data != null &&
                            data.data != null &&
                            data.data.responseCode === ResponseCode.Success
                        ) {
                            if (data != null && data.data != null && data.data.data != null && data.data.data != null && data.data.data?.conversationList !== undefined) {
                                setConvertionDataCount(data.data.data.totalCount)
                                if (convertionPageIndex === 1) {
                                    ConversationCountRef.current = 0;
                                }
                                if (data.data.data.conversationList.length > 0) {
                                    if ("Employee" === isLeftOption) {
                                        settempEmployye(data.data.data.conversationList);
                                        setConvertionData(tempEmployye.concat(data.data.data.conversationList));
                                        setShowFirstLoader(false);
                                        !isAndroidIOSMobile && CommonMethod.RemoveElementbyClass("select2-container");
                                    }
                                    if ("Customer" === isLeftOption) {
                                        settempCustomer(data.data.data.conversationList);
                                        setConvertionData(tempCustomer.concat(data.data.data.conversationList));
                                        setShowFirstLoader(false);
                                        if (isFromConnectApp) {
                                            SetFirstUserData(data.data.data.conversationList[0].customerId);
                                        }
                                    }
                                    ConversationCountRef.current += data.data.data.conversationList.length
                                    if (data.data.data.conversationList.length >= 20 && ConversationCountRef.current < data.data.data.conversationCount) {
                                        setPaging(true);
                                    } else {
                                        setPaging(false);
                                    }

                                    mergeConvertionData(data.data.data.conversationList, undefined, false, selectionForCollpasedChat);
                                    if (isFromConnectApp) {
                                        let orientationQuery = window.matchMedia('(orientation: portrait)');
                                        if ((isIpad || isIpadold || isTablet) && orientationQuery.matches === false) {
                                            setIsNewChat(false);
                                        } else if (isNewChatFreeez.current === false) {
                                            setIsNewChat(false);
                                        }
                                    } else {
                                        setIsNewChat(false);
                                    }

                                    setnoRecordUnread(false);
                                    setnoRecord(false);

                                    // if (data.data.data.conversationList.item2 !== null && data.data.data.conversationList.item2.customerIds !== null && data.data.data.conversationList.item2.customerIds.length > 0) {
                                    //     setUnReadConvertionCustomer(data.data.data.conversationList.item2.customerIds);
                                    //     // setUnReadCountCustomer(data.data.data.conversationList.item2.customerIds.length)
                                    // }
                                    // if (data.data.data.conversationList.item2 !== null && data.data.data.conversationList.item2.employeeIds !== null && data.data.data.conversationList.item2.employeeIds.length > 0) {
                                    //     setUnReadConvertionEmployee(data.data.data.conversationList.item2.employeeIds);
                                    //     // setUnReadCountEmployee(data.data.data.conversationList.item2.employeeIds.length)
                                    // }

                                } else {
                                    setPaging(false);
                                    setShowFirstLoader(false);
                                    if (expandMinimizeShow === 2) {
                                        setConvertionData([]);
                                    }
                                    if (expandMinimizeShow === 1) {
                                        if (data.data.data.totalCount !== 0) {
                                            setConvertionData([]);
                                        }
                                    }
                                    if (isUnreadTab) {
                                        setnoRecordUnread(true);
                                        setnoRecord(true);
                                    } else {
                                        if (convertionPageIndex === 1) {
                                            if ("Employee" === isLeftOption) {
                                                if (expandMinimizeShow === 2 || isFromConnectApp) {
                                                    GetEmployee(false);
                                                }
                                            }
                                            if (expandMinimizeShow === 2 && isFromConnectApp === false) {
                                                setIsNewChat(false);
                                                setnoRecord(true);
                                            } else {

                                                setIsNewChat(true);
                                            }
                                        }
                                    }
                                    // if (LoadDataCountRef.current > 1 && isFromConnectApp) {
                                    //    utils.CallBackGivenToMobileSelectTab(2);
                                    //}
                                }
                                if (typeof window.activeTextmarketingPlan === 'function') {
                                    if (initialData?.activeTextmarketingPlan === true) {
                                        window.activeTextmarketingPlan();
                                    }
                                }
                                setFirstShow(true);
                                utils.SetMassageAreaWidth(isCustomerChat === false && expandMinimizeShow === 0);
                                isNewChatFreeez.current = false;
                                if (isAndroidIOSMobile && ChatSelectionConverstionId !== "" || pushNotificationConversationId.current !== "") {
                                    var selectionConversationId = ChatSelectionConverstionId === "" ? pushNotificationConversationId.current !== "" ? pushNotificationConversationId.current : "" : ChatSelectionConverstionId;
                                    var d = data.data.data.conversationList.find(val => val.conversationId === selectionConversationId);
                                    if (d) {
                                        d.messageId = pushNotificationMessageId.current;
                                        if (pushNotificationConversationId.current !== "") {
                                            UpsertChannel(LoginUserIDV2, d?.customerIdHash, true);
                                        }
                                        setConversetionChatSelection(d, true, undefined, false, ChatSelectionConverstionId !== "" ? false : true);
                                        pushNotificationConversationId.current = "";
                                    }
                                }

                                if (customerFromCustomerDetails.current !== undefined && customerFromCustomerDetails.current !== "" && customerFromCustomerDetails.current !== null) {
                                    let customerDetails = customerFromCustomerDetails.current;
                                    var customerRecord = data.data.data.conversationList.find(val => val.customerIdHash === customerDetails.userIdHash && val.isGroupChat === false);
                                    if (!isAndroidIOSMobile && !isFromConnectApp) {
                                        ExpandMinimizeButtonClick(2, true);
                                    }
                                    if (customerRecord) {
                                        setConversetionChatSelection(customerRecord, true);
                                        if (typeof window && window.parent !== undefined) {
                                            var jsonData = JSON.stringify({ IframName: 'chatFrame', className: 'ifream-chatActive' }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
                                            window.parent.postMessage('{"eventType": "iFrameAddClass","Data": "' + jsonData + '"}', '*');
                                        }
                                        setChatAreaShow(true);
                                        AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("messagesAreaheaderback") })
                                    }
                                    else {
                                        window.OnChangeCustomerForNewchat(customerDetails);
                                    }
                                    customerFromCustomerDetails.current = "";
                                }

                                if (recentChatSelectionRef.current !== "") {
                                    var recentChatData = recentChatSelectionRef.current;
                                    if (recentChatData.Type === 1) {
                                        var record = data.data.data.conversationList.find(val => val.conversationId === recentChatData.ConversationId);
                                        if (record) {
                                            setConversetionChatSelection(record, true, undefined, false);
                                        }
                                        recentChatSelectionRef.current = "";
                                    } else if (recentChatData.Type === 2) {
                                        var selectedItem = recentChatData.selectedItem;
                                        var customerDetails = {}
                                        if (selectedItem?.userId !== undefined) {
                                            customerDetails.customerId = selectedItem.userId;
                                            customerDetails.name = selectedItem.fullName;
                                            customerDetails.firstName = selectedItem.firstName;
                                            customerDetails.lastName = selectedItem.lastName;
                                            customerDetails.userIdHash = selectedItem.userIdHash;
                                            customerDetails.cdnUrl = selectedItem.cdnUrl !== undefined ? selectedItem.cdnUrl : "" || "";
                                            customerDetails.profileImage = selectedItem.photo !== undefined ? selectedItem.photo : "" || "";
                                            customerDetails.isChatForCustomer = true;
                                        } else {
                                            customerDetails = selectedItem;
                                        }
                                        GetConvertionIdFromCustomerPage(customerDetails);
                                        recentChatSelectionRef.current = "";
                                    } else if (recentChatData.Type === 3 && "Employee" === isLeftOption) {
                                        setIsNewChat(true);
                                        EmployeeNewChat(recentChatData.EmployeeIDs)
                                        recentChatSelectionRef.current = "";
                                    }
                                }

                                LoadDataCountRef.current = LoadDataCountRef.current + 1;
                                if (data.data.data.totalCount === 0) {
                                    if (window.OnResizeSelectEmployee !== undefined) {
                                        window.OnResizeSelectEmployee();
                                    }
                                }
                            }
                            else {
                                console.log(data);
                                isNewChatFreeez.current = false;
                                if (LoadDataCountRef.current === 0 && isFromConnectApp && isAndroidIOSMobile) {
                                    if (isLeftOption === LeftControlNames.Customer) {
                                        onChange(LeftControlNames.Employee);
                                    } else {
                                        onChange(LeftControlNames.Customer);
                                        LoadDataCountRef.current = LoadDataCountRef.current + 1;
                                    }
                                } else {
                                    setIsNewChat(true);
                                    setFirstShow(true);
                                    setShowFirstLoader(false);
                                }
                                // LoadDataCountRef.current = LoadDataCountRef.current + 1;
                            }
                        } else {
                            if (LoadDataCountRef.current === 0 && isFromConnectApp && isAndroidIOSMobile) {
                                if (isLeftOption === LeftControlNames.Customer) {
                                    onChange(LeftControlNames.Employee);
                                } else {
                                    onChange(LeftControlNames.Customer);
                                    LoadDataCountRef.current = LoadDataCountRef.current + 1;
                                }
                            } else {
                                setIsNewChat(true);
                                setFirstShow(true);
                                setShowFirstLoader(false);
                                if (isLeftOption === LeftControlNames.Employee) {
                                    GetEmployee(false);
                                }
                            }
                            if (data.data.responseCode === ResponseCode.EmptyResultSet) {
                                setConvertionData([]);
                            }
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Get Connversations",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        setIsNewChat(true);
                        isNewChatFreeez.current = false;
                        setFirstShow(true);
                        setShowFirstLoader(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Get Conversations",
                            "",
                            error
                        );
                    }
                },
                function OnError(data) {
                    console.log(data);
                    setIsNewChat(true);
                    isNewChatFreeez.current = false;
                    setFirstShow(true);
                    setShowFirstLoader(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Get Conversations",
                        "",
                        data
                    );
                },
                true
            );
        }
    }

    useEffect(() => {
        if (connectionStatus == "Connected") {
            if (isCustomerChat) {
                UpsertChannel(LoginUserIDV2, LoginUserIdHash);
            } else {
                GetAllActiveChannel()
            }

        } else if (connectionStatus == "Reconnecting" || connectionStatus == "Closed") {
            if (isCustomerChat) {
                window.DeleteChannelDetail(false);
            }
        }
    }, [connectionStatus]);

    useEffect(() => {

        if (!isCustomerChat && initialData.intialState !== 0 && !isFromConnectApp) {
            ConnectFeature();
        }

        if (activeTabState) {
            const intervalId = setInterval(() => {
                if (!isCustomerChat && isIframLoad && isIframeOpened && chatSelection.ConversationId !== "") {
                    if (isFromConnectApp && $("#rightMobileHeaderBack").is(":visible")) {
                        setTimer(upsertChannelTimer);
                    } else {
                        UpsertChannel(LoginUserIDV2, chatSelection?.AllData?.customerIdHash);
                    }
                } else {
                    setTimer(upsertChannelTimer);
                }
            }, timer * 1000);

            // Cleanup function to clear the interval when component unmounts
            return () => clearInterval(intervalId);
        }
    }, [isIframeOpened, activeTabState]);


    const UpsertChannel = async (userId, userIdHash, fromNotification = false) => {
        // console.log("Upsert channel - outside method when connection id is available");
        const accesstoken = await useGenerateToken(dispatch);
        if (!isCustomerChat) {
            setTimer(upsertChannelTimer);
        }
        var connectionId = "";
        if (connection !== "undefined" && connection != null && connection.connectionId != "undefined" && connection.connectionId != null || fromNotification) {
            connectionId = fromNotification && connection?.connectionId === null ? "" : connection.connectionId;
            //console.log("Upsert channel - inside method when connection id is available");
            const RequestData = {
                "userId": isCustomerChat ? userId : LoginUserIDV2, //Customer Id
                "channelId": sideoption.current === LeftControlNames.Customer ? userIdHash + props.ReactBusinessId : props.MerchantChannelId.toString(), // customerIdhash
                "connectionId": connectionId,
                "isCustomerChat": isCustomerChat,
                "ConversationId": isCustomerChat ? "" : chatSelection.ConversationId
            };
            setConnectionId(connection.connectionId);
            await AsyncAjaxPost(
                VagaroAPIEnum.upsertchannel,
                RequestData,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (
                            data != null &&
                            data.data != null &&
                            data.data.responseCode === ResponseCode.Success
                        ) {
                            if (data != null && data.data != null && data.data.data != null) {
                                //console.log(data.data.data)
                                if (isCustomerChat) {
                                    chatSelection.AllData.chanelId = RequestData.channelId;
                                }
                            }
                            else {
                                utils.appInsightTrack(
                                    props.ReactBusinessId,
                                    props.ReactUserID,
                                    "Upsert Channel",
                                    RequestData,
                                    data != null && data.data != null ? data.data : ""
                                );
                            }
                        } else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Upsert Channel",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Upsert Channel",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Upsert Channel",
                        RequestData,
                        data
                    );
                },
                true
            );
        }
    }

    const GetAllActiveChannel = async () => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.getActivechannel + `?ConnectionId=${connection.connectionId}&UserId=${LoginUserIDV2}&MerchantChannelId=${MerchantChannelId}`,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (
                        data != null &&
                        data.data != null &&
                        data.data.responseCode === ResponseCode.Success
                    ) {
                        if (data != null && data.data != null && data.data.data != null) {
                            if (firstTimeActiveChannelCalled.current == false) {
                                if (isFromConnectApp) {
                                    if (data.data.data.totalBadgeCount !== undefined && data.data.data.totalBadgeCount.multiLocationBusinessIds !== undefined) {
                                        multiLocationBusinessIdsRef.current = data.data.data.totalBadgeCount.multiLocationBusinessIds;
                                    }
                                }
                                firstTimeActiveChannelCalled.current = true;
                                if (data.data.data.customerIds !== null && data.data.data.customerIds.length > 0) {
                                    setUnReadConvertionCustomer(data.data.data.customerIds);
                                    setUnReadCountCustomer(data.data.data.customerIds.length)
                                }
                                if (data.data.data.employeeIds !== null && data.data.data.employeeIds.length > 0) {
                                    setUnReadConvertionEmployee(data.data.data.employeeIds);
                                    setUnReadCountEmployee(data.data.data.employeeIds.length)
                                }
                            }
                            if (isFromConnectApp) {
                                if (data.data.data.totalBadgeCount !== undefined && data.data.data.totalBadgeCount.totalBadgeCount !== undefined) {
                                    var jsonData =
                                    {
                                        "appicon": data.data.data?.totalBadgeCount?.totalBadgeCount,
                                        "messageicon": data.data.data.customerIds.length + data.data.data.employeeIds.length,
                                        "Type": 1
                                    }
                                    utils.CallBackGivenToMobile(MobileCallBackAction.BagdeCountForConnectApp, jsonData);
                                }
                            }
                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Get All Active Channels",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Get All Active Channels",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Get All Active Channels",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Get All Active Channels",
                    "",
                    data
                );
            },
            true
        );
    }

    const ReadUnreadCount = async () => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.readunread,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (
                        data != null &&
                        data.data != null &&
                        data.data.responseCode === ResponseCode.Success
                    ) {
                        if (data != null && data.data != null && data.data.data != null) {
                            if (data.data.data.customerIds !== null && data.data.data.customerIds.length > 0) {
                                setUnReadConvertionCustomer(data.data.data.customerIds);
                                setUnReadCountCustomer(data.data.data.customerIds.length)
                            }
                            if (data.data.data.employeeIds !== null && data.data.data.employeeIds.length > 0) {
                                setUnReadConvertionEmployee(data.data.data.employeeIds);
                                setUnReadCountEmployee(data.data.data.employeeIds.length)
                            }
                            if (isFromConnectApp) {
                                var jsonData =
                                {
                                    "appicon": null,
                                    "messageicon": data.data.data.customerIds.length + data.data.data.employeeIds.length,
                                    "Type": 2
                                }
                                utils.CallBackGivenToMobile(MobileCallBackAction.BagdeCountForConnectApp, jsonData);
                            }
                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Read Unread",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );

                        }
                    } else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Read Unread",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Read Unread",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Read Unread",
                    "",
                    data
                );
            },
            true
        );
    }
    const GetConvertionIdFromBusinessPage = async () => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.GetConversionId + `?CustomerId=${LoginUserIDV2}&IsFromMerchant=${false}&loggedInUserId=${LoginUserIDV2}`,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setShowFirstLoader(false);
                        if (data.data.data.businessShopOwnerId !== undefined && data.data.data.businessShopOwnerId !== null) {
                            CustomerId.current = data.data.data.businessShopOwnerId;
                            window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                        }
                        else if (data.data.data.conversationId !== undefined && data.data.data.conversationId !== null) {
                            setConversionidForCustomer(data.data.data.conversationId);
                            window.parent.postMessage('{"eventType": "setChatConversationId","Data": ' + data.data.data.conversationId + '}', '*');
                        }
                    }
                    else {
                        setShowFirstLoader(false);
                        window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');

                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Get ConversationId",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    setShowFirstLoader(false);
                    window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Get ConversationId",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                setShowFirstLoader(false);
                window.parent.postMessage('{"eventType": "vagaroConnectShowHideLoader","Data": ' + false + '}', '*');
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Get ConversationId",
                    "",
                    data
                );
            },
            true
        );
    }

    const GetConvertionIdFromCustomerPage = async (customerDetails) => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.GetConversionId + `?CustomerId=${customerDetails.customerId}&IsFromMerchant=${true}&loggedInUserId=${LoginUserIDV2}`,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        setConvertionPageIndex(1);
                        if (data.data.data.conversationId !== undefined && data.data.data.conversationId !== null) {
                            var conversionData = {};
                            conversionData.customerId = customerDetails.customerId;
                            conversionData.name = customerDetails.firstName + " " + customerDetails.lastName;
                            conversionData.firstName = customerDetails.firstName;
                            conversionData.lastName = customerDetails.lastName;
                            conversionData.conversationId = data.data.data.conversationId;
                            conversionData.isBlocked = false;
                            conversionData.chanelId = customerDetails.userIdHash + props.ReactBusinessId;
                            conversionData.userIdHash = customerDetails.userIdHash;
                            conversionData.isLoggedInUserRemovedFromGroup = false;
                            conversionData.cdnUrl = customerDetails.cdnUrl !== undefined ? customerDetails.cdnUrl + "/" : "" || "";
                            conversionData.profileImage = customerDetails.profileImage !== undefined ? customerDetails.profileImage : "" || "";
                            conversionData.isGroupChat = false;
                            conversionData.isForvagaroApp = true;
                            setIsNewChat(false);
                            setConversetionChatSelection(conversionData, true);
                        } else {
                            if (isFromConnectApp) {
                                if (isNewChatStart) { //For blank business - New business
                                    setNewChatStart(false);
                                }
                                if (!isAndroidIOSMobile) { // For landscape new chat
                                    setNewChat(true);
                                } else if (isAndroidIOSMobile) { // For mobile and tablet potrait mode new chat
                                    setIsNewChat(true);
                                }
                            } else { // For web new chat
                                if (customerDetails.isChatForCustomer === true) {
                                    setIsNewChat(true);
                                }
                            }

                            if (customerDetails.isChatForCustomer === false) {
                                if (serviceProviderDataRef.current.filter(employee => customerDetails.userIdHash.includes(employee.UserIdHash)).length === 0) {
                                    setIsNewChat(true);
                                    return;
                                }
                            }

                            customerDetails.name = customerDetails.firstName + " " + customerDetails.lastName;
                            customerDetails.fullName = customerDetails.firstName + " " + customerDetails.lastName;
                            setnewChatData(prevChatData => [
                                {
                                    ...prevChatData[0],
                                    name: customerDetails.firstName + " " + customerDetails.lastName,
                                    customerId: customerDetails.customerId,
                                    firstName: customerDetails.firstName,
                                    lastName: customerDetails.lastName,
                                    cdnUrl: customerDetails.cdnUrl !== undefined ? customerDetails.cdnUrl : "" || "",
                                    profileImage: customerDetails.profileImage !== undefined ? customerDetails.profileImage : "" || ""
                                },
                                ...prevChatData.slice(1)
                            ]);

                            setCustomer(customerDetails.customerId);
                            CustomerId.current = customerDetails.customerId;
                            customerDataRef.current = customerDetails;
                            setCustomerData(customerDetails);

                            if (typeof window.onCkeditoerResize === 'function') {
                                window.onCkeditoerResize();
                            }

                            if (customerDetails.isChatForCustomer === true) {
                                $("#Customer").append(`<option id="${customerDetails.customerId}" data-value="${customerDetails.customerId}" data-firstName="${customerDetails.firstName}"  data-lastName="${customerDetails.lastName}">${(customerDetails.name)}</option>`);
                                setTimeout(() => {
                                    if (!isAndroidIOSMobile && isFromConnectApp) {

                                    } else {
                                        $('.select2-container').removeClass("driveFormMselect");
                                    }
                                    $('.select2backdrop ').trigger("click");
                                }, 500);
                            }
                            else if (customerDetails.isChatForCustomer === false) {
                                setnewChatEmployeeSelection(true);
                                if (newChatSelectedEmployee === "") {
                                    if (serviceProviderDataRef.current.filter(employee => customerDetails.userIdHash.includes(employee.UserIdHash)).length > 0) {
                                        setnewChatSelectedEmployee(customerDetails.userIdHash);
                                        setIsNewChat(true);
                                    }
                                }
                            }
                        }
                    }
                    else {

                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Get ConversationId",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Get ConversationId",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Get ConversationId",
                    "",
                    data
                );
            },
            true
        );
    }

    const GetBagdeCountForConnectApp = async () => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.badgecount + `&multilocationBusinessIds=${multiLocationBusinessIdsRef.current}`,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (
                        data != null &&
                        data.data != null &&
                        data.data.responseCode === ResponseCode.Success
                    ) {
                        if (data != null && data.data != null && data.data.data != null) {
                            if (data.data.data.totalBadgeCount !== null && data.data.data.totalBadgeCount !== undefined) {
                                var jsonData =
                                {
                                    "appicon": data.data.data.totalBadgeCount,
                                    "messageicon": null,
                                    "Type": 3
                                }
                                utils.CallBackGivenToMobile(MobileCallBackAction.BagdeCountForConnectApp, jsonData);
                            }
                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Get badge count for connect app",
                                "",
                                data != null && data.data != null ? data.data : ""
                            );

                        }
                    } else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Get badge count for connect app",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Get badge count for connect app",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "Read Unread",
                    "",
                    data
                );
            },
            true
        );
    }

    const setConversionidForCustomer = (conversationId) => {
        var conversionData = {};
        conversionData.id = LoginUserIDV2;
        conversionData.name = "Customer Name";
        conversionData.conversationId = conversationId;
        conversionData.isBlocked = false;
        conversionData.chanelId = LoginUserIdHash + props.ReactBusinessId;
        conversionData.isLoggedInUserRemovedFromGroup = false;
        setConversetionChatSelection(conversionData, true);
    };

    const removeDuplicateConversations = (data) => {
        const uniqueConversations = {};
        data.forEach(conversation => {

            if (!uniqueConversations[conversation.conversationId]) {
                uniqueConversations[conversation.conversationId] = conversation;
            }
        });
        const filteredConversations = Object.values(uniqueConversations);
        return filteredConversations;
    };

    const mergeConvertionData = (convertionData, isFromReceiver, isUnreadTab = false, selectionForCollpasedChat = false, isfromSearch = false) => {
        var mergedObject = convertionData;
        if (tempConvertionData !== undefined && tempConvertionData !== null && tempConvertionData.length > 0 && convertionPageIndex > 1 && isFromReceiver === undefined && isfromSearch === false) {
            mergedObject = mergedObject.concat(tempConvertionData);
        }
        mergedObject = removeDuplicateConversations(mergedObject);
        var tempData = [];
        // mergedObject.map((x) => {
        //     if (x.isRead === false) {
        //         tempData.push({ conversationId: x.conversationId })
        //     }
        // })
        const sortedConversations = mergedObject.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
        setTempConvertionData(sortedConversations);
        setConvertionData(sortedConversations);
        convertionDataRef.current = sortedConversations;
        setPinCount(sortedConversations.filter((d) => d.isPin === true).length);

        // var unreaddata = mergedObject.filter((x) => x.isRead === false);
        // if (unreaddata.length > 0) {
        //     for (let index = 0; index < unreaddata.length; index++) {
        //         const element = unreaddata[index];
        //         tempData.push({ conversationId: element.conversationId })
        //     }

        //     const keyToCheck = 'conversationId';
        //     if (isLeftOption === LeftControlNames.Customer) {
        //         if (isIframeOpened === true || isFromReceiver !== undefined) {
        //             if (unReadConversionCustomer !== undefined && unReadConversionCustomer !== null && unReadConversionCustomer.length > 0) {
        //                 let newArray = unReadConversionCustomer.filter(item2 => !tempData.some(item1 => item1[keyToCheck] === item2[keyToCheck]));
        //                 tempData = tempData.concat(newArray);
        //             }
        //             setUnReadConvertionCustomer(tempData);
        //         }
        //     } else {
        //         if (unReadConversionEmployee !== undefined && unReadConversionEmployee !== null && unReadConversionEmployee.length > 0) {
        //             let newArray2 = unReadConversionEmployee.filter(item2 => !tempData.some(item1 => item1[keyToCheck] === item2[keyToCheck]));
        //             tempData = tempData.concat(newArray2);
        //         }
        //         setUnReadConvertionEmployee(tempData);
        //     }


        //     if (isLeftOption === LeftControlNames.Customer) {
        //         if (isIframeOpened === true || isFromReceiver !== undefined) {
        //             setUnReadCountCustomer(tempData.filter((x) => x.conversationId !== chatSelection.ConversationId).length);
        //         }
        //     } else {
        //         setUnReadCountEmployee(tempData.filter((x) => x.conversationId !== chatSelection.ConversationId).length);
        //     }
        // } else {
        //     if (isLeftOption === LeftControlNames.Customer) {
        //         setUnReadConvertionCustomer([])
        //         setUnReadCountCustomer(0)
        //     } else {
        //         setUnReadConvertionEmployee([])
        //         setUnReadCountEmployee(0)
        //     }
        // }
        // if (!isUnreadTab) {
        if (convertionPageIndex === 1 && expandMinimizeShow === 0 && isFromReceiver === undefined && searchText.length === 0 && isUnreadTab === false) {
            if (RedirectToCustommerbyHasFlagRef.current !== null) {
                var temparr = sortedConversations.find(val => val.customerIdHash === RedirectToCustommerbyHasFlagRef.current);
                if (temparr) {
                    setConversetionChatSelection(temparr, true);
                } else {
                    // redirect from gotochat
                    // Add New Chat Pending for Add New Chat if Has Not in conversetion
                    setConversetionChatSelection(sortedConversations[0], true);
                }
                RedirectToCustommerbyHasFlagRef.current = null;
            } else {
                if (isFromConnectApp && chatSelection.ConversationId === "") {
                    setConversetionChatSelection(sortedConversations[0], true);
                } else if (!isFromConnectApp) {
                    setConversetionChatSelection(sortedConversations[0], true);
                }
            }
        }
        // }
        if (selectionForCollpasedChat) {
            setConversetionChatSelection(sortedConversations[0], true);
        }
        if (convertionPageIndex > 1 && paging) {
            setConversionChanged(Math.random())
        }
        setscrollupdate(Math.random())

    };
    const mergeChatData = (chatData) => {
        var mergedObject = chatData;
        var TempChatResponse = {
            messageId: mergedObject[0].messageId,
            message: mergedObject[0].message,
            messageDate: mergedObject[0].messageDate,
            sentByHash: mergedObject[0].sentByHash,
            fullName: mergedObject[0].fullName,
            isNote: mergedObject[0].isNote,
            isGroupUpdateMessage: mergedObject[0].isGroupUpdateMessage
        };
        setMessageConvertions(prevState => {
            const updatedChatData = { ...prevState };
            const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
            if (updatedChatData[dateKey]) {
                updatedChatData[dateKey].push(TempChatResponse);
            } else {
                updatedChatData[dateKey] = [TempChatResponse];
            }
            return updatedChatData;
        });
        mergedObject = removeDuplicateMessages(mergedObject);
        setTempChatData(mergedObject);
        middleArea.current.scrollTop = middleArea.current.scrollHeight;
    };

    const removeDuplicateMessages = (data) => {
        const uniqueMessages = {};
        data.forEach(conversation => {

            if (!uniqueMessages[conversation.messageId]) {
                uniqueMessages[conversation.messageId] = conversation;
            }
        });
        const filteredConversations = Object.values(uniqueMessages);
        return filteredConversations;
    };

    const hasGroupUpdateMessage = (dateArray) => {
        for (const date in dateArray) {
            const messages = dateArray[date];
            for (const message of messages) {
                if (message.isGroupUpdateMessage) {
                    return date; // Found a message with isGroupUpdateMessage set to true
                }
            }
        }
        return false; // No message with isGroupUpdateMessage set to true found
    };

    const markReadAndUnreadChat = (isRead, conversationId) => {

        setConvertionData(convertionData.map(item =>
            item.conversationId === conversationId
                ? { ...item, isRead: isRead }
                : item
        ));

        // start on scroll new  data not showing
        const sortedConversations = tempConvertionData?.map(item =>
            item.conversationId === conversationId
                ? { ...item, isRead: isRead }
                : item
        );
        setTempConvertionData(sortedConversations);

        const tempCustomernew = tempCustomer?.map(item =>
            item.conversationId === conversationId
                ? { ...item, isRead: isRead }
                : item
        );
        settempCustomer(tempCustomernew);

        const tempEmployyenew = tempEmployye?.map(item =>
            item.conversationId === conversationId
                ? { ...item, isRead: isRead }
                : item
        );
        settempEmployye(tempEmployyenew);
        // end on scroll new data not showing
    };

    window.setAiBroadCast = (data) => {
        if (data.messageType === VagaroConnectMessageType.TakeControl) {
            const updatedData = convertionData.map(d =>
                d.conversationId === data.conversationId
                    ? { ...d, takeControl: data.date_Time }
                    : d
            );
            const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
            setTempConvertionData(sortedConversations);
            setConvertionData(sortedConversations);
            var divElement = $('#' + data.conversationId + '.active');
            if (divElement.length > 0 && $('#' + data.conversationId).length > 0) {
                setAIControl(false);
            }
        }
    }

    window.AllBroadCast = (data) => {
        if (data.type === ConnectLivestreamType.BusinessTocustomer) {
            if (data.tempid === chatSelection.ConversationId && isCustomerChat) {
                LiveStreamjoinroom(false, data);
            }
        } else if (data.type === ConnectLivestreamType.EmployeeToEmployee) {
            if (data.tempid === initialData.encStaticUserid && data.merchantId === initialData.encStaticMerchantid && isCustomerChat === false) {
                LiveStreamjoinroom(false, data);
            }
        } else if (data.type === ConnectLivestreamType.EmployeeToGroup) {
            if (data.tempid !== undefined && data.tempid !== null && data.tempid !== "") {
                const ids = data.tempid.split(",");
                const exists = ids.includes(initialData.encStaticUserid);
                if (exists) {
                    LiveStreamjoinroom(false, data);
                }
            }
        } else if (data.type === ConnectLivestreamType.CustomerToBusiness && isCustomerChat === false) {
            if (data.merchantId === initialData.encStaticMerchantid) {
                LiveStreamjoinroom(false, data);
            }
        }
    }

    window.SetMessageCopy = (data) => {
        var mergedObject = data;
        var TempChatResponse = {
            messageId: mergedObject.id,
            message: mergedObject.message,
            attachment: mergedObject.attachment || [],
            messageDate: mergedObject.date,
            sentByHash: mergedObject.senderIdHash,
            fullName: mergedObject.fullName,
            isNote: mergedObject.isNote,
            isGroupUpdateMessage: mergedObject.isGroupUpdateMessage,
            isDeleted: mergedObject.isDeleted,
            isMessageFromCustomer: mergedObject.isMessageFromCustomer,
            status: mergedObject.status,
            action: mergedObject.action,
            senderFirstName: mergedObject.senderFirstName,
            senderLastName: mergedObject.senderLastName,
            senderPhoto: mergedObject.senderPhoto,
            senderCdnURL: mergedObject.senderCdnUrl,
            reactions: mergedObject.reactions ? mergedObject.reactions : [],
            isVcard: mergedObject.isVcard,
            isAIMessage: mergedObject.isAIMessage || false,
            isDislikeMessage: mergedObject.isDislikeMessage || false
        };
        //Common Object
        var AddConversationToObject = {
            "conversationId": mergedObject.conversationId,
            "customerIdHash": mergedObject.senderIdHash,
            "customerId": mergedObject.senderId,
            "name": mergedObject.isGroupChat === false ? mergedObject.fullName : (mergedObject.groupMembers !== undefined && mergedObject.groupMembers !== null) && mergedObject.groupMembers.length > 0 ? mergedObject.groupMembers.map((m) => m.firstName).join(", ") : "",
            "lastMessage": mergedObject.message,
            "lastMessageDate": mergedObject.date,
            "isPin": false,
            "isMute": false,
            "userType": mergedObject.messageType === VagaroConnectMessageType.EmployeeChatMessage ? 0 : 1,
            "isGroupChat": mergedObject.isGroupChat,
            "messageId": mergedObject.id,
            "isRead": false,
            "cdnUrl": mergedObject.senderCdnUrl,
            "firstName": mergedObject.senderFirstName,
            "lastName": mergedObject.senderLastName,
            "isBlocked": mergedObject.isBlocked,
            "pageIndex": 0,
            "isDeleted": false,
            "profileImage": mergedObject.senderPhoto,
            "members": mergedObject.groupMembers,
            "isBusinessExcluded": false
        };
        try {
            if (mergedObject.date !== null) {
                let inputDateTime = moment(mergedObject.date);
                mergedObject.date = inputDateTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            }
        } catch (e) {

        }

        if (!isCustomerChat && (mergedObject.isGroupChat === true || mergedObject.messageType === VagaroConnectMessageType.ChangeGroupName)) {
            var getConversation = convertionData.filter((x) => x.conversationId === mergedObject.conversationId);
            if (getConversation.length > 0) {
                if (CheckLoginUserIsRemovedFromChat(getConversation[0]) === true) {
                    return false;
                }
            }
            // else {
            //     if (mergedObject.messageType === VagaroConnectMessageType.EmployeeChatMessage) {
            //         if (mergedObject.groupMembers.filter((x) => x.memberIdHash === LoginUserIdHash).length > 0) {
            //             AddConversationToObject.isLoggedInUserRemovedFromGroup = mergedObject.groupMembers.filter((x) => x.memberIdHash === LoginUserIdHash).isMemberRemovedFromGroup
            //         } else {
            //             AddConversationToObject.isLoggedInUserRemovedFromGroup = false;
            //         }
            //     }
            // }
        }

        if (!isIframeOpened) {
            if (!isCustomerChat) {
                // ReadUnreadCount();
                if (mergedObject.messageType === VagaroConnectMessageType.CustomerMessageSentByCustomer) {
                    if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length === 0) {
                        setUnReadConvertionCustomer(unReadConversionCustomer.concat(mergedObject.conversationId));
                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                    }
                } else if (mergedObject.messageType === VagaroConnectMessageType.EmployeeChatMessage) {
                    if (mergedObject.senderIdHash !== LoginUserIdHash) {
                        var ReceiverIdHash = "";
                        if (mergedObject.groupMembers !== undefined && mergedObject.groupMembers !== null && mergedObject.groupMembers.length > 1) {
                            mergedObject.receiverIdHash = LoginUserIdHash;
                            var memberReceiverIdHash = mergedObject.groupMembers.filter((x) => x.memberIdHash === LoginUserIdHash);
                            if (memberReceiverIdHash.length > 0) {
                                ReceiverIdHash = memberReceiverIdHash[0].memberIdHash;
                            }
                            else {
                                return;
                            }
                        } else {
                            return;
                            //ReceiverIdHash = LoginUserIdHash;
                        }
                        if (mergedObject.receiverIdHash === ReceiverIdHash) {
                            if (unReadConversionEmployee.filter(e => e === mergedObject.conversationId).length === 0) {
                                setUnReadConvertionEmployee(unReadConversionEmployee.concat(mergedObject.conversationId));
                                setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                            }
                        }
                    }
                } else if (mergedObject.messageType === VagaroConnectMessageType.ReadStatusMessage) {
                    var isRead = mergedObject.message === "Read" ? true : false;
                    if (isRead) {
                        if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length > 0) {
                            setUnReadConvertionCustomer(unReadConversionCustomer.filter(c => c !== mergedObject.conversationId));
                            setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer - 1);
                        }
                    } else {
                        if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length === 0) {
                            setUnReadConvertionCustomer(unReadConversionCustomer.concat(mergedObject.conversationId));
                            setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                        }
                    }
                } else if (mergedObject.messageType === VagaroConnectMessageType.CustomerRemoveChat || mergedObject.messageType === VagaroConnectMessageType.CustomerMessageSentByAI) {
                    if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length > 0) {
                        setUnReadConvertionCustomer(unReadConversionCustomer.filter(c => c !== mergedObject.conversationId));
                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer - 1);
                    }
                }
            }
            if (mergedObject.messageType === VagaroConnectMessageType.ChannelSubscriptionMessage) {
                //Customer Channel Subscription
                if (connection.state === 'Connected') {
                    connection.invoke("JoinGroup", data.message, "Business_Joined");
                }
            }
            return;
        }

        if (mergedObject.messageType === VagaroConnectMessageType.ChannelSubscriptionMessage) {
            //Customer Channel Subscription
            if (connection.state === 'Connected') {
                connection.invoke("JoinGroup", data.message, "Business_Joined");
            }
        }
        //Customer is Online
        if (!isCustomerChat && mergedObject.messageType === VagaroConnectMessageType.CustomerIsOnline) {

            if (chatSelection.AllData.customerIdHash === mergedObject.senderIdHash) {
                chatSelection.AllData.isCustomerOnline = true;
                if ("Customer" === isLeftOption) {
                    $(`#sendbutton1`).text("Send");
                    const updatedData = convertionData.map(d =>
                        d.customerIdHash === mergedObject.senderIdHash
                            ? { ...d, isCustomerOnline: true }
                            : d
                    );
                    setTempConvertionData(updatedData);
                    setConvertionData(updatedData);
                    window.CheckcustomerOnline(true);
                }
            }
        }
        //Customer is Offline
        if (!isCustomerChat && mergedObject.messageType === VagaroConnectMessageType.CustomerIsOffline) {

            if (chatSelection.AllData.customerIdHash === mergedObject.senderIdHash) {
                chatSelection.AllData.isCustomerOnline = false;
                const updatedData = convertionData.map(d =>
                    d.customerIdHash === mergedObject.senderIdHash
                        ? { ...d, isCustomerOnline: false }
                        : d
                );
                setTempConvertionData(updatedData);
                setConvertionData(updatedData);
                if ("Customer" === isLeftOption && UserTextMarketingData.current?.isTextMessagingActivated === true && UserTextMarketingData.current?.isNumberApproved === true && UserTextMarketingData.current?.cell !== "" && UserTextMarketingData.current?.isNotifyBySMS === true) {
                    $(`#sendbutton1`).text("SMS");
                }
                window.CheckcustomerOnline(false);
            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.CustomerMessageSentByAI) {
            //Customer message sent by employee
            // if (mergedObject.senderIdHash !== LoginUserIdHash) {
            if (mergedObject.senderIdHash && mergedObject.conversationId) {
                dispatch(RemoveTypingUser({ id: mergedObject.conversationId, userId: mergedObject.conversationId }))
            }
            if (isCustomerChat) {
                if (!mergedObject.isBlocked && !mergedObject.isNote) {
                    if (connection !== undefined && connection !== null && connection.state !== undefined && connection.state !== null) {
                        if (connection.state === "Connected") {
                            connection.invoke("UpdateLastSeen", merchantId, LoginUserIDV2, mergedObject.conversationId);
                        }
                    }
                    if ((mergedObject.receiverIdHash === LoginUserIdHash)) {
                        setMessageConvertions(prevState => {
                            const updatedChatData = { ...prevState };
                            const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                            if (!TempChatResponse.sentBy && mergedObject.senderId) {
                                TempChatResponse.sentBy = mergedObject.senderId
                            }
                            if (updatedChatData[dateKey]) {
                                updatedChatData[dateKey].push(TempChatResponse);
                            } else {
                                updatedChatData[dateKey] = [TempChatResponse];
                            }
                            return updatedChatData;
                        });
                        if (chatSelection.ConversationId === "") {
                            setConversionidForCustomer(mergedObject.conversationId);
                        }
                    }
                    middleArea.current.scrollTop = middleArea.current.scrollHeight;
                }
            } else {
                const HandleAIMessage = (prev) => {
                    if (mergedObject.isAIMessage === true && mergedObject.isGroupUpdateMessage === true) {
                        return false;
                    } else {
                        return data.isAIMessage
                    }
                }
                if (mergedObject.conversationId !== chatSelection.ConversationId) {

                    var checkConversationExist = convertionData.filter((x) => x.conversationId === data.conversationId).length > 0 ? true : false;
                    if (!checkConversationExist && isLeftOption === LeftControlNames.Customer) {
                        let AddConversetionData = convertionData;
                        AddConversationToObject.isRead = HandleAIMessage();
                        AddConversetionData.unshift(AddConversationToObject);
                        mergeConvertionData(AddConversetionData, true);
                    } else {
                        var divElement = $('#' + data.conversationId + '.active');
                        if (divElement.length > 0) {
                            setMessageConvertions(prevState => {
                                const updatedChatData = { ...prevState };
                                const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                                if (updatedChatData[dateKey]) {
                                    updatedChatData[dateKey].push(TempChatResponse);
                                } else {
                                    updatedChatData[dateKey] = [TempChatResponse];
                                }
                                return updatedChatData;
                            });
                        } else if ($('#' + data.conversationId).length > 0) {
                            //mergeChatData1(data)
                        }
                        setTempChatData(mergedObject);
                        const updatedData = convertionData.map(d =>
                            d.conversationId === data.conversationId
                                ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, isRead: HandleAIMessage(), isBlocked: mergedObject.isBlocked, isAIMessage: HandleAIMessage(d), isDislikeMessage: mergedObject.isDislikeMessage }
                                : d
                        );
                        const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                        setTempConvertionData(sortedConversations);
                        setConvertionData(sortedConversations);
                        if (mergedObject.isAIMessage && mergedObject.isGroupUpdateMessage) {
                            CommonMethod.MarkMessageUnread(mergedObject.conversationId, LoginUserIDV2, mergedObject.id, true, true, mergedObject.senderIdHash, false, "", undefined, true)
                        } else {
                            // ReadCount Update on AI Reply
                            setUnReadConvertionCustomer(unReadConversionCustomer.filter(c => c !== mergedObject.conversationId));
                            setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer - 1);
                        }
                    }
                }
                // }
                else {

                    const updatedData = convertionData.map(d =>
                        d.conversationId === data.conversationId
                            ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, isBlocked: mergedObject.isBlocked, isAIMessage: HandleAIMessage(d), isDislikeMessage: mergedObject.isDislikeMessage }
                            : d
                    );
                    const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                    setTempConvertionData(sortedConversations);
                    setConvertionData(sortedConversations);

                    setMessageConvertions(prevState => {
                        const updatedChatData = { ...prevState };
                        const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                        if (updatedChatData[dateKey]) {
                            updatedChatData[dateKey].push(TempChatResponse);
                        } else {
                            updatedChatData[dateKey] = [TempChatResponse];
                        }
                        return updatedChatData;
                    });
                    // Rightside Panel Detail Update
                    if (mergedObject.isGroupUpdateMessage === true) {
                        CommonMethod.RenderRightSidePanel();
                    }
                    if (mergedObject.isAIMessage === true && mergedObject.isGroupUpdateMessage === true) {
                        setAIControl(false)
                    } else {
                        setAIControl(true)
                    }
                }
                middleArea.current.scrollTop = middleArea.current.scrollHeight;
            }
            // }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.CustomerMessageSentByEmployee) {
            //Customer message sent by employee
            if (mergedObject.senderIdHash !== LoginUserIdHash) {
                if (mergedObject.senderIdHash && mergedObject.conversationId) {
                    dispatch(RemoveTypingUser({ id: mergedObject.conversationId, userId: mergedObject.senderIdHash }))
                }
                if (isCustomerChat) {
                    if (!mergedObject.isBlocked && !mergedObject.isNote) {
                        if (connection !== undefined && connection !== null && connection.state !== undefined && connection.state !== null) {
                            if (connection.state === "Connected") {
                                connection.invoke("UpdateLastSeen", merchantId, LoginUserIDV2, mergedObject.conversationId);
                            }
                        }
                        if ((mergedObject.receiverIdHash === LoginUserIdHash)) {
                            setMessageConvertions(prevState => {
                                const updatedChatData = { ...prevState };
                                const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                                if (!TempChatResponse.sentBy && mergedObject.senderId) {
                                    TempChatResponse.sentBy = mergedObject.senderId
                                }
                                if (updatedChatData[dateKey]) {
                                    updatedChatData[dateKey].push(TempChatResponse);
                                } else {
                                    updatedChatData[dateKey] = [TempChatResponse];
                                }
                                return updatedChatData;
                            });
                            if (chatSelection.ConversationId === "") {
                                setConversionidForCustomer(mergedObject.conversationId);
                            }
                        }
                        middleArea.current.scrollTop = middleArea.current.scrollHeight;
                    }
                } else {
                    if (mergedObject.conversationId !== chatSelection.ConversationId) {

                        var checkConversationExist = convertionData.filter((x) => x.conversationId === data.conversationId).length > 0 ? true : false;
                        if (!checkConversationExist && isLeftOption === LeftControlNames.Customer) {
                            let AddConversetionData = convertionData;
                            AddConversationToObject.isRead = true;
                            AddConversetionData.unshift(AddConversationToObject);
                            mergeConvertionData(AddConversetionData, true);
                        } else {
                            var divElement = $('#' + data.conversationId + '.active');
                            if (divElement.length > 0) {
                                setMessageConvertions(prevState => {
                                    const updatedChatData = { ...prevState };
                                    const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                                    if (updatedChatData[dateKey]) {
                                        updatedChatData[dateKey].push(TempChatResponse);
                                    } else {
                                        updatedChatData[dateKey] = [TempChatResponse];
                                    }
                                    return updatedChatData;
                                });
                            } else if ($('#' + data.conversationId).length > 0) {
                                //mergeChatData1(data)
                            }
                            setTempChatData(mergedObject);
                            const updatedData = convertionData.map(d =>
                                d.conversationId === data.conversationId
                                    ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, isRead: true, isBlocked: mergedObject.isBlocked }
                                    : d
                            );
                            const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                            setTempConvertionData(sortedConversations);
                            setConvertionData(sortedConversations);


                        }
                    }
                    // }
                    else {

                        const updatedData = convertionData.map(d =>
                            d.conversationId === data.conversationId
                                ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, isBlocked: mergedObject.isBlocked }
                                : d
                        );
                        const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                        setTempConvertionData(sortedConversations);
                        setConvertionData(sortedConversations);

                        setMessageConvertions(prevState => {
                            const updatedChatData = { ...prevState };
                            const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                            if (updatedChatData[dateKey]) {
                                updatedChatData[dateKey].push(TempChatResponse);
                            } else {
                                updatedChatData[dateKey] = [TempChatResponse];
                            }
                            return updatedChatData;
                        });
                        // Rightside Panel Detail Update
                        if (mergedObject.isGroupUpdateMessage === true) {
                            CommonMethod.RenderRightSidePanel();
                        }
                    }
                    middleArea.current.scrollTop = middleArea.current.scrollHeight;
                }
            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.CustomerMessageSentByCustomer) {
            //Customer message sent by Customer
            if (mergedObject.senderIdHash && mergedObject.conversationId) {
                dispatch(RemoveTypingUser({ id: mergedObject.conversationId, userId: mergedObject.senderIdHash }))
            }
            if (mergedObject.senderIdHash !== LoginUserIdHash && isLeftOption === LeftControlNames.Customer) {
                if (mergedObject.conversationId !== chatSelection.ConversationId) {
                    // ReadUnreadCount();

                    if (unReadConversionCustomer.filter(c => c === data.conversationId).length === 0) {
                        setUnReadConvertionCustomer(unReadConversionCustomer.concat(data.conversationId));
                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                    }

                    var checkConversationExist = convertionData.filter((x) => x.conversationId === data.conversationId).length > 0 ? true : false;
                    if (!checkConversationExist && mergedObject.isMessageFromCustomer === true && isLeftOption === LeftControlNames.Customer) {
                        let AddConversetionData = convertionData;
                        if (isNewChat && convertionData.length === 0) {
                            if (isAndroidIOSMobile) {
                                if (document.getElementById("messagesAreaheaderback") !== null) {
                                    CommonMethods.Actionclick("messagesAreaheaderback");
                                }
                                isNewChatFreeez.current = false;
                            }
                            setShowFirstLoader(false);
                            setIsNewChat(false);
                            setNewChatStart(false);
                            setPaging(false);
                        }
                        AddConversationToObject.isCustomerOnline = true;
                        AddConversationToObject.isLoggedInUserRemovedFromGroup = false;
                        AddConversetionData.unshift(AddConversationToObject);
                        mergeConvertionData(AddConversetionData, true);
                    } else {
                        var divElement = $('#' + data.conversationId + '.active');
                        if (divElement.length > 0) {
                            setMessageConvertions(prevState => {
                                const updatedChatData = { ...prevState };
                                const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                                if (updatedChatData[dateKey]) {
                                    updatedChatData[dateKey].push(TempChatResponse);
                                } else {
                                    updatedChatData[dateKey] = [TempChatResponse];
                                }
                                return updatedChatData;
                            });
                        } else if ($('#' + data.conversationId).length > 0) {
                            //mergeChatData1(data)
                        }
                        setTempChatData(mergedObject);
                        const updatedData = convertionData.map(d =>
                            d.conversationId === data.conversationId
                                ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, isRead: divElement.length > 0 ? true : false, customerIdHash: data.senderIdHash, isCustomerOnline: true, isAIMessage: false }
                                : d
                        );
                        const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                        setTempConvertionData(sortedConversations);
                        setConvertionData(sortedConversations);
                        // if (!isCustomerChat) {

                        //     if (isLeftOption === LeftControlNames.Customer) {
                        //         if (mergedObject.isMessageFromCustomer === true) {
                        //             if (unReadConversionCustomer.filter((x) => x.conversationId === data.conversationId).length === 0) {
                        //                 setUnReadConvertionCustomer(unReadConversionCustomer.concat({ conversationId: data.conversationId }))
                        //                 setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                        //             }
                        //             // setUnReadCountCustomer(sortedConversations.filter((x) => x.conversationId !== chatSelection.ConversationId && x.isRead === false).length);
                        //         } else {
                        //             if (unReadConversionEmployee.filter((x) => x.conversationId === data.conversationId).length === 0) {
                        //                 setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: data.conversationId }))
                        //                 setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                        //             }
                        //         }
                        //     }
                        //     // } else if (mergedObject.isMessageFromCustomer === false && mergedObject.senderIdHash !== props.LoginUserIdHash) {
                        //     if (isLeftOption === LeftControlNames.Employee) {
                        //         if (mergedObject.isMessageFromCustomer === false) {
                        //             if (unReadConversionEmployee.filter((x) => x.conversationId === data.conversationId).length === 0) {
                        //                 setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: data.conversationId }))
                        //                 setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                        //             }
                        //             // setUnReadCountEmployee(sortedConversations.filter((x) => x.conversationId !== chatSelection.ConversationId && x.isRead === false).length);
                        //         } else {
                        //             if (unReadConversionCustomer.filter((x) => x.conversationId === data.conversationId).length === 0) {
                        //                 setUnReadConvertionCustomer(unReadConversionCustomer.concat({ conversationId: data.conversationId }))
                        //                 setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                        //             }
                        //         }
                        //     }
                        //     // }
                        // }
                    }
                }
                // }
                else {

                    if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length === 0) {
                        CommonMethod.MarkMessageUnread(mergedObject.conversationId, LoginUserIDV2, mergedObject.id, false, true, mergedObject.senderIdHash, false)
                    }


                    const updatedData = convertionData.map(d =>
                        d.conversationId === data.conversationId
                            ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, customerIdHash: data.senderIdHash, isCustomerOnline: true, isAIMessage: data.isAIMessage }
                            : d
                    );
                    chatSelection.AllData.isCustomerOnline = true;
                    const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                    setTempConvertionData(sortedConversations);
                    setConvertionData(sortedConversations);
                    setMessageConvertions(prevState => {
                        const updatedChatData = { ...prevState };
                        const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                        if (!TempChatResponse.sentBy && mergedObject.senderId) {
                            TempChatResponse.sentBy = mergedObject.senderId
                        }
                        if (updatedChatData[dateKey]) {
                            updatedChatData[dateKey].push(TempChatResponse);
                        } else {
                            updatedChatData[dateKey] = [TempChatResponse];
                        }
                        return updatedChatData;
                    });
                    // Rightside Panel Detail Update
                    if (mergedObject.isGroupUpdateMessage === true) {
                        CommonMethod.RenderRightSidePanel();
                    }
                    window.CheckcustomerOnline(true);
                }
                middleArea.current.scrollTop = middleArea.current.scrollHeight;
            } else {
                if (unReadConversionCustomer.filter(c => c === data.conversationId).length === 0) {
                    if (data.conversationId !== chatSelection.ConversationId) {
                        setUnReadConvertionCustomer(unReadConversionCustomer.concat(data.conversationId));
                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                    }
                }
            }
        }

        if (mergedObject.messageType === VagaroConnectMessageType.EmployeeChatMessage) {
            //Employee Message sent by Employee
            if (!isCustomerChat) {
                if (mergedObject.senderIdHash !== LoginUserIdHash) {
                    if (mergedObject.senderIdHash && mergedObject.conversationId) {
                        dispatch(RemoveTypingUser({ id: mergedObject.conversationId, userId: mergedObject.senderIdHash }))
                    }
                    if (mergedObject.groupMembers !== undefined && mergedObject.groupMembers !== null && mergedObject.groupMembers.length > 1) {
                        mergedObject.receiverIdHash = LoginUserIdHash;
                        var memberReceiverIdHash = mergedObject.groupMembers.filter((x) => x.memberIdHash === LoginUserIdHash && x.isMemberRemovedFromGroup === false);

                        if (memberReceiverIdHash.length > 0) {
                            ReceiverIdHash = memberReceiverIdHash[0].memberIdHash;
                        }
                        else {
                            return;
                        }
                    } else {
                        return;
                    }
                }

                if (mergedObject.senderIdHash !== LoginUserIdHash && isLeftOption === LeftControlNames.Employee) {
                    var ReceiverIdHash = "";
                    if (mergedObject.groupMembers !== undefined && mergedObject.groupMembers !== null && mergedObject.groupMembers.length > 1) {
                        mergedObject.receiverIdHash = LoginUserIdHash;
                        var memberReceiverIdHash = mergedObject.groupMembers.filter((x) => x.memberIdHash === LoginUserIdHash);
                        if (memberReceiverIdHash.length > 0) {
                            ReceiverIdHash = memberReceiverIdHash[0].memberIdHash;
                        }
                    } else {
                        ReceiverIdHash = LoginUserIdHash;
                    }
                    if (mergedObject.receiverIdHash === ReceiverIdHash) {
                        if (isNewChat && isLeftOption === LeftControlNames.Employee) {
                            GetConvertion();
                            setUserFromConversation(true);
                        }
                        if (mergedObject.conversationId !== chatSelection.ConversationId && !isNewChat) {
                            // ReadUnreadCount();

                            if (unReadConversionEmployee.filter(c => c === data.conversationId).length === 0) {
                                setUnReadConvertionEmployee(unReadConversionEmployee.concat(data.conversationId));
                                setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                            }

                            var checkConversationExist = convertionData.filter((x) => x.conversationId === data.conversationId).length > 0 ? true : false;
                            if (!checkConversationExist && mergedObject.isMessageFromCustomer === false && isLeftOption === LeftControlNames.Employee) {
                                let AddConversetionData = convertionData;
                                AddConversationToObject.isLoggedInUserRemovedFromGroup = false;
                                AddConversetionData.unshift(AddConversationToObject);
                                mergeConvertionData(AddConversetionData, true);
                            } else {
                                var divElement = $('#' + data.conversationId + '.active');
                                if (divElement.length > 0) {
                                    setMessageConvertions(prevState => {
                                        const updatedChatData = { ...prevState };
                                        const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                                        if (updatedChatData[dateKey]) {
                                            updatedChatData[dateKey].push(TempChatResponse);
                                        } else {
                                            updatedChatData[dateKey] = [TempChatResponse];
                                        }
                                        return updatedChatData;
                                    });
                                } else if ($('#' + data.conversationId).length > 0) {
                                    //mergeChatData1(data)
                                }
                                setTempChatData(mergedObject);
                                const updatedData = convertionData.map(d =>
                                    d.conversationId === data.conversationId
                                        ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, isRead: divElement.length > 0 ? true : false, customerIdHash: data.senderIdHash, profileImage: data.senderPhoto }
                                        : d
                                );
                                const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                                setTempConvertionData(sortedConversations);
                                setConvertionData(sortedConversations);
                            }
                        }
                        else {
                            const updatedData = convertionData.map(d =>
                                d.conversationId === data.conversationId
                                    ? { ...d, lastMessageDate: mergedObject.date, lastMessage: data.message, messageId: data.id, customerIdHash: data.senderIdHash, profileImage: data.senderPhoto, isRead: true }
                                    : d
                            );
                            const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                            setTempConvertionData(sortedConversations);
                            setConvertionData(sortedConversations);

                            setMessageConvertions(prevState => {
                                const updatedChatData = { ...prevState };
                                const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                                if (!TempChatResponse.sentBy && mergedObject.senderId) {
                                    TempChatResponse.sentBy = mergedObject.senderId
                                }
                                if (updatedChatData[dateKey]) {
                                    updatedChatData[dateKey].push(TempChatResponse);
                                } else {
                                    updatedChatData[dateKey] = [TempChatResponse];
                                }
                                return updatedChatData;
                            });
                            // Rightside Panel Detail Update
                            if (mergedObject.isGroupUpdateMessage === true) {
                                CommonMethod.RenderRightSidePanel();
                            }
                            CommonMethod.MarkMessageUnread(mergedObject.conversationId, LoginUserIDV2, mergedObject.id, false, true, "", true, "", true)
                        }
                        middleArea.current.scrollTop = middleArea.current.scrollHeight;
                    }
                } else {
                    if (unReadConversionEmployee.filter(c => c === data.conversationId).length === 0
                        && data.senderIdHash != LoginUserIdHash) {
                        if (data.conversationId !== chatSelection.ConversationId) {
                            setUnReadConvertionEmployee(unReadConversionEmployee.concat(data.conversationId));
                            setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                        }
                    }
                }
            }
        }

        if (mergedObject.messageType === VagaroConnectMessageType.ReadStatusMessage) {
            if (!isCustomerChat) {
                var isRead = mergedObject.message === "Read" ? true : false;
                markReadAndUnreadChat(isRead, mergedObject.conversationId);
                if (isRead) {
                    if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length > 0) {
                        setUnReadConvertionCustomer(unReadConversionCustomer.filter(c => c !== mergedObject.conversationId));
                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer - 1);
                    }
                } else {
                    if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length === 0) {
                        setUnReadConvertionCustomer(unReadConversionCustomer.concat(mergedObject.conversationId));
                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                    }
                }
            }
        }

        if (mergedObject.messageType === VagaroConnectMessageType.CustomerHasRemovedChannel) {
            //
        }

        if (mergedObject.messageType === VagaroConnectMessageType.CustomerRemoveChat) {
            //Remove chat customer
            if (isLeftOption === LeftControlNames.Customer) {
                if (!isCustomerChat) {
                    if (convertionData.filter((data) => data.conversationId === mergedObject.conversationId).length > 0) {
                        // ReadUnreadCount();

                        if (unReadConversionCustomer.filter(c => c === mergedObject.conversationId).length > 0) {
                            setUnReadConvertionCustomer(unReadConversionCustomer.filter(c => c !== mergedObject.conversationId));
                            setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer - 1);
                        } else if (unReadConversionEmployee.filter(e => e === mergedObject.conversationId).length > 0) {
                            setUnReadConvertionEmployee(unReadConversionEmployee.filter(e => e !== mergedObject.conversationId));
                            setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee - 1);
                        }

                        const updatedData = convertionData.filter((data) => data.conversationId !== mergedObject.conversationId);
                        if (updatedData.length > 0) {
                            setConvertionData(updatedData);
                            setTempConvertionData(updatedData);
                            if (chatSelection.ConversationId === mergedObject.conversationId) {
                                setConversetionChatSelection(updatedData[0], true);
                            }
                        } else {
                            if (searchText.length > 0) {
                                setnoRecord(true);
                                setnoRecordUnread(true);
                            } else {
                                setIsNewChat(true);
                                setConvertionData([]);
                            }
                        }
                        removeConversationItem(1, mergedObject.conversationId, LoginUserIdHash);
                    }
                }
            }
        }

        if (mergedObject.messageType === VagaroConnectMessageType.EditMessage) {
            //Edit Message
            if (isCustomerChat) {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    const dateKey = moment(mergedObject.date).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            message: TempChatResponse.message,
                                            attachment: TempChatResponse.attachment,
                                            reactions: TempChatResponse.reactions
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
            } else {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    let getConversation = convertionData.filter((x) => x.conversationId === mergedObject.conversationId);
                    if (getConversation.length > 0 && getConversation[0].isGroupChat) {
                        if (CheckLoginUserIsRemovedFromChat(getConversation[0]) === true) {
                            return false;
                        }
                    }
                    const dateKey = moment(mergedObject.date).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            message: TempChatResponse.message,
                                            attachment: TempChatResponse.attachment,
                                            reactions: TempChatResponse.reactions
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
                const updatedData = convertionData.map(d =>
                    d.conversationId === mergedObject.conversationId && d.lastMessageDate === TempChatResponse.messageDate
                        ? { ...d, lastMessage: TempChatResponse.message, customerIdHash: d.isGroupChat ? data.senderIdHash : d.customerIdHash, attachment: TempChatResponse.attachment, reactions: TempChatResponse.reactions }
                        : d
                );
                setConvertionData(updatedData);
            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.DeleteMessage || mergedObject.messageType === VagaroConnectMessageType.UndoDeleteMessage) {
            //Delete / UndoDelete
            var isDeleted = mergedObject.messageType === VagaroConnectMessageType.DeleteMessage ? true : false;
            if (isCustomerChat) {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    const dateKey = moment(mergedObject.date).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            isDeleted: isDeleted,
                                            isMessageFromCustomer: TempChatResponse.isMessageFromCustomer
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
            } else {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    const dateKey = moment(mergedObject.date).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            isDeleted: isDeleted
                                            //isMessageFromCustomer: TempChatResponse.isMessageFromCustomer
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
                const updatedData = convertionData.map(d =>
                    d.conversationId === mergedObject.conversationId && d.lastMessageDate === TempChatResponse.messageDate
                        ? { ...d, lastMessage: isDeleted ? "This message was deleted" : TempChatResponse.message, customerIdHash: d.isGroupChat ? data.senderIdHash : d.customerIdHash }
                        : d
                );
                setConvertionData(updatedData);

            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.BlockCustomer || mergedObject.messageType === VagaroConnectMessageType.UnblockCustomer) {
            //Block customer / UnBlock Customer

            var isBlocked = mergedObject.messageType === VagaroConnectMessageType.BlockCustomer ? true : false;
            if (isCustomerChat) {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    chatSelection.IsBlocked = isBlocked;
                    chatSelection.AllData.isBlocked = isBlocked;

                    var result = hasGroupUpdateMessage(messageConvertions);
                    if (isBlocked) {
                        if (result === false) {
                            var blockMessage = {
                                message: `${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.`,
                                isDeleted: false,
                                isGroupUpdateMessage: true,
                                messageDate: new Date(),
                                isMessageFromCustomer: false
                            }
                            var dateOfMessage = result === false ? new Date() : result;
                            var dateKey = moment(dateOfMessage).format('YYYY-MM-DD');
                            setMessageConvertions(prevState => {
                                if (prevState[dateKey]) {
                                    const updatedMessages = [...prevState[dateKey], blockMessage];
                                    return {
                                        ...prevState,
                                        [dateKey]: updatedMessages
                                    };
                                } else {
                                    return {
                                        ...prevState,
                                        [dateKey]: [blockMessage]
                                    };
                                }
                            });
                        }
                    } else {
                        var dateKey = moment(result).format('YYYY-MM-DD');
                        setMessageConvertions(prevState => {
                            if (prevState[dateKey]) {
                                const updatedMessages = prevState[dateKey].filter(x => x.messageId !== undefined);
                                return {
                                    ...prevState,
                                    [dateKey]: updatedMessages
                                };
                            }
                            return prevState;
                        });
                    }
                }
            } else {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    chatSelection.IsBlocked = isBlocked;
                    chatSelection.AllData.isBlocked = isBlocked;
                }
                // const updatedData = convertionData.map(item =>
                //     item.conversationId === mergedObject.conversationId
                //         ? { ...item, isBlocked: isBlocked, customerIdHash: data.sentByHash, lastName: data.senderLastName, firstName: data.senderFirstName, name: data.fullName, cdnUrl: data.senderCdnUrl, profileImage: data.senderPhoto }
                //         : item
                // );

                const updatedData = convertionData.map(item =>
                    item.conversationId === mergedObject.conversationId
                        ? { ...item, isBlocked: isBlocked }
                        : item
                );
                setConvertionData(updatedData);
            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.ChangeGroupName) {
            if (!isCustomerChat) {
                if (mergedObject.receiverIdHash !== LoginUserIdHash && mergedObject.message !== "") {
                    if (mergedObject.conversationId === chatSelection.ConversationId && !isNewChat) {
                        TempChatResponse.isGroupUpdateMessage = true;
                        setMessageConvertions(prevState => {
                            const updatedChatData = { ...prevState };
                            const dateKey = moment().format('YYYY-MM-DD');
                            if (updatedChatData[dateKey]) {
                                updatedChatData[dateKey].push(TempChatResponse);
                            } else {
                                updatedChatData[dateKey] = [TempChatResponse];
                            }
                            return updatedChatData;
                        });
                        if (TempChatResponse.isGroupUpdateMessage === true) {
                            CommonMethod.RenderRightSidePanel();
                        }
                        chatSelection.AllData.isRead = true;
                    }
                    const updatedData = convertionData.map(item => item.conversationId === mergedObject.conversationId ? { ...item, name: mergedObject.fullName, lastMessage: mergedObject.message, isRead: true, customerIdHash: mergedObject.receiverIdHash } : item);
                    setConvertionData(updatedData);
                }
            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.AddMemberInGroup || mergedObject.messageType === VagaroConnectMessageType.AddMemberInGroupWithHistory) {
            //Add member in group
            if (!isCustomerChat) {
                if (mergedObject.receiverIdHash !== LoginUserIdHash) {
                    if (mergedObject.conversationId === chatSelection.ConversationId && !isNewChat) {
                        TempChatResponse.isGroupUpdateMessage = true;
                        setMessageConvertions(prevState => {
                            const updatedChatData = { ...prevState };
                            const dateKey = moment().format('YYYY-MM-DD');
                            if (updatedChatData[dateKey]) {
                                updatedChatData[dateKey].push(TempChatResponse);
                            } else {
                                updatedChatData[dateKey] = [TempChatResponse];
                            }
                            return updatedChatData;
                        });
                        if (TempChatResponse.isGroupUpdateMessage === true) {
                            CommonMethod.RenderRightSidePanel();
                        }
                        chatSelection.AllData.isRead = true;
                    } else {
                        if (unReadConversionEmployee.filter(c => c === mergedObject.conversationId).length === 0) {
                            setUnReadConvertionEmployee(unReadConversionEmployee.concat(mergedObject.conversationId));
                            setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                        }
                    }
                    if (convertionData.filter((x) => x.conversationId === mergedObject.conversationId).length > 0) {
                        const isLoggedInUserRemovedFromGroupTemp = mergedObject.groupMembers.filter((x) => x.memberIdHash === LoginUserIdHash).length > 0 ? mergedObject.groupMembers.filter((x) => x.memberIdHash === LoginUserIdHash)[0].isMemberRemovedFromGroup : false

                        const updatedData = convertionData.map(item => item.conversationId === mergedObject.conversationId ? { ...item, members: mergedObject.groupMembers, isLoggedInUserRemovedFromGroup: isLoggedInUserRemovedFromGroupTemp, name: mergedObject.fullName, isRead: false } : item);
                        if (mergedObject.conversationId === chatSelection.ConversationId && !isNewChat) {
                            chatSelection.AllData.isLoggedInUserRemovedFromGroup = isLoggedInUserRemovedFromGroupTemp;
                        }
                        setConvertionData(updatedData);
                        setTempConvertionData(updatedData);
                    } else {
                        GetConvertion();
                    }
                    if (mergedObject.messageType === VagaroConnectMessageType.AddMemberInGroupWithHistory) {
                        CommonMethod.MessageConvertionRender();
                    }
                }
            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.RemoveMemberFromGroup) {
            // Remove member in group
            if (!isCustomerChat) {
                // if (mergedObject.receiverIdHash !== LoginUserIdHash) {
                if (mergedObject.conversationId === chatSelection.ConversationId && !isNewChat) {
                    TempChatResponse.isGroupUpdateMessage = true;
                    setMessageConvertions(prevState => {
                        const updatedChatData = { ...prevState };
                        const dateKey = moment().format('YYYY-MM-DD');
                        if (updatedChatData[dateKey]) {
                            updatedChatData[dateKey].push(TempChatResponse);
                        } else {
                            updatedChatData[dateKey] = [TempChatResponse];
                        }
                        return updatedChatData;
                    });
                    if (TempChatResponse.isGroupUpdateMessage === true) {
                        CommonMethod.RenderRightSidePanel();
                    }
                    chatSelection.AllData.isRead = true;
                } else {
                    if (unReadConversionEmployee.filter(c => c === mergedObject.conversationId).length === 0) {
                        setUnReadConvertionEmployee(unReadConversionEmployee.concat(mergedObject.conversationId));
                        setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                    }
                }
                let updatedData = convertionData;
                if (LoginUserIdHash === mergedObject.memberIds && mergedObject.conversationId === chatSelection.ConversationId && !isNewChat) {
                    chatSelection.AllData.isLoggedInUserRemovedFromGroup = true;
                }
                let element = "";
                const filterdata = updatedData?.filter((d) => d.conversationId === mergedObject.conversationId);
                if (filterdata.length > 0) {
                    element = filterdata[0].members.map(item => item.memberIdHash === mergedObject.memberIds ? { ...item, isMemberRemovedFromGroup: true } : item);
                    filterdata[0].members = element;
                    filterdata[0].lastMessage = mergedObject.message;
                    filterdata[0].name = mergedObject.fullName;
                    filterdata[0].isRead = false;
                }

                updatedData = convertionData?.map(item => item.conversationId === mergedObject.conversationId ? filterdata[0] : item);
                setConvertionData(updatedData);
                setTempConvertionData(updatedData);
                // if (chatSelection.ConversationId === mergedObject.conversationId) {
                //     setConversetionChatSelection(updatedData[0], true);
                // }
                // }
            }
        }
        if (mergedObject.messageType === VagaroConnectMessageType.UpdateTextUsage) {
            if (!isCustomerChat) {
                const textData = mergedObject;
                var percentage = ((textData.usage * 100) / textData.planUsageLimit).toFixed(2);
                var variant = "success";
                if (percentage >= 0 && percentage <= 90) {
                    variant = "success";
                }
                else if (percentage > 90 && percentage < 100) {
                    variant = "warning";
                }
                else if (percentage >= 100) {
                    variant = "danger";
                }
                Addtextmarketing({ ...textData, percentage: percentage, variant: variant });
            }
        }
    };

    window.OpenConnectFromTwilio = (data) => {
        if (data !== null) {
            setTimeout(() => {
                window.SetMessageCopy(data);
                Textmarketing(false);
            }, 1000)
        }
    };

    window.ResetValueCustomerAndEmployee = () => {
        customerDataRef.current = "";
        groupMemberArrRef.current = null;
    }

    window.SetMessage = (data) => {
        var mergedObject = data;
        var TempChatResponse = {
            messageId: mergedObject.messageId,
            message: mergedObject.message,
            messageDate: new Date(),
            sentByHash: mergedObject.sentByHash,
            fullName: mergedObject.fullName,
            isNote: mergedObject.isNote,
            isGroupUpdateMessage: mergedObject.isGroupUpdateMessage,
            isDeleted: mergedObject.isDeleted,
            isMessageFromCustomer: mergedObject.isMessageFromCustomer,
            status: mergedObject.status,
            action: mergedObject.action,
            senderFirstName: mergedObject.senderFirstName,
            senderLastName: mergedObject.senderLastName,
            senderPhoto: mergedObject.senderPhoto,
            senderCdnURL: mergedObject.senderCdnUrl

        };
        //Edit Message
        if (mergedObject.action !== null && mergedObject.action === "Edit") {
            if (isCustomerChat) {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    const dateKey = moment(mergedObject.messageDate).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            message: TempChatResponse.message
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
            } else {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    const dateKey = moment(mergedObject.messageDate).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            message: TempChatResponse.message
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
                const updatedData = convertionData.map(d =>
                    d.conversationId === mergedObject.conversationId && d.messageId === TempChatResponse.messageId
                        ? { ...d, lastMessage: TempChatResponse.message, customerIdHash: data.sentByHash }
                        : d
                );
                setConvertionData(updatedData);

            }
        }
        //Delete/Undo Delete Message
        else if (mergedObject.action !== null && (mergedObject.action === "Delete" || mergedObject.action === "UndoDelete")) {
            if (isCustomerChat) {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    const dateKey = moment(mergedObject.messageDate).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            isDeleted: TempChatResponse.isDeleted,
                                            isMessageFromCustomer: TempChatResponse.isMessageFromCustomer
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
            } else {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    const dateKey = moment(mergedObject.messageDate).format('YYYY-MM-DD');
                    setMessageConvertions(prevState => {
                        if (prevState[dateKey]) {
                            return {
                                ...prevState,
                                [dateKey]: prevState[dateKey].map((x) => {
                                    if (x.messageId === TempChatResponse.messageId) {
                                        return {
                                            ...x,
                                            isDeleted: TempChatResponse.isDeleted
                                            //isMessageFromCustomer: TempChatResponse.isMessageFromCustomer
                                        };
                                    }
                                    return x;
                                })
                            };
                        }
                        return prevState;
                    });
                }
                const updatedData = convertionData.map(d =>
                    d.conversationId === mergedObject.conversationId && d.messageId === TempChatResponse.messageId
                        ? { ...d, lastMessage: TempChatResponse.isDeleted ? "This message was deleted" : TempChatResponse.message, customerIdHash: data.sentByHash }
                        : d
                );
                setConvertionData(updatedData);

            }
        }
        //Block / unblock customer
        else if (mergedObject.action !== null && (mergedObject.action === "Block" || mergedObject.action === "Unblock")) {
            var isBlocked = mergedObject.action === "Block" ? true : false;
            if (isCustomerChat) {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    chatSelection.IsBlocked = isBlocked;
                    chatSelection.AllData.isBlocked = isBlocked;

                    var result = hasGroupUpdateMessage(messageConvertions);
                    if (isBlocked) {
                        if (result === false) {
                            var blockMessage = {
                                message: `${ReactBusinessName} is currently not accepting messages at this time. Please try calling the business directly.`,
                                isDeleted: false,
                                isGroupUpdateMessage: true,
                                messageDate: new Date(),
                                isMessageFromCustomer: false
                            }
                            var dateOfMessage = result === false ? new Date() : result;
                            var dateKey = moment(dateOfMessage).format('YYYY-MM-DD');
                            setMessageConvertions(prevState => {
                                if (prevState[dateKey]) {
                                    const updatedMessages = [...prevState[dateKey], blockMessage];
                                    return {
                                        ...prevState,
                                        [dateKey]: updatedMessages
                                    };
                                } else {
                                    return {
                                        ...prevState,
                                        [dateKey]: [blockMessage]
                                    };
                                }
                            });
                        }
                    } else {
                        var dateKey = moment(result).format('YYYY-MM-DD');
                        setMessageConvertions(prevState => {
                            if (prevState[dateKey]) {
                                const updatedMessages = prevState[dateKey].filter(x => x.isGroupUpdateMessage === false);
                                return {
                                    ...prevState,
                                    [dateKey]: updatedMessages
                                };
                            }
                            return prevState;
                        });
                    }
                }
            } else {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    chatSelection.IsBlocked = isBlocked;
                    chatSelection.AllData.isBlocked = isBlocked;
                }
                const updatedData = convertionData.map(item =>
                    item.conversationId === mergedObject.conversationId
                        ? { ...item, isBlocked: isBlocked, customerIdHash: data.sentByHash, lastName: data.senderLastName, firstName: data.senderFirstName, name: data.fullName, cdnUrl: data.senderCdnUrl, profileImage: data.senderPhoto }
                        : item
                );
                setConvertionData(updatedData);
            }
        }
        // Remove Chat
        else if (mergedObject.action !== null && mergedObject.action === "RemoveChat") {
            const updatedData = convertionData.filter((data) => data.conversationId !== mergedObject.conversationId);
            if (updatedData.length > 0) {
                setConvertionData(updatedData);
                if (chatSelection.ConversationId === mergedObject.conversationId) {
                    setConversetionChatSelection(updatedData[0], true);
                }
            } else {
                setIsNewChat(true);
                setConvertionData([]);
            }
        }
        else if (mergedObject.action !== null && mergedObject.action === MassageTypeEnum.ChageGroupName) {
            const updatedData = convertionData.map(item => item.conversationId === mergedObject.conversationId ? { ...item, name: mergedObject.fullName } : item);
            setConvertionData(updatedData);
        }
        else if (mergedObject.action !== null && mergedObject.action === MassageTypeEnum.AddMamber) {
            const Members = mergedObject.memberIds.split(",").map(val => allserviceprovider.find(provider => provider.UserIdHash === val));
            const updatedData = convertionData;
            for (let index = 0; index < updatedData.length; index++) {
                var element = updatedData[index];
                if (element.conversationId === mergedObject.conversationId) {
                    for (let innrindex = 0; innrindex < Members.length; innrindex++) {
                        const member = Members[innrindex];
                        const isPush = member ? updatedData[index].members.find(val => val.memberIdHash === member.UserIdHash) : null;
                        if (member !== undefined && isPush) {
                            updatedData[index].members.push({
                                "memberId": member.value,
                                "memberIdHash": member.UserIdHash,
                                "cdnUrl": member.profile.split("Original/")[0] || "",
                                "photo": member.profile.split("Original/")[1] || "",
                                "firstName": member.label.split(" ")[0] || "",
                                "lastName": member.label.split(" ")[1] || ""
                            });
                        }
                    }
                }
            }
            setConvertionData(updatedData);

        }
        else if (mergedObject.action !== null && mergedObject.action === MassageTypeEnum.RemoveMamber) {
            let updatedData = convertionData;
            if (LoginUserIdHash === mergedObject.memberIds) {
                updatedData = convertionData.filter(item => item.conversationId !== mergedObject.conversationId);
            } else {
                updatedData = convertionData.map(item => item.conversationId === mergedObject.conversationId ? { ...item, members: item.members.filter((val) => val.memberIdHash !== mergedObject.memberIds) } : item);
            }
            setConvertionData(updatedData);
            if (chatSelection.ConversationId === mergedObject.conversationId) {
                setConversetionChatSelection(updatedData[0], true);
            }
        }


        // Add Chat for Customer
        var AddConversationToObject = {
            "conversationId": mergedObject.conversationId,
            "customerIdHash": mergedObject.sentByHash,
            "customerId": mergedObject.senderId,
            "name": mergedObject.members === null ? mergedObject.fullName : mergedObject.members !== null && mergedObject.members.length > 0 ? mergedObject.members.map((m) => m.firstName).join(", ") : "",
            "lastMessage": mergedObject.message,
            "lastMessageDate": mergedObject.messageDate,
            "isPin": false,
            "isMute": false,
            "userType": mergedObject.action === "NewChatFromEmployeeToEmployee" ? 0 : 1,
            "isGroupChat": mergedObject.members === null ? false : true,
            "messageId": mergedObject.messageId,
            "isRead": false,
            "cdnUrl": mergedObject.senderCdnUrl,
            "firstName": mergedObject.senderFirstName,
            "lastName": mergedObject.senderLastName,
            "isBlocked": false,
            "pageIndex": 0,
            "isDeleted": false,
            "profileImage": mergedObject.senderPhoto,
            "members": mergedObject.members,
            // "groupAdminIdHash": mergedObject.groupAdminIdHash
        };
        if (mergedObject.action !== null && (mergedObject.action === "NewChatFromBusinessToCustomer" || mergedObject.action === "NewChatFromBusinessToCustomerForEmployee")) {
            if (isCustomerChat && mergedObject.action === "NewChatFromBusinessToCustomer") {
                if (chatSelection.ConversationId === "") {
                    setConversionidForCustomer(mergedObject.conversationId);
                }
                mergedObject.action = null;
            } else if (mergedObject.action === "NewChatFromBusinessToCustomerForEmployee") {
                if (isLeftOption === LeftControlNames.Customer) {
                    if (mergedObject.isMessageFromCustomer === false) {
                        var checkConversationExist = convertionData.filter((x) => x.conversationId === data.conversationId).length > 0 ? true : false;
                        if (!checkConversationExist) {
                            let AddConversetionData = convertionData;
                            AddConversetionData.unshift(AddConversationToObject);
                            mergeConvertionData(AddConversetionData, true);
                        } else {
                            mergedObject.action = null;
                        }
                    }
                    else {
                        if (unReadConversionEmployee.filter((x) => x.conversationId === mergedObject.conversationId).length === 0) {
                            setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: mergedObject.conversationId }))
                            setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                        }
                    }
                }
            }
        }
        //Add Conversion into Business
        else if (mergedObject.action !== null && mergedObject.action === "NewChatFromCustomer") {
            if (isLeftOption === LeftControlNames.Customer) {
                if (mergedObject.isMessageFromCustomer === true) {
                    if (convertionData.length === 0 && isNewChat) {
                        GetConvertion();
                        setUserFromConversation(true);
                    } else {
                        let AddConversetionData = convertionData;
                        AddConversetionData.unshift(AddConversationToObject);
                        mergeConvertionData(AddConversetionData, true);
                    }
                }
            } else {
                if (unReadConversionCustomer.filter((x) => x.conversationId === mergedObject.conversationId).length === 0) {
                    setUnReadConvertionCustomer(unReadConversionCustomer.concat({ conversationId: mergedObject.conversationId }))
                    setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                }
            }
        }
        else if (mergedObject.action !== null && mergedObject.action === "NewChatFromEmployeeToEmployee") {
            if (isLeftOption === LeftControlNames.Employee) {
                if (mergedObject.isMessageFromCustomer === false) {
                    var checkConversationExist = convertionData.filter((x) => x.conversationId === data.conversationId).length > 0 ? true : false;
                    if (!checkConversationExist) {
                        if (convertionData.length === 0 && isNewChat) {
                            GetConvertion();
                            setUserFromConversation(true);
                        } else {
                            let AddConversetionData = convertionData;
                            AddConversetionData.unshift(AddConversationToObject);
                            mergeConvertionData(AddConversetionData, true);
                        }
                    } else {
                        mergedObject.action = null;
                    }
                }
            } else {
                if (unReadConversionEmployee.filter((x) => x.conversationId === mergedObject.conversationId).length === 0) {
                    setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: mergedObject.conversationId }))
                    setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                }
            }
        }

        if (mergedObject.action === null) {
            if (isCustomerChat) {
                if (mergedObject.conversationId === chatSelection.ConversationId) {
                    setMessageConvertions(prevState => {
                        const updatedChatData = { ...prevState };
                        const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                        if (updatedChatData[dateKey]) {
                            updatedChatData[dateKey].push(TempChatResponse);
                        } else {
                            updatedChatData[dateKey] = [TempChatResponse];
                        }
                        return updatedChatData;
                    });
                }
                middleArea.current.scrollTop = middleArea.current.scrollHeight;
            } else {
                if (mergedObject.conversationId !== chatSelection.ConversationId) {

                    var checkConversationExist = convertionData.filter((x) => x.conversationId === data.conversationId).length > 0 ? true : false;
                    if (!checkConversationExist && mergedObject.isMessageFromCustomer === true && isLeftOption === LeftControlNames.Customer) {
                        let AddConversetionData = convertionData;
                        AddConversetionData.unshift(AddConversationToObject);
                        mergeConvertionData(AddConversetionData, true);
                    } else {
                        var divElement = $('#' + data.conversationId + '.active');
                        if (divElement.length > 0) {
                            setMessageConvertions(prevState => {
                                const updatedChatData = { ...prevState };
                                const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                                if (updatedChatData[dateKey]) {
                                    updatedChatData[dateKey].push(TempChatResponse);
                                } else {
                                    updatedChatData[dateKey] = [TempChatResponse];
                                }
                                return updatedChatData;
                            });
                        } else if ($('#' + data.conversationId).length > 0) {
                            //mergeChatData1(data)
                        }
                        setTempChatData(mergedObject);
                        const updatedData = convertionData.map(d =>
                            d.conversationId === data.conversationId
                                ? { ...d, lastMessageDate: new Date(), lastMessage: data.message, messageId: data.messageId, isRead: divElement.length > 0 ? true : false, customerIdHash: data.sentByHash, lastName: data.senderLastName, firstName: data.senderFirstName, name: data.fullName, cdnUrl: data.senderCdnUrl, profileImage: data.senderPhoto }
                                : d
                        );
                        const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                        setTempConvertionData(sortedConversations);
                        setConvertionData(sortedConversations);

                        if (!isCustomerChat) {

                            if (isLeftOption === LeftControlNames.Customer) {
                                if (mergedObject.isMessageFromCustomer === true) {
                                    if (unReadConversionCustomer.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                        setUnReadConvertionCustomer(unReadConversionCustomer.concat({ conversationId: data.conversationId }))
                                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                                    }
                                    // setUnReadCountCustomer(sortedConversations.filter((x) => x.conversationId !== chatSelection.ConversationId && x.isRead === false).length);
                                } else {
                                    if (unReadConversionEmployee.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                        setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: data.conversationId }))
                                        setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                                    }
                                }
                            }
                            // } else if (mergedObject.isMessageFromCustomer === false && mergedObject.sentByHash !== props.LoginUserIdHash) {
                            if (isLeftOption === LeftControlNames.Employee) {
                                if (mergedObject.isMessageFromCustomer === false) {
                                    if (unReadConversionEmployee.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                        setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: data.conversationId }))
                                        setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                                    }
                                    // setUnReadCountEmployee(sortedConversations.filter((x) => x.conversationId !== chatSelection.ConversationId && x.isRead === false).length);
                                } else {
                                    if (unReadConversionCustomer.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                        setUnReadConvertionCustomer(unReadConversionCustomer.concat({ conversationId: data.conversationId }))
                                        setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                                    }
                                }
                            }
                            // }
                        }
                    }
                }
                // }
                else {
                    if (!isCustomerChat && typeof window !== "undefined" && typeof window.top !== "undefined" && window.top.$('#chatFrame') !== undefined && !window.top.$('#chatFrame').hasClass('ifreamVC')) {
                        if (isLeftOption === LeftControlNames.Customer) {
                            if (mergedObject.isMessageFromCustomer === true) {
                                if (unReadConversionCustomer.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                    setUnReadConvertionCustomer(unReadConversionCustomer.concat({ conversationId: data.conversationId }))
                                    setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                                }
                            } else {
                                if (unReadConversionEmployee.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                    setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: data.conversationId }))
                                    setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                                }
                            }
                        }
                        // } else if (mergedObject.isMessageFromCustomer === false && mergedObject.sentByHash !== props.LoginUserIdHash) {
                        if (isLeftOption === LeftControlNames.Employee) {
                            if (mergedObject.isMessageFromCustomer === false) {
                                if (unReadConversionEmployee.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                    setUnReadConvertionEmployee(unReadConversionEmployee.concat({ conversationId: data.conversationId }))
                                    setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee + 1);
                                }
                            } else {
                                if (unReadConversionCustomer.filter((x) => x.conversationId === data.conversationId).length === 0) {
                                    setUnReadConvertionCustomer(unReadConversionCustomer.concat({ conversationId: data.conversationId }))
                                    setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer + 1);
                                }
                            }
                        }
                    }
                    const updatedData = convertionData.map(d =>
                        d.conversationId === data.conversationId
                            ? { ...d, lastMessageDate: new Date(), lastMessage: data.message, messageId: data.messageId, customerIdHash: data.sentByHash, lastName: data.senderLastName, firstName: data.senderFirstName, name: data.fullName, cdnUrl: data.senderCdnUrl, profileImage: data.senderPhoto }
                            : d
                    );
                    const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                    setTempConvertionData(sortedConversations);
                    setConvertionData(sortedConversations);

                    setMessageConvertions(prevState => {
                        const updatedChatData = { ...prevState };
                        const dateKey = moment(TempChatResponse.messageDate).format('YYYY-MM-DD');
                        if (updatedChatData[dateKey]) {
                            updatedChatData[dateKey].push(TempChatResponse);
                        } else {
                            updatedChatData[dateKey] = [TempChatResponse];
                        }
                        return updatedChatData;
                    });
                    // Rightside Panel Detail Update
                    if (mergedObject.isGroupUpdateMessage === true) {
                        CommonMethod.RenderRightSidePanel();
                    }
                }
                middleArea.current.scrollTop = middleArea.current.scrollHeight;
            }
        }
    };

    const connectWindowSizeMessageHeader = () => {
        utils.SetMassageAreaWidth(isCustomerChat === false && expandMinimizeShow === 0);
    };

    useEffect(() => {
        var totalChatCount = unReadCountCustomer + unReadCountEmployee;
        if (!isCustomerChat && !isAndroidIOSMobile) {
            if (totalChatCount > 0) {
                window.parent.postMessage('{"eventType": "setUnreadCount","Data": "' + totalChatCount + '"}', '*');
            } else {
                window.parent.postMessage('{"eventType": "setUnreadCount","Data": "0"}', '*');
            }
        }
        if (!isCustomerChat && isFromConnectApp && firstTimeActiveChannelCalled.current) {
            var jsonData =
            {
                "appicon": null,
                "messageicon": totalChatCount,
                "Type": 2
            }
            utils.CallBackGivenToMobile(MobileCallBackAction.BagdeCountForConnectApp, jsonData);
            setTimeout(() => {
                GetBagdeCountForConnectApp();
            }, 1000);
        }
    }, [unReadCountCustomer, unReadCountEmployee])

    useEffect(() => {
        connectWindowSizeMessageHeader();
        window.addEventListener('resize', connectWindowSizeMessageHeader);
        utils.expandMinimizeSize = expandMinimizeShow;
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', connectWindowSizeMessageHeader);
        };
    }, [expandMinimizeShow])
    useEffect(() => {
        if (convertionPageIndex > 1 && paging === true && isScrollEnable.current) {
            isScrollEnable.current = false;
            GetConvertion();
        }
    }, [convertionPageIndex]);

    useEffect(() => {
        GetEmployee(true);
        if (isFromConnectApp) {
            setTimeout(() => {
                Textmarketing(true);
            }, 500);
        } else {
            if (typeof window !== "undefined" && !isCustomerChat) {
                Textmarketing(true);
            }
        }
    }, []);
    useEffect(() => {
        if (isCustomerChat) {
            setIsCustomer(isCustomerChat);
        }
    }, [isCustomerChat]);

    useEffect(() => {
        isScrollEnable.current = true;
    }, [scrollupdate]);



    window.onresize = ReSizechat;
    function ReSizechat() {
        if (!restrictResize) {
            var messagesArea = $('.messagesArea-middle');

            var totalHeight = 0;
            messagesArea.children().each(function () {
                totalHeight += $(this).outerHeight(true); // Include margins
            });

            var availableSpace = messagesArea.height() - totalHeight;

            if (availableSpace > 0 && expandMinimizeShow === 0) {
                // console.log("SpaceAvailable");
                if (!isCustomerChat) {
                    setPageIndex(pageIndex + 1);
                }
            }
            else {
                // console.log("not SpaceAvailable");
            }

            const chatListGroupBlock = document.querySelector('.chatList-groupBlock');

            const chatListGroupBlockHeight = chatListGroupBlock?.offsetHeight;
            //console.log('chatList-groupBlock height:', chatListGroupBlockHeight);

            const chatListGroupDivs = chatListGroupBlock?.querySelectorAll('.chatList-group');

            var divHeight = 0;
            if (chatListGroupDivs !== undefined && chatListGroupDivs !== null && chatListGroupDivs !== "") {
                chatListGroupDivs.forEach((div, index) => {
                    divHeight += div.offsetHeight;
                    // console.log(`chatList-group[${index}] height:`, div.offsetHeight);
                });
            }
            //console.log(divHeight);
            var availableGroupBlockHeight = chatListGroupBlockHeight - divHeight
            //console.log(availableGroupBlockHeight);

            if (availableGroupBlockHeight > 0 && paging === true) {
                setTimeout(function () { setConvertionPageIndex(convertionPageIndex + 1); }, 50);
            }
        }
    }

    const ReSizechat1 = async () => {

        // Get all elements with class 'chatList-person' and 'chatList-group_header'
        const personElements = document.querySelectorAll('.chatList-person');
        const headerElements = document.querySelectorAll('.chatList-group_header');

        // Function to calculate total height of elements
        const getTotalHeight = (elements) => {
            let totalHeight = 0;
            elements.forEach(element => {
                totalHeight += element.offsetHeight; // Get the height of the element
            });
            return totalHeight;
        }

        // Calculate total height of 'chatList-person' elements and 'chatList-group_header' elements
        const totalPersonHeight = getTotalHeight(personElements);
        const totalHeaderHeight = getTotalHeight(headerElements);

        // Get the client height of the container
        const containerHeight = document.querySelector('.chatList-groupBlock') !== null ? document.querySelector('.chatList-groupBlock').clientHeight : 0;

        // Check if the total height of 'chatList-person' elements and 'chatList-group_header' elements is equal to container height
        const isAcquired = (totalPersonHeight + totalHeaderHeight) <= containerHeight;
        if (isAcquired && paging === true) {
            if (!isAndroidIOSMobile) {
                setConvertionPageIndex(convertionPageIndex + 1);
            }
        }
    }

    const UpgradNow = () => {
        return ReactDOM.render(
            <Fragment>
                <SuccessAlert
                    alertType={"warning"}
                    strMessage={"Under development."}
                    displaytime={2000}
                />
            </Fragment>,
            document.getElementById("OpenDialog1")
        );
    }

    const OpenTextMessagingPlanPopup = (isEnable2WaySMS) => {
        if (props.initialData.TextMessagingModifyRights.toLowerCase() === "false") {
            utils.ShowAlert("Alert", "You Don't Have Rights!!", "Close");
            return <></>;
        }
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        let isIpadold = /iPad/i.test(navigator.userAgent);
        let isandroid = /VagaroAndroidPhone/i.test(navigator.userAgent);
        let isandroidTab = /VagaroAndroidTablet/i.test(navigator.userAgent);

        if (isAndroidIOSMobile || isIpad || isIpadold || isandroid || isandroidTab) {
            utils.CallBackGivenToMobile(MobileCallBackAction.OpenTextMessaging, "")
        } else {
            var jsonData = JSON.stringify({ isEnable2WaySMS: isEnable2WaySMS }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "OpenTextMessagingPlanPopup","Data": "' + jsonData + '"}', '*');
        }
    }

    function getStatusText(numberStatus) {
        switch (numberStatus) {
            case 1:
            case 2:
                return "Pending Review";
            case 3:
                return "In Review";
            case 4:
                return "Approved";
            case 5:
                return "Rejected";
            default:
                return "Pending Review";
        }
    }

    const LeftControlRenderByKey = () => {
        const onSettingClick = (e) => {
            e.preventDefault();
            navigate('/setting');
            if (dispatch) {
                ThunkAPI(dispatch, ThunkApiEnum.AiSetting);
            }
        }
        return (
            <>
                <>


                    <div ref={leftsideOptionPenalRef}
                        className={leftsideOptionPenalRef.current?.className ? leftsideOptionPenalRef.current.className : "leftside col leftside-open"}
                        id='leftBar'
                    >

                        <div>
                            {/* <a alt="" className="leftside-toggle" onClick={() => ToggleSidebar()} aria-current="page" href={false}>
                                <i className="far fa-chevron-double-left"></i>
                                <i className="far fa-chevron-double-right"></i>

                                <i className="fa-solid fa-chevrons-left"></i>
                                <i className="fa-solid fa-chevrons-right"></i>

                            </a> */}

                            <nav className="nav flex-column leftside-menu">
                                <LeftSelectionDiv />
                            </nav>
                        </div>
                        {/* NumberStatus
                        FromTextMessengerPhone
                        ActiveSMSForMessenger
                        MessengerUsage
                        PlanUsageLimit */}
                        <div>
                            {/* {istextmarketing === true &&
                                <div className='ai-slider-banner'>
                                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/ai-slider-icon.png" width="85" alt="" />
                                    <h3 className='ai-slider-heading'>Try AI for Connect</h3>
                                    <p className='ai-slider-description'>Let AI answer all your messages so you can focus on your business.</p>
                                    <a href={false} className='ai-try-button' onClick={(e) => { e.preventDefault(); navigate('/setting') }}>Try AI</a>
                                </div>
                            } */}
                            <SliderComponent AI UpgradeTextMarketing ReactBusinessId={props.ReactBusinessId} isStenciledForSMS={isStenciledForSMS} isAndroidIOSMobile={isAndroidIOSMobile} initialData={initialData} />
                            <nav className="nav flex-column leftside-menu SettingMenu">
                                {initialData.IsVConnectAIEnableBetaRelease === true && <a className="leftside-menu_options" href={false} onClick={(e) => onSettingClick(e)}>
                                    <i className="fa-regular fa-gear"></i>Settings
                                </a>}
                            </nav>
                            {/* START: Text count */}
                            {istextmarketing === true && props.ReactBusinessId !== 226 &&
                                <div className={`textCounArea${istextmarketing ? "" : " d-none"} ${isStenciledForSMS ? "stenciled" : ""}`}>
                                    <div className="textCounArea-sent">
                                        <span className="textCounArea-sent_count">{textmarketing.usage}</span>Texts Sent
                                    </div>

                                    <ProgressBar now={textmarketing.percentage} variant={textmarketing.variant} />
                                    <div className="textCounArea-limit" onClick={() => OpenTextMessagingPlanPopup(false)}>
                                        <span>{textmarketing.planName} - Limit: {textmarketing.planUsageLimit}</span>
                                    </div>
                                    {textmarketing.activeSMSForMessenger !== null && textmarketing.activeSMSForMessenger === 0 &&
                                        <div className="textCounArea-limit" onClick={() => OpenTextMessagingPlanPopup(true)}>
                                            <span>Enable 2-Way SMS</span>
                                        </div>
                                    }
                                    {textmarketing.usage >= textmarketing.planUsageLimit &&
                                        <div className="mail-sent-per">{textmarketing.overLimitRateInCentsText}</div>
                                    }
                                    <div className="textCounArea-contact">
                                        {fromTextMessengerPhone}
                                    </div>
                                    {textmarketing.numberStatus !== null && textmarketing.numberStatus > 0 &&
                                        <div className="textCounArea-status-msg">(Status : {getStatusText(textmarketing.numberStatus)})</div>
                                    }
                                </div>
                            }
                            {/* END: Text count */}

                            {/* START: Upgrade modal */}
                            {/* {istextmarketing === false && props.ReactBusinessId !== 226 &&
                                <div  className={`upgradeBlock${!istextmarketing ? "" : " d-none"} ${isStenciledForSMS ? "stenciled" : ""}`}>
                                    <div className="msgImg">
                                        <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_in-app_upgrade2.png" alt="" width="76px" />
                                    </div>
                                    <div className="textArea">
                                        <h3 className="textArea-title">Upgrade to SMS Texts</h3>
                                        <p className="textArea-info">Click here and activate your text plan and ensure your customers receive your messages anytime, anywhere.</p>
                                    </div>
                                    <a href={false} className="upgradeBlock-btn" onClick={() => OpenTextMessagingPlanPopup(false)}>Upgrade Now</a>
                                </div>
                            } */}
                            {/* END: Upgrade modal */}
                        </div>
                    </div>
                </>

            </>)
    }

    const onChange = (type) => {
        if ((("Customer" === type && ViewCustomer === false) || ("Employee" === type && ViewEmployee === false)) && !isCustomerChat && initialData.isAi === false) {
            if (isAndroidIOSMobile && (newChat || isNewChat)) {
                CommonMethod.OpenSelect2();
                setnewChatEmployeeSelection(false);
                onChange(LeftControlNames.Employee);
                isNewChatFreeez.current = true;
                setShowFirstLoader(false);
                utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close", true, null, true);
            }
            else {
                setShowFirstLoader(false);
                utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
            }
        }
        else {
            if (type !== isLeftOption) {
                setConvertionPageIndex(1);
                setShowFirstLoader(true);
                setsearchText("");
                setActiveKey(['0', '1']);
                setnewChatData(NewDummyData);
                setNewChat(false);
                props.setlastScroll(0);
                setIsViewChanging(true);
                setMessageConvertions([]);
                setTempChatData([]);
                setChatListSelection(ChatListSelection.All);
                ChatboadSelection(SelectionControlTypeEnum.None, "", "", "", "", false, false, {});
                if (LeftControlNames.Employee === type) {
                    // setUnReadConvertionEmployee([]);
                    GetEmployee(false);
                    // if (tempEmployye.length > 0) {
                    //     setConvertionData(tempEmployye);
                    // }
                    // if (tempEmployye.length > 0) {
                    //     setConversetionChatSelection(tempEmployye[0], true);
                    // }
                }
                //if (LeftControlNames.Customer === type) {
                //setConvertionData(tempCustomer);
                // if (tempCustomer.length > 0) {
                //     setConversetionChatSelection(tempCustomer[0], true);
                // }
                //}
                if (matchMediaScreen_1024.current?.matches) {
                    if (leftsideConversetionPenalRef.current && leftsideConversetionPenalRef.current.className) {
                        leftsideConversetionPenalRef.current.className = "chatListBlock convRight convRight-open";
                    }
                }
                // if (isFromConnectApp) {
                //     utils.CallBackGivenToMobileSelectTab(1)
                // }

            }
            else {
                if (matchMediaScreen_1024.current?.matches) {
                    if (leftsideConversetionPenalRef.current && leftsideConversetionPenalRef.current.className) {
                        leftsideConversetionPenalRef.current.className = "chatListBlock convRight convRight-open";
                    }
                }
            }
            setLeftOption(type)
            sideoption.current = type;
        }
    };

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const { deltaX } = eventData;
            if (deltaX < 0) {
                if (isLeftOption !== LeftControlNames.Customer) {
                    if (isAndroidIOSMobile && (newChat || isNewChat) && ViewCustomer === true) {
                        CommonMethod.RemoveElementbyClass("select2-container")
                    }
                } else {
                    CommonMethod.OpenSelect2();
                }
                setnewChatEmployeeSelection(false);
                onChange(LeftControlNames.Customer);
                isNewChatFreeez.current = true;
            } else {
                if (isLeftOption !== LeftControlNames.Employee) {
                    CommonMethod.RemoveElementbyClass("select2-container")
                } else {
                    CommonMethod.OpenSelect2();
                }
                setnewChatEmployeeSelection(false);
                onChange(LeftControlNames.Employee);
                isNewChatFreeez.current = true;
            }
        },
        trackMouse: true
    });



    const LeftSelectionDiv = () => {

        return (
            <>
                <>
                    <a className={isLeftOption === LeftControlNames.Customer ? "leftside-menu_options active" : "leftside-menu_options"} href={false} onClick={() => { onChange(LeftControlNames.Customer); }}>
                        <i className="fas fa-users"></i>
                        Customers
                        {unReadCountCustomer > 0 && <span className="vCbadge">
                            {unReadCountCustomer}
                        </span>}
                    </a>
                    <a className={isLeftOption === LeftControlNames.Employee ? "leftside-menu_options active" : "leftside-menu_options"} href={false} onClick={() => { onChange(LeftControlNames.Employee); }}>
                        <i className="far fa-id-card"></i>
                        Teams
                        {unReadCountEmployee > 0 && <span className="vCbadge">
                            {unReadCountEmployee}
                        </span>}
                    </a>
                </>
            </>
        )
    }
    const BtnRightsidepenal = () => {
        return (

            <a href={false} className='moRightSlideTrigger' onClick={() => {
                if (rightsidePenal.current && rightsidePenal.current.className.includes("rightSlide-open")) {
                    rightsidePenal.current.className = "rightSlide rightSlide-close";
                } else {
                    rightsidePenal.current.className = "rightSlide rightSlide-open";
                    if (isFromConnectApp && isAndroidIOSMobile === false) {
                        CommonMethod.RightSidePanelSetHeight();
                    }
                    else {
                        utils.SetHeight();
                    }
                }
            }}>
                <i className="fa fa-solid fa-clipboard-list"></i>
            </a>
        )
    }


    useEffect(() => {
        if (typeof window !== "undefined" && isCustomerChat) {
            //UpsertChannel();
            setShowFirstLoader(false);
            setChatAreaShow(true);
            setBusinessedtailPageCustomer(true);
            $("body").addClass('customerChatWindow');
            window.parent.postMessage('{"eventType": "openCustomerChatWindow","Data": ""}', '*');
            if (typeof window !== "undefined" && window.document.getElementById('hdnChatConversionId') !== null && window.document.getElementById('hdnChatConversionId') !== undefined) {
                if (window.document.getElementById('hdnChatConversionId').value !== "") {
                    setConversionidForCustomer(window.document.getElementById('hdnChatConversionId').value);
                } else {
                    GetConvertionIdFromBusinessPage();
                }
            }
        } else {
            if (isFromConnectApp) {
                setTimeout(() => {
                    setConvertionPageIndex(1);
                    setPaging(true);
                    GetConvertion();
                }, 500);
            } else {
                if (isIframeOpened === true) {
                    setConvertionPageIndex(1);
                    setPaging(true);
                    GetConvertion();
                }
            }
        }
        setnewChatEmployeeSelection(false);
    }, [isLeftOption])

    window.UpdateUnReadCount = () => {
        // setIsIframeOpened(true);
        // if (unReadConversionCustomer.filter((x) => x.conversationId === chatSelection.ConversationId).length > 0) {
        //     setUnReadConvertionCustomer(unReadConversionCustomer.filter((x) => x.conversationId !== chatSelection.ConversationId).length === 0 ? [] : unReadConversionCustomer.filter((x) => x.conversationId !== chatSelection.ConversationId))
        //     setUnReadCountCustomer(unReadCountCustomer => unReadCountCustomer - 1);
        // }
        // if (unReadConversionEmployee.filter((x) => x.conversationId === chatSelection.ConversationId).length > 0) {
        //     setUnReadConvertionEmployee(unReadConversionEmployee.filter((x) => x.conversationId !== chatSelection.ConversationId).length === 0 ? [] : unReadConversionEmployee.filter((x) => x.conversationId !== chatSelection.ConversationId))
        //     setUnReadCountEmployee(unReadCountEmployee => unReadCountEmployee - 1);
        // }

        if (convertionData !== null && convertionData !== undefined && convertionData.length === 0) {
            setConvertionPageIndex(1);
            setPaging(true);
            GetConvertion();
        } else {
            setConversetionChatSelection(lastSeletion, true);
        }

        setThemePreferenceFromCookies();
        setTimeout(() => {
            setrestrictResize(false);
            utils.SetHeight();
        }, 1000);
    }

    const setThemePreferenceFromCookies = () => {
        // Get a cookie value using document.cookie in index.js
        function getCookie(name) {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(name + '=')) {
                    return cookie.substring(name.length + 1, cookie.length);
                }
            }
            return '';
        }
        const themePreference = getCookie('ThemePreferance');

        if (themePreference === "1") {
            $('html').attr('data-theme', 'dark');
        }
        else if (themePreference === "2") {
            $('html').attr('data-theme', '');
        }
        else {
            $('html').attr('data-theme', 'light');
        }
    }

    useEffect(() => {
        if (isIframeOpened && expandMinimizeShow === 0 && ShowFirst === true && convertionData.length > 0) {
            if (!newChat) {
                if (isFromConnectApp && (isIpad || isIpadold || isTablet)) {
                    var SelectedChat = convertionData.find(val => val.customerId === chatSelectionID);
                    if (SelectedChat) {
                        setConversetionChatSelection(SelectedChat, true);
                    } else {
                        setConversetionChatSelection(convertionData[0], true);
                    }
                } else {
                    var SelectedChat = convertionData.find(val => val.conversationId === chatSelection.ConversationId);
                    if (SelectedChat) {
                        setConversetionChatSelection(SelectedChat, true);
                    } else {
                        setConversetionChatSelection(convertionData[0], true);
                    }
                }
            }
            else if (newChat && newChatData[0]?.name !== "New Chat") {
                if (sideoption.current === LeftControlNames.Customer) {
                    $("#Customer").append(`<option id="${newChatData[0].customerId}" data-value="${newChatData[0].customerId}" data-firstName="${newChatData[0].firstName}"  data-lastName="${newChatData[0].lastName}">${(newChatData[0].name)}</option>`);
                    setTimeout(() => {
                        $('.select2-container').removeClass("driveFormMselect");
                        $('.select2backdrop ').trigger("click");
                    }, 500);
                }
            }
        }
    }, [expandMinimizeShow])

    useEffect(() => {
        if (isLeftOption === LeftControlNames.Employee && (isNewChat || newChat)) {
            var defultValues = [];
            if (employeeData !== null && newChatSelectedEmployee !== undefined && newChatSelectedEmployee !== "" && employeeData.length > 0 && newChatSelectedEmployee.length > 0) {
                defultValues = employeeData.filter(employee => newChatSelectedEmployee.includes(employee.memberIdHash)).map(employee => employee.memberId);
            }
            if (typeof (allserviceprovider) !== "undefined" && allserviceprovider.length !== 0) {
                setServiceproviderasperMultilocation(
                    utils.EmployeeRoleWiseBinding(
                        allserviceprovider,
                        "",
                        props.ReactBusinessId,
                        true,
                        "ddlEmployee",
                        defultValues,
                        defultValues.length > 0 ? false : true
                    )
                )
            }
            if (newChatSelectedEmployee !== "") {
                $('#ddlEmployee').val(defultValues).trigger('change');
                setnewChatSelectedEmployee("");
                if (isAndroidIOSMobile) {
                    setTimeout(() => { CommonMethod.Actionclick("div-select2-done-selection"); }, 800);
                } else {
                    setTimeout(() => { $('#ddlEmployee').select2("close").blur(); }, 800);
                }
            }
        }
        CommonMethod.HandleInputFocus();
    }, [allserviceprovider, isNewChat, newChat, isLeftOption]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            var ContainDetails = document.getElementById("messagesAreaGroupDetail");
            var PopupShow = document.getElementById("Alert");
            let orientationQuery = window.matchMedia('(orientation: portrait)');
            if (leftsideConversetionPenalRef.current && !leftsideConversetionPenalRef.current.contains(event.target) && (typeof event.target.className === 'string' && !event.target?.className.includes("leftside-menu_options")) && (event.target.parentElement && typeof event.target?.parentElement?.className === 'string' && !event.target?.parentElement?.className.includes("leftside-menu_options") && !event.target?.className.includes("fa fa-solid fa-clipboard-list") && !event.target?.className.includes("leftside-toggle") && !event.target?.parentElement?.className.includes("leftside-toggle"))) {
                if (matchMediaScreen_1024.current?.matches) {
                    if (leftsideConversetionPenalRef.current && leftsideConversetionPenalRef.current.className) {
                        leftsideConversetionPenalRef.current.className = "chatListBlock convRight convRight-open";
                        if (window.OnResizeSelectCustomer !== undefined) {
                            // window.OnResizeSelectCustomer();
                        }
                    }
                }
            }
            // && matchMediaScreen_1024.current?.matches
            if (rightsidePenal.current && !rightsidePenal.current.contains(event.target) && rightsidePenal.current.className.includes("rightSlide-open") && rightsidePenal.current.className.includes("btn-close") && !event.target?.className.includes("fa fa-solid fa-clipboard-list") && !event.target?.className.includes("messagesArea-header_name") && !ContainDetails) {
                if (!$(".modal-dialog").is(":visible")) {
                    rightsidePenal.current.className = "rightSlide rightSlide-close";
                }

            }
            if ((PopupShow !== undefined && PopupShow !== null && PopupShow !== "" && isAndroidIOSMobile && (newChat || isNewChat)) || (PopupShow !== undefined && PopupShow !== null && PopupShow !== "" && orientationQuery.matches && isFromConnectApp && (newChat || isNewChat))) {
                CommonMethod.OpenSelect2();
            }
            $('.fa-chevrons-left').on('click', function () {
                $('.rightSlide').removeClass('rightSlide-open')
                $('.rightSlide').addClass('rightSlide-close')
            });
        };
        // Add a global click event listener
        document.addEventListener('click', handleOutsideClick);
        // Cleanup the event listener when the component is unmounted
        return () => { document.removeEventListener('click', handleOutsideClick); };
    }, []);

    const CheckLoginUserIsRemovedFromChat = (conversationData) => {
        if (!isCustomerChat && (isLeftOption === "Employee" || sideoption.current === "Employee") && conversationData?.isGroupChat && conversationData?.members.length > 0) {
            if (conversationData?.members?.filter((x) => x.memberIdHash === LoginUserIdHash).length > 0 && conversationData?.isBusinessExcluded === false) {
                return conversationData?.members?.filter((x) => x.memberIdHash === LoginUserIdHash)[0].isMemberRemovedFromGroup;
            } else {
                return true;
            }
        }
        return false;
    }

    const RemoveGroupForLogedUser = (RequestData) => {

        let currentConv = convertionData.filter(a => a.conversationId === RequestData.conversationId);
        if (currentConv.length > 0) {

            let curMember = currentConv[0].members.filter(a => a.memberIdHash === RequestData.SentByHash);
            if (curMember.length > 0) {
                curMember[0].isMemberRemovedFromGroup = true;
            }
        }

        //const updatedData = convertionData.map(item => item.conversationId === RequestData.conversationId ? { ...item, members: item.members.map((val) => val.memberIdHash === RequestData.SentByHash ? val.isMemberRemovedFromGroup = true : val), lastMessage: RequestData.message, isLoggedInUserRemovedFromGroup: true } : item);
        // const updatedData = convertionData.map(item => item.conversationId === RequestData.conversationId ? { ...item, isLoggedInUserRemovedFromGroup: true } : item);
        // if (RequestData.conversationId === chatSelection.ConversationId && !isNewChat) {
        //     chatSelection.AllData.isLoggedInUserRemovedFromGroup = true;
        // }

        // if (convertionData.conversationId === chatSelection.ConversationId && !isNewChat) {
        //     chatSelection.AllData.isLoggedInUserRemovedFromGroup = true;
        //     convertionData.isGroupUpdateMessage = true;
        //     setMessageConvertions(prevState => {
        //         const updatedChatData = { ...prevState };
        //         const dateKey = moment().format('YYYY-MM-DD');
        //         if (updatedChatData[dateKey]) {
        //             updatedChatData[dateKey].push(RequestData);
        //         } else {
        //             updatedChatData[dateKey] = [RequestData];
        //         }
        //         return updatedChatData;
        //     });
        //     if (RequestData.isGroupUpdateMessage === true) {
        //         CommonMethod.RenderRightSidePanel();
        //     }
        // }

        if (convertionData.length > 0) {
            setConvertionData(convertionData);
            if (!isAndroidIOSMobile) {
                setConversetionChatSelection(convertionData[0], true);
            } else {
                CommonMethod.Actionclick('messagesAreaheaderback');
                ChatboadSelection(SelectionControlTypeEnum.None, "", "", "", "", false, false, {});
            }
        } else {
            if (searchText.length > 0) {
                setnoRecord(true);
                setnoRecordUnread(true);
            } else {
                setIsNewChat(true);
                setConvertionData([]);
            }
        }
    }

    const GetRequestForBusinessEmployee = (data) => {
        const sendMsgRequestData = {};
        var msgResponse = data;
        sendMsgRequestData.messageId = msgResponse.id;
        sendMsgRequestData.conversationId = msgResponse.conversationId;
        sendMsgRequestData.message = msgResponse.message;
        sendMsgRequestData.MemberIds = msgResponse.MemberIds;
        sendMsgRequestData.status = msgResponse.status;
        sendMsgRequestData.isnote = msgResponse.isNote;
        sendMsgRequestData.attachment = msgResponse.attachment;
        sendMsgRequestData.isMessageFromCustomer = msgResponse.isMessageFromCustomer
        sendMsgRequestData.isDeleted = false;
        sendMsgRequestData.isGroupUpdateMessage = msgResponse.isGroupUpdateMessage;
        sendMsgRequestData.action = "NewChatFromBusinessToCustomerForEmployee"
        sendMsgRequestData.SenderId = customerDataRef.current.userId;
        sendMsgRequestData.fullname = customerDataRef.current.fullName;
        sendMsgRequestData.SentByHash = customerDataRef.current.userIdHash;
        sendMsgRequestData.messageDate = msgResponse.messageDate;
        sendMsgRequestData.senderFirstName = msgResponse.senderFirstName;
        sendMsgRequestData.senderLastName = msgResponse.senderLastName;
        sendMsgRequestData.senderPhoto = msgResponse.senderPhoto;
        sendMsgRequestData.senderCdnURL = msgResponse.senderCdnUrl;
        return sendMsgRequestData;
    }


    const SMSEligibility = async (SendSMSRequest, VcardCallBack, isVcardSend = false) => {
        const accesstoken = await useGenerateToken(dispatch);
        await AsyncAjaxGet(
            VagaroAPIEnum.smseligibility + `?UserId=${CustomerId.current}&SenderId=${LoginUserIDV2}`,
            accesstoken,
            function onSuccess(data) {
                try {
                    if (data != null && data.data != null && data.data.data != null) {
                        checkFirstCall.current = false;
                        if ("Customer" === sideoption.current) {
                            if (chatSelection.AllData.customerIdHash === "7A582EC453F2F16226BE137DC64422F53DF5E4F5" || data.data.data.isEligible) {
                                $(`#sendbutton1`).text("SMS");
                            }
                            else {
                                $(`#sendbutton1`).text("Send");
                            }
                        }
                        else {
                            $(`#sendbutton1`).text("Send");
                        }
                        issmsavail(data.data.data.isEligible);

                        if (data.data.data.isEligible && SendSMSRequest !== undefined && SendSMSRequest !== null && SendSMSRequest !== "") {
                            VcardCallBack(true);
                            TextSMS(SendSMSRequest);
                        }
                        window.onTextMarketingAlerts(data.data.data);
                        StoreTextMarketingData(data.data.data);
                    }
                    else {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "SMS Eligibility",
                            "",
                            data != null && data.data != null ? data.data : ""
                        );
                    }
                } catch (error) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "SMS Eligibility",
                        "",
                        error
                    );
                }
            },
            function OnError(data) {
                utils.appInsightTrack(
                    props.ReactBusinessId,
                    props.ReactUserID,
                    "SMS Eligibility",
                    "",
                    data
                );
            }, false
        );
    }

    const VcardChanges = (RequestData, Editoer, convertion, isGroupNameChange, isNewChatFromMobile) => {
        SendMessage(RequestData, Editoer, convertion, isGroupNameChange, isNewChatFromMobile, true);
    }

    //Api Call Send Message
    const SendMessage = async (RequestData, Editoer, convertion, isGroupNameChange = false, isNewChatFromMobile = false, isVcardSend = false) => {
        const accesstoken = await useGenerateToken(dispatch);
        RequestData.MerchantId = merchantId;
        var Rights = CommonMethod.CheckModifyRights() || false;
        const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
        if (Rights === false && !isCustomerChat && "Customers" === ActiveLeftOption) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            // if (RequestData.IsRemove) {
            //     RemoveGroupForLogedUser(RequestData)
            // }
            if (!isCustomerChat) {
                if (isNewChat || newChat) {
                    if (isLeftOption === LeftControlNames.Customer) {
                        RequestData.chanelId = customerDataRef.current.userIdHash + props.ReactBusinessId;
                        RequestData.receiverIdHash = customerDataRef.current.userIdHash;
                    } else {
                        RequestData.chanelId = props.MerchantChannelId.toString()
                        RequestData.receiverIdHash = groupMemberArrRef.current !== null && groupMemberArrRef.current.length > 1 ? "" : serviceProviderHashId.current;
                        recentChatSelectionRef.current = "";
                    }
                }
                else {
                    RequestData.receiverIdHash = chatSelection.AllData.isGroupChat ? "" : chatSelection.AllData.customerIdHash;
                }
            }

            if (Editoer !== undefined && Editoer !== null && Editoer !== "") {
                Editoer.setData("");
                setTimeout(() => {
                    if (!isAndroidIOSMobile && !isCustomerChat) {
                        Editoer.focus();
                    }
                }, 10);
            }
            if (!isNewChat && !isCustomerChat) {
                let updatedData;
                if (chatSelection.AllData.isGroupChat) {
                    updatedData = convertion.map(d =>
                        d.conversationId === RequestData.conversationId
                            ? { ...d, lastMessageDate: new Date(), lastMessage: RequestData.message, customerIdHash: chatSelection.AllData.isGroupChat ? RequestData.SentByHash : d.customerIdHash, members: chatSelection.AllData.isGroupChat && chatSelection.AllData.members }
                            : d
                    );
                } else {
                    updatedData = convertion.map(d =>
                        d.conversationId === RequestData.conversationId
                            ? { ...d, lastMessageDate: new Date(), lastMessage: RequestData.message, customerIdHash: chatSelection.AllData.isGroupChat ? RequestData.SentByHash : d.customerIdHash, isAIMessage: false }
                            : d
                    );
                }
                const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                if (RequestData.IsRemove) {
                    RemoveGroupForLogedUser(RequestData)
                }
                else {
                    if (isGroupNameChange === false) {
                        setConvertionData(sortedConversations);
                    }
                }
            }
            if (isNewChat && !isCustomerChat) {
                setShowFirstLoader(true);
            }
            //if (!isCustomerChat && (isNewChat || newChat) && isLeftOption === LeftControlNames.Customer) {
            //    UpsertChannel(RequestData.MemberIds, RequestData.receiverIdHash);
            //}
            if (!isCustomerChat) {
                if ((isNewChat || newChat) && groupMemberArrRef.current !== null && groupMemberArrRef.current.length > 1) {
                    var sendGroupMembers = [];
                    var ownerDetailsArray = {
                        "memberId": LoginUserIDV2,
                        "memberIdHash": LoginUserIdHash,
                        "cdnUrl": ReactCdnUrl || "",
                        "photo": ReactProfileImage || "",
                        "firstName": ReactFirstName,
                        "lastName": ReactLastName,
                        "isIncludeAllChat": true
                    }
                    sendGroupMembers = groupMemberArrRef.current.map((x) => {
                        return ({
                            "memberId": x.value,
                            "memberIdHash": x.UserIdHash,
                            "cdnUrl": x?.profile?.split("Original/").length > 1 ? x?.profile?.split("Original/")[0] : "",
                            "photo": x?.profile?.split("Original/").length > 1 ? x?.profile?.split("Original/")[1] : "",
                            "firstName": x.label.split(" ").length > 0 ? x.label.split(" ")[0] : "",
                            "lastName": x.label.split(" ").length > 1 ? x.label.split(" ")[1] : "",
                            "isIncludeAllChat": true
                        })
                    })
                    sendGroupMembers.push(ownerDetailsArray);
                    RequestData.isGroupChat = true;
                    RequestData.members = sendGroupMembers;
                    // sendMsgRequestData.groupAdminIdHash = LoginUserIdHash;

                }
            }
            RequestData.connectionId = connectionId;
            RequestData.DeviceID = navigator.userAgent;
            await AsyncAjaxPost(
                VagaroAPIEnum.SendMessage,
                RequestData,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                            if ((isNewChat || newChat) && !isCustomerChat) {
                                if (sideoption.current === LeftControlNames.Customer) {
                                    data.data.data.firstName = data.data.data.senderFirstName;
                                    data.data.data.lastName = data.data.data.senderLastName;
                                    data.data.data.userId = data.data.data.senderId;
                                    data.data.data.name = data.data.data.fullName;
                                    setConversetionChatSelection(data.data.data, false, undefined, true);
                                }
                                setNewChat(false);
                                if (isNewChatFromMobile) {
                                    GetConvertion(data.data.data.conversationId);
                                } else {
                                    GetConvertion("", expandMinimizeShow !== 0);
                                }
                                setUserFromConversation(true);
                                if (data.data.data.isBlocked === true) {
                                    return ReactDOM.render(
                                        <Fragment>
                                            <SuccessAlert
                                                alertType={"warning"}
                                                strMessage={`You can not send message to blocked User`}
                                                displaytime={2000}
                                            />
                                        </Fragment>,
                                        document.getElementById("OpenDialog1")
                                    );
                                }
                            }
                            else {

                            }
                            const sendMsgRequestData = {};
                            var msgResponse = data.data.data;
                            sendMsgRequestData.messageId = msgResponse.id;
                            sendMsgRequestData.conversationId = msgResponse.conversationId;
                            sendMsgRequestData.SenderId = msgResponse.senderId;
                            sendMsgRequestData.fullname = msgResponse.fullName;
                            sendMsgRequestData.message = msgResponse.message;
                            sendMsgRequestData.MemberIds = RequestData.MemberIds;
                            sendMsgRequestData.status = msgResponse.status;
                            sendMsgRequestData.isnote = msgResponse.isNote;
                            sendMsgRequestData.attachment = msgResponse.attachment;
                            sendMsgRequestData.isMessageFromCustomer = msgResponse.isMessageFromCustomer
                            sendMsgRequestData.isDeleted = false;
                            sendMsgRequestData.isGroupUpdateMessage = RequestData.isGroupUpdateMessage;
                            sendMsgRequestData.SentByHash = msgResponse.senderIdHash;
                            sendMsgRequestData.senderFirstName = msgResponse.senderFirstName;
                            sendMsgRequestData.senderLastName = msgResponse.senderLastName;
                            sendMsgRequestData.senderPhoto = msgResponse.senderPhoto;
                            sendMsgRequestData.senderCdnURL = msgResponse.senderCdnUrl;
                            sendMsgRequestData.messageDate = msgResponse.date;
                            const ActiveLeftOption = isCustomerChat ? "Customers" : $("a.leftside-menu_options.active").text().replace(/[0-9]/g, '');
                            if ("Customers" === ActiveLeftOption && !isCustomerChat && data.data.data?.isCustomerOnline === false) {
                                const updatedData = convertionData.map(d =>
                                    d.conversationId === data.data.data.conversationId
                                        ? { ...d, isCustomerOnline: false, lastMessageDate: new Date(), lastMessage: RequestData.message, attachment: msgResponse.attachment, customerIdHash: chatSelection.AllData.isGroupChat ? RequestData.SentByHash : d.customerIdHash }
                                        : d
                                );
                                if (data.data.data.conversationId === chatSelection.ConversationId) {
                                    chatSelection.AllData.isCustomerOnline = false;
                                }
                                setTempConvertionData(updatedData);
                                setConvertionData(updatedData);
                                window.CheckcustomerOnline(false);
                                if ("Customer" === isLeftOption && UserTextMarketingData.current?.isTextMessagingActivated === true && UserTextMarketingData.current?.isNumberApproved === true && UserTextMarketingData.current?.cell !== "" && UserTextMarketingData.current?.isNotifyBySMS === true) {
                                    $(`#sendbutton1`).text("SMS");
                                }
                            } else {
                                $(`#sendbutton1`).text("Send");
                            }
                            if (!isCustomerChat) {
                                if (chatSelection.AllData.customerIdHash === "7A582EC453F2F16226BE137DC64422F53DF5E4F5" || (sideoption.current === "Customer" && data.data.data?.isCustomerOnline === false && RequestData.isnote === false)) {
                                    var SendSMSRequest = {};
                                    let fileUrls = "";
                                    if (RequestData?.attachments?.length > 0) {
                                        fileUrls = RequestData.attachments.map(attachment => attachment.FileUrl);
                                    }
                                    SendSMSRequest.Message = msgResponse.message + fileUrls;
                                    SendSMSRequest.MessageId = msgResponse.id;
                                    SendSMSRequest.IsMessageFromCustomer = false;
                                    SendSMSRequest.IsCustomerOnline = false;
                                    SendSMSRequest.ReceiverId = (isNewChat || newChat) ? CustomerId.current : chatSelection.AllData.customerId;
                                    SendSMSRequest.LoggedInUserId = LoginUserIDV2;
                                    SendSMSRequest.Attachments = RequestData.attachments;
                                    SendSMSRequest.IsVcard = isVcardSend ? true : false;
                                    if ((isNewChat || newChat)) {
                                        SMSEligibility(SendSMSRequest, VcardCallBack => {
                                            if (VcardCallBack && msgResponse.isVcard !== undefined && msgResponse.isVcard !== null && msgResponse.isVcard !== "" && msgResponse.isVcard === false) {
                                                const VCardMsg = () => {
                                                    return (
                                                        <VCard
                                                            reactBusinessName={ReactBusinessName}
                                                        />
                                                    )
                                                }
                                                const temlMessageVCard = ReactDOMServer.renderToString(VCardMsg());
                                                setTimeout(() => {
                                                    var TempChatResponse = {
                                                        messageId: "",
                                                        tempMessageId: utils.generateCustomMessageID(),
                                                        message: temlMessageVCard,
                                                        messageDate: new Date(),
                                                        sentByHash: LoginUserIdHash,
                                                        fullName: RequestData.fullname,
                                                        isNote: RequestData.isnote,
                                                        isDeleted: RequestData.isDeleted,
                                                        sentBy: RequestData.SenderId,
                                                        isVcard: true
                                                    };

                                                    window.callBackChatConversation(TempChatResponse);
                                                    RequestData.isVcard = true;
                                                    RequestData.conversationId = msgResponse.conversationId;
                                                    RequestData.message = temlMessageVCard;
                                                    RequestData.attachments = [];
                                                    RequestData.MemberIds = null;
                                                    VcardChanges(RequestData, Editoer, convertion, isGroupNameChange, isNewChatFromMobile);
                                                }, 350);
                                            }
                                        }, isVcardSend);
                                    }
                                    else {
                                        if (smsavail.current) {
                                            // SendSMS(SendSMSRequest);
                                            // SMSTextValidate(SendSMSRequest);
                                            setTimeout(() => {
                                                const VCardMsg = () => {
                                                    return (
                                                        <VCard
                                                            reactBusinessName={ReactBusinessName}
                                                        />
                                                    )
                                                }
                                                const temlMessageVCard = ReactDOMServer.renderToString(VCardMsg());
                                                if (msgResponse.isVcard !== undefined && msgResponse.isVcard !== null && msgResponse.isVcard !== "" && msgResponse.isVcard === false) {
                                                    const tempId = utils.generateCustomMessageID();
                                                    var TempChatResponse = {
                                                        messageId: "",
                                                        tempMessageId: tempId,
                                                        message: temlMessageVCard,
                                                        messageDate: new Date(),
                                                        sentByHash: LoginUserIdHash,
                                                        fullName: RequestData.fullname,
                                                        isNote: RequestData.isnote,
                                                        isDeleted: RequestData.isDeleted,
                                                        sentBy: RequestData.SenderId,
                                                        isVcard: true
                                                    };

                                                    window.callBackChatConversation(TempChatResponse);
                                                    RequestData.isVcard = true;
                                                    RequestData.message = temlMessageVCard;
                                                    RequestData.tempMessageId = tempId;
                                                    RequestData.attachments = [];
                                                    SendSMSRequest.IsVcard = true;
                                                    RequestData.MemberIds = null;
                                                    VcardChanges(RequestData, Editoer, convertion, isGroupNameChange, isNewChatFromMobile);
                                                }
                                            }, 350);
                                            TextSMS(SendSMSRequest);
                                        }
                                    }
                                }
                            }
                            setsendResponse(sendMsgRequestData);

                            if (isCustomerChat) {
                                // window.callBackChatConversation(sendMsgRequestData);
                                if (window.setMessageIdIntoMessageObject) {
                                    window.setMessageIdIntoMessageObject(RequestData, sendMsgRequestData)
                                }
                            } else if (!isNewChat && window.setMessageIdIntoMessageObject) {
                                if (RequestData.IsRemove === true && LoginUserIdHash === RequestData.SentByHash) {
                                    //do nothing when member left the chat
                                } else {
                                    window.setMessageIdIntoMessageObject(RequestData, sendMsgRequestData)
                                }
                            }

                            if (!isCustomerChat && (isNewChat || newChat) && groupMemberArrRef.current !== null && groupMemberArrRef.current.length > 1) {
                                var sendGroupMembers = [];
                                var ownerDetailsArray = {
                                    "memberId": LoginUserIDV2,
                                    "memberIdHash": LoginUserIdHash,
                                    "cdnUrl": ReactCdnUrl || "",
                                    "photo": ReactProfileImage || "",
                                    "firstName": ReactFirstName,
                                    "lastName": ReactLastName,
                                    "isIncludeAllChat": true
                                }
                                sendGroupMembers = groupMemberArrRef.current.map((x) => {
                                    return ({
                                        "memberId": x.value,
                                        "memberIdHash": x.UserIdHash,
                                        "cdnUrl": x?.profile?.split("Original/").length > 1 ? x?.profile?.split("Original/")[0] : "",
                                        "photo": x?.profile?.split("Original/").length > 1 ? x?.profile?.split("Original/")[1] : "",
                                        "firstName": x.label.split(" ").length > 0 ? x.label.split(" ")[0] : "",
                                        "lastName": x.label.split(" ").length > 1 ? x.label.split(" ")[1] : "",
                                        "isIncludeAllChat": true
                                    })
                                })
                                sendGroupMembers.push(ownerDetailsArray);
                                sendMsgRequestData.members = sendGroupMembers;

                            }
                            const sendData = data.data.data;
                            if (isCustomerChat) {
                                if (typeof window !== "undefined" &&
                                    window.document.getElementById('hdnChatConversionId') !== null &&
                                    window.document.getElementById('hdnChatConversionId') !== undefined &&
                                    window.document.getElementById('hdnChatConversionId').value === "") {
                                    window.document.getElementById('hdnChatConversionId').value = sendData.conversationId;
                                }
                                setConversionidForCustomer(sendData.conversationId);
                                if (CustomerId.current !== "") {
                                    CustomerId.current = "";
                                    setCustomer("")
                                }
                            } else {
                                const array = [{
                                    "messageId": data.data.data.id,
                                    "message": data.data.data.message,
                                    "sentByHash": data.data.data.senderIdHash,
                                    "sentBy": data.data.data.senderId,
                                    "messageDate": data.data.data.date,
                                    "isNote": data.data.data.isNote,
                                    "isGroupUpdateMessage": true,
                                    "isMessageFromCustomer": data.data.data.isMessageFromCustomer,
                                    "fullName": ReactFirstName + " " + ReactLastName
                                },];
                                if (RequestData.IsRemove === true && LoginUserIdHash === RequestData.SentByHash) {
                                    //do nothing when member left the chat
                                } else {
                                    if (isGroupNameChange) {
                                        mergeChatData(array);
                                    }
                                }
                            }
                        }
                        else {

                            // if (window.callBackChatConversation) {
                            //     window.callBackChatConversation(RequestData);
                            //     if (!isNewChat && !isCustomerChat) {
                            //         const updatedData = convertion.map(d =>
                            //             d.conversationId === RequestData.conversationId
                            //                 ? { ...d, lastMessageDate: new Date(), lastMessage: RequestData.message}
                            //                 : d
                            //         );
                            //         const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                            //         setConvertionData(sortedConversations);
                            //     }
                            // }
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Send Message",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {

                        // if (window.callBackChatConversation) {
                        //     window.callBackChatConversation(RequestData);
                        //     if (!isNewChat && !isCustomerChat) {
                        //         const updatedData = convertion.map(d =>
                        //             d.conversationId === RequestData.conversationId
                        //                 ? { ...d, lastMessageDate: new Date(), lastMessage: RequestData.message }
                        //                 : d
                        //         );
                        //         const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                        //         setConvertionData(sortedConversations);
                        //     }
                        // }
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Send Message",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {

                    // if (window.callBackChatConversation) {
                    //     window.callBackChatConversation(RequestData);
                    //     if (!isNewChat && !isCustomerChat) {
                    //         const updatedData = convertion.map(d =>
                    //             d.conversationId === RequestData.conversationId
                    //                 ? { ...d, lastMessageDate: new Date(), lastMessage: RequestData.message }
                    //                 : d
                    //         );
                    //         const sortedConversations = updatedData.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
                    //         setConvertionData(sortedConversations);
                    //     }
                    // }
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Send Message",
                        RequestData,
                        data
                    );
                },
                true
            );
        }
    }

    const SendSMS = async (RequestData) => {
        // setShowFirstLoader(true);
        setStenciledForSMS(true);
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === isLeftOption && ModifyCustomer === false) || ("Employee" === isLeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            // return ReactDOM.render(
            //     <Fragment>
            //         <SuccessAlert
            //             alertType={"warning"}
            //             strMessage={"You Don't Have Rights"}
            //             displaytime={2000}
            //         />
            //     </Fragment>,
            //     document.getElementById("OpenDialog1")
            // );
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPost(
                VagaroAPIEnum.SendSMS,
                RequestData,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {
                            const textData = data.data.data;
                            var percentage = ((textData.usage * 100) / textData.planUsageLimit).toFixed(2);
                            var variant = "success";
                            if (percentage >= 0 && percentage <= 90) {
                                variant = "success";
                            }
                            else if (percentage > 90 && percentage < 100) {
                                variant = "warning";
                            }
                            else if (percentage >= 100) {
                                variant = "danger";
                            }
                            Addtextmarketing({ ...textData, percentage: percentage, variant: variant });
                            setStenciledForSMS(false);
                        }
                        else {
                            setStenciledForSMS(false);
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Send SMS",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        setStenciledForSMS(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Send SMS",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {
                    setStenciledForSMS(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Send SMS",
                        RequestData,
                        data
                    );
                },
                true
            );
        }
    }
    const SMSStatus = async (RequestData) => {
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === isLeftOption && ModifyCustomer === false) || ("Employee" === isLeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPut(
                VagaroAPIEnum.SMSStatus,
                accesstoken,
                RequestData,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {

                        }
                        else {
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "SMS Status",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "SMS Status",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "SMS Status",
                        RequestData,
                        data
                    );
                },
                true
            );
        }
    }
    const SMSTextValidate = async (RequestData) => {
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === isLeftOption && ModifyCustomer === false) || ("Employee" === isLeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPost(
                VagaroAPIEnum.TextValidate,
                RequestData,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null) {

                        }
                        else {
                            setStenciledForSMS(false);
                            console.log(data)
                        }
                    } catch (error) {
                        setStenciledForSMS(false);
                        console.log(data)
                    }
                },
                function OnError(data) {
                    setStenciledForSMS(false);
                    console.log(data)
                },
            );
        }
    }

    const TextSMS = async (RequestData) => {
        const accesstoken = await useGenerateToken(dispatch);
        if ((("Customer" === isLeftOption && ModifyCustomer === false) || ("Employee" === isLeftOption && ModifyEmployee === false)) && !isCustomerChat) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close");
        }
        else {
            await AsyncAjaxPost(
                VagaroAPIEnum.TextSend,
                RequestData,
                accesstoken,
                function onSuccess(data) {
                    try {
                        if (data != null && data.data != null && data.data.data != null && data.data.data.errorType === 0) {
                            // if (data.data.data.errorType === 0) {
                            //     setStenciledForSMS(true);
                            // }
                            // if (data.data.data.errorType === 1) {
                            //     // setStenciledForSMS(false);
                            //     return ReactDOM.render(
                            //         <Fragment>
                            //             <SuccessAlert
                            //                 alertType={"warning"}
                            //                 strMessage={"Text Messaging Plan is not activated."}
                            //                 displaytime={2000}
                            //             />
                            //         </Fragment>,
                            //         document.getElementById("OpenDialog1")
                            //     );
                            // }
                            // else if (data.data.data.errorType === 2) {
                            //     // setStenciledForSMS(false);
                            //     return ReactDOM.render(
                            //         <Fragment>
                            //             <SuccessAlert
                            //                 alertType={"warning"}
                            //                 strMessage={"Text Messaging Plan Number is not approved yet."}
                            //                 displaytime={2000}
                            //             />
                            //         </Fragment>,
                            //         document.getElementById("OpenDialog1")
                            //     );
                            // }
                            // else if (data.data.data.errorType === 3) {
                            //     // setStenciledForSMS(false);
                            //     return ReactDOM.render(
                            //         <Fragment>
                            //             <SuccessAlert
                            //                 alertType={"warning"}
                            //                 strMessage={"Send SMS For Messenger is not active."}
                            //                 displaytime={2000}
                            //             />
                            //         </Fragment>,
                            //         document.getElementById("OpenDialog1")
                            //     );
                            // }
                            // else if (data.data.data.errorType === 4) {
                            //     // setStenciledForSMS(false);
                            //     return ReactDOM.render(
                            //         <Fragment>
                            //             <SuccessAlert
                            //                 alertType={"warning"}
                            //                 strMessage={"Customer does not have cell number."}
                            //                 displaytime={2000}
                            //             />
                            //         </Fragment>,
                            //         document.getElementById("OpenDialog1")
                            //     );
                            // }
                            // else if (data.data.data.errorType === 5) {
                            //     // setStenciledForSMS(false);
                            //     return ReactDOM.render(
                            //         <Fragment>
                            //             <SuccessAlert
                            //                 alertType={"warning"}
                            //                 strMessage={"Customer notify by SMS is disabled."}
                            //                 displaytime={2000}
                            //             />
                            //         </Fragment>,
                            //         document.getElementById("OpenDialog1")
                            //     );
                            // }
                            // else {
                            const textData = data.data.data;
                            var percentage = ((textData.usage * 100) / textData.planUsageLimit).toFixed(2);
                            var variant = "success";
                            if (percentage >= 0 && percentage <= 90) {
                                variant = "success";
                            }
                            else if (percentage > 90 && percentage < 100) {
                                variant = "warning";
                            }
                            else if (percentage >= 100) {
                                variant = "danger";
                            }
                            Addtextmarketing({ ...textData, percentage: percentage, variant: variant });
                            let statusRequest = {};
                            statusRequest.SMSStatus = 1;
                            statusRequest.IsSMS = true;
                            statusRequest.MessageId = RequestData.MessageId;
                            statusRequest.ActiveSMSForMessenger = textData.activeSMSForMessenger;
                            statusRequest.FromTextMessengerPhone = textData.fromTextMessengerPhone;
                            statusRequest.Usage = textData.usage;
                            statusRequest.MessengerUsage = textData.messengerUsage;
                            statusRequest.PlanUsageLimit = textData.planUsageLimit;
                            statusRequest.OverLimitRateInCentsText = textData.overLimitRateInCentsText;
                            statusRequest.PlanName = textData.planName;
                            statusRequest.NumberStatus = textData.numberStatus;
                            SMSStatus(statusRequest);
                            window.parent.postMessage('{"eventType": "ReloadTextMessagingPlanIframe","Data": ""}', '*');
                            // setStenciledForSMS(false);
                            // }
                        }
                        else {
                            setStenciledForSMS(false);
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Text SMS",
                                RequestData,
                                data != null && data.data != null ? data.data : ""
                            );
                        }
                    } catch (error) {
                        setStenciledForSMS(false);
                        utils.appInsightTrack(
                            props.ReactBusinessId,
                            props.ReactUserID,
                            "Text SMS",
                            RequestData,
                            error
                        );
                    }
                },
                function OnError(data) {
                    setStenciledForSMS(false);
                    utils.appInsightTrack(
                        props.ReactBusinessId,
                        props.ReactUserID,
                        "Text SMS",
                        RequestData,
                        data
                    );
                },
            );
        }
    }
    window.DeleteChannelDetail = async (isCloseFrame, isForNotification = false) => {
        if (connection !== "undefined" && connection !== null && connection.connectionId !== "undefined" && connection.connectionId !== null) {
            if (connection.state === 'Connected') {
                let deleteConnectioncompleted = false;
                try {
                    await AsyncAjaxDelete(
                        VagaroAPIEnum.deletechannel + `&connectionId=${isForNotification && connectionId === "" ? "" : connectionId}&channelId=${isCustomerChat ? LoginUserIdHash + props.ReactBusinessId : props.MerchantChannelId.toString()}&isCustomer=${isCustomerChat}`,
                        "",
                        "",
                        function onSuccess(data) {
                            try {
                                deleteConnectioncompleted = true;
                                if (data != null && data.data != null && data.data.data != null) {
                                }
                                else {
                                    utils.appInsightTrack(
                                        props.ReactBusinessId,
                                        props.ReactUserID,
                                        "Delete Channel",
                                        "",
                                        data != null && data.data != null ? data.data : ""
                                    );
                                    return false;
                                }
                            } catch (error) {
                                deleteConnectioncompleted = true;
                                window.beforeunloadcompleted = true;
                                utils.appInsightTrack(
                                    props.ReactBusinessId,
                                    props.ReactUserID,
                                    "Delete Channel",
                                    "",
                                    error
                                );
                            }
                            //return true;
                        },
                        function OnError(data) {
                            deleteConnectioncompleted = true;
                            window.beforeunloadcompleted = true;
                            utils.appInsightTrack(
                                props.ReactBusinessId,
                                props.ReactUserID,
                                "Delete Channel",
                                "",
                                data
                            );
                        },
                        true
                    );
                    if (isCustomerChat) {
                        while (deleteConnectioncompleted) {
                            connection.stop();
                            if (isCloseFrame) {
                                window.parent.postMessage('{"eventType": "closeChatClient"}', '*');
                                setExpandMinimizeChatBox(1);
                            }
                            break;
                        }
                    }

                } catch (error) {
                    console.log("Error invoking stop connection:", error);
                }
                finally {
                    return;
                }
            }
        }
        if (isCloseFrame) {
            window.parent.postMessage('{"eventType": "closeChatClient"}', '*');
            setExpandMinimizeChatBox(1);
        }
    }
    window.UpsertChannelDetail = () => {
        if (connectionStatus === "Connected" && isCustomerChat) {
            UpsertChannel(LoginUserIDV2, LoginUserIdHash);
        }

    }

    window.PushNotificationConversation = (data) => {
        if (isFromConnectApp) {
            pushNotificationMessageId.current = data.pushNotificationMessageId;
            pushNotificationConversationId.current = data.pushNotificationConversationId;
        }
    }

    const serviceProvider = (serviceprovider, newdata) => {
        let selectednew = newdata;
        let member = [];
        let chatname = [];
        if (selectednew.length > 1) {
            selectednew.push({
                id: LoginUserIDV2,
                label: FullName,
                initial: ReactFirstName !== "" && ReactLastName !== "" ? ReactFirstName.substr(0, 1).toUpperCase() + ReactLastName.substr(0, 1).toUpperCase() : "",
                profile: ReactProfileImage !== "" ? ReactCdnUrl + "Original/" + ReactProfileImage : ""
            });
            selectednew.map(d => {
                member.push({ memberId: d.id, firstName: d.label.split(" ")[0], lastName: d.label.split(" ")[1], cdnUrl: d.profile.split("Original/")[0], photo: d.profile.split("Original/")[1] });
                chatname.push(d.label.split(" ")[0]);
            })
            setnewChatData(prevChatData => [
                {
                    ...prevChatData[0],
                    name: chatname.join(", "),
                    customerId: "",
                    isGroupChat: true,
                    members: member
                },
                ...prevChatData.slice(1)
            ]);
        } else {
            if (newChatData[0]?.name === "New Chat") {
                setnewChatData(prevChatData => [
                    {
                        ...prevChatData[0],
                        name: selectednew.length > 0 ? selectednew[0]?.label : "New Chat",
                        isGroupChat: false,
                        customerId: selectednew.length > 0 ? serviceprovider : "",
                        cdnUrl: selectednew.length > 0 ? selectednew[0]?.profile?.split("Original/")[0] : "",
                        profileImage: selectednew.length > 0 ? selectednew[0]?.profile?.split("Original/")[1] : "",
                        firstName: selectednew[0]?.label.split(" ")[0],
                        lastName: selectednew[0]?.label.split(" ")[1],
                    },
                    ...prevChatData.slice(1)
                ]);
            }
        }
        setserviceprovider({ optionSelected: serviceprovider });
        CustomerId.current = serviceprovider;


        var groupMemberArr = [];
        var allserviceproviderData = allserviceprovider.length > 0 ? allserviceprovider : serviceProviderDataRef.current;
        if (serviceprovider !== "" && serviceprovider.length > 0) {
            if (serviceprovider.split(",").length > 1) {
                var splitedArray = serviceprovider.split(",");
                for (let index = 0; index < splitedArray.length; index++) {
                    const element = splitedArray[index];
                    groupMemberArr = groupMemberArr.concat(allserviceproviderData.filter((x) => x.value === element));
                }
            } else {
                serviceProviderHashId.current = allserviceproviderData?.filter((x) => x.value === serviceprovider)[0]?.UserIdHash || null;
                groupMemberArr = allserviceproviderData?.filter((x) => x.value === serviceprovider) || [];
            }
        }
        groupMemberArrRef.current = groupMemberArr;
        if (!isFromConnectApp) {
            window.SetEditorValueFromLocalStorage();
        }
    }
    const RenderMentiontag = useCallback(
        () => {
            if (chatSelection.Type === LeftControlNames.Employee) {
                setTimeout(() => {
                    for (let index = 0; index < document.getElementsByTagName("vg-user").length; index++) {
                        const element = document.getElementsByTagName("vg-user")[index];
                        const toggle = (isTrue) => {
                            if (isAndroidIOSMobile) {
                                if (isTrue) {
                                    HandleHistory("push", "employeedetailsActive")
                                    AddCommonMethod("MobileCallBackForBack", () => { CommonMethod?.CloseEmployeedetails && CommonMethod.CloseEmployeedetails(); });
                                } else {
                                    if (window.location.href.includes("employeedetailsActive") && window.location.pathname !== '/conversation') {
                                        if (tablet && ipad === false) { return; }
                                        HandleHistory("back");
                                        AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("messagesAreaheaderback"); });
                                    }
                                }
                            }
                        }
                        if (element.dataset.renderd === undefined) {
                            element.dataset.renderd = true;
                            if (element.dataset.id !== "1") {
                                ReactDOM.render(
                                    <>
                                        <MentionPopup {...props} isNewChat={isNewChat} CommonMethod={CommonMethod} AddCommonMethod={AddCommonMethod} convertionData={convertionData} employeeData={employeeData} CountryID={CountryID} chatSelectionID={GetEmployeeIdFromHas(element.dataset.id)} chatSelectionIDhas={element.dataset.id} element={element} onToggle={toggle} setChatAreaShow={setChatAreaShow} setConversetionChatSelection={setConversetionChatSelection} employeeNewChat={EmployeeNewChat} ShowMassage={LoginUserIdHash !== element.dataset.id && (chatSelection.IsFromGroup || isAndroidIOSMobile)} />
                                    </>
                                    , element
                                );
                            }
                        }
                    }
                }, 1000);
            }
            RenderUserProfileDetails()
        },
        [chatSelectionID]
    );

    const RenderUserProfileDetails = useCallback(
        () => {
            if (isFromConnectApp) {
                setTimeout(() => {
                    const elementList = document.querySelectorAll('.recived.messagesBox .avatar-group-img-wrap');
                    for (let index = 0; index < elementList.length; index++) {
                        const element = elementList[index];
                        const toggle = (isTrue) => {
                            if (isAndroidIOSMobile) {
                                if (isTrue) {
                                    HandleHistory("push", "employeedetailsActive")
                                    AddCommonMethod("MobileCallBackForBack", () => { CommonMethod?.CloseEmployeedetails && CommonMethod.CloseEmployeedetails(); });
                                } else {
                                    if (window.location.href.includes("employeedetailsActive") && window.location.pathname !== '/conversation') {
                                        if (tablet && ipad === false) { return; }
                                        HandleHistory("back");
                                        AddCommonMethod("MobileCallBackForBack", () => { CommonMethod.Actionclick("messagesAreaheaderback"); });
                                    }
                                }
                            }
                        }
                        if (element.dataset.renderd === undefined || (element.dataset.renderd && element.dataset.id !== element.dataset.renderd)) {
                            element.dataset.renderd = element.dataset.id;
                            if (chatSelection.Type === LeftControlNames.Employee && chatSelection.IsFromGroup && !isAndroidIOSTabIpad()) {
                                if (element.dataset.id && element.children.length > 0) {
                                    element.addEventListener('click', (e) => {
                                        var Div = document.createElement('div')
                                        Div.dataset.id = element.dataset.id;
                                        unmountComponentAtNode(document.getElementById("OpenDialog1"));
                                        if (!e.target.className.includes("name")) {
                                            return ReactDOM.render(
                                                <>
                                                    <MentionPopup DefultShow={true} {...props} isNewChat={isNewChat} CommonMethod={CommonMethod} AddCommonMethod={AddCommonMethod} convertionData={convertionData} employeeData={employeeData} CountryID={CountryID} chatSelectionID={GetEmployeeIdFromHas(element.dataset.id)} chatSelectionIDhas={element.dataset.id} elementHTML={Div.outerHTML} element={Div} onToggle={toggle} setChatAreaShow={setChatAreaShow} setConversetionChatSelection={setConversetionChatSelection} employeeNewChat={EmployeeNewChat} ShowMassage={LoginUserIdHash !== element.dataset.id && (chatSelection.IsFromGroup || isAndroidIOSMobile)} />
                                                </>
                                                , document.getElementById("OpenDialog1")
                                            );
                                        }
                                    })
                                }
                            } else {
                                if (element.dataset.clickEvent === undefined) {
                                    element.dataset.clickEvent = true;
                                    element.addEventListener('click', (e) => {
                                        var ClickElement = document.getElementById("messagesAreaGroupDetail");
                                        if (ClickElement) {
                                            ClickElement.dataset.clickFromSigleDetail = true;
                                            ClickElement.click();
                                        }
                                        if (isAndroidIOSTabIpad() && !e.target.className.includes("name")) {
                                            CommonMethod.TriggerClick(".addMemberModal .btn-close");
                                            var detail = document.getElementById("employee_GroupDetails");
                                            detail && ReactDOM.render(
                                                <div className='avatarClickDetailPage'>
                                                    <EmployeeDetailPopover  {...props} isFromDetailPage isNewChat={isNewChat} CommonMethod={CommonMethod} AddCommonMethod={AddCommonMethod} convertionData={convertionData} employeeData={employeeData} CountryID={CountryID} chatSelectionID={GetEmployeeIdFromHas(element.dataset.id)} chatSelectionIDhas={element.dataset.id} elementHTML={element.innerHTML} element={element} onToggle={toggle} setChatAreaShow={setChatAreaShow} setConversetionChatSelection={setConversetionChatSelection} employeeNewChat={EmployeeNewChat} ShowMassage={LoginUserIdHash !== element.dataset.id && (chatSelection.IsFromGroup || isAndroidIOSMobile)} />
                                                </div>
                                                , detail
                                            );
                                        }

                                    })
                                }
                            }
                        }
                    }
                }, 100);
            }
        },
        [chatSelectionID]
    );



    useEffect(() => {
        if (isIframeOpened && !isCustomerChat) {
            window.UpdateUnReadCount();
        }
    }, [isIframeOpened])


    const ExpandMinimizeButtonClick = (showValue, ClearChatSelection = true, isPageLoad = false) => {
        if (!isCustomerChat) {
            if (showValue < 0) {
                return;
            } else if (showValue > 3) {
                return;
            } else {
                if (showValue === 1) {
                    CommonMethod.RemoveClassToBody("collapsibleDropDown");
                    CommonMethod.RemoveClassToBody("chatMinimize");

                } else if (showValue === 2) {
                    CommonMethod.AddClassToBody("collapsibleDropDown");
                    CommonMethod.RemoveClassToBody("chatMinimize");
                }
                else if (showValue === 3) {
                    window.DeleteChannelDetail(false);
                    CommonMethod.RemoveClassToBody("collapsibleDropDown");
                    CommonMethod.AddClassToBody("chatMinimize");
                }
                else if (showValue === 0) {
                    CommonMethod.RemoveClassToBody("collapsibleDropDown");
                    CommonMethod.RemoveClassToBody("chatMinimize");
                    if (expandMinimizeShow === 2 && isNewChat && newChatData.length > 0) {
                        setNewChat(true);
                        setnewChatData(newChatData);
                        if (sideoption.current === LeftControlNames.Employee) {
                            setnewChatSelectedEmployee(groupMemberArrRef.current?.map(x => x.UserIdHash))
                        }
                    }
                }
            }
            //console.log("isIframeOpened: " + isIframeOpened);
            if (isPageLoad === true && isIframeOpened) {
                window.UpdateUnReadCount();
            }
        }
        if (showValue === 2 && ClearChatSelection) {
            setLastSelection(chatSelection.AllData);
            ChatboadSelection(SelectionControlTypeEnum.None, "", "", "", "", false, false, {});
            chatSelection.ConversationId = chatSelection.AllData.conversationId !== undefined && chatSelection.AllData.conversationId !== null && chatSelection.AllData.conversationId !== "" ? chatSelection.AllData.conversationId : "";
            if (isFromConnectApp === false && expandMinimizeShow === 0) {
                StoreLocalConversation(0);
            }
            window.DeleteChannelDetail(false);
        }
        $("body").addClass("select2Collapsible");

        if (showValue === 0) {
            if (convertionData.length > 0) {
                if (expandMinimizeShow !== showValue) {
                    setIsNewChat(false);
                }
                $("body").removeClass("select2Collapsible");
                setChatAreaShow(false);
                if (!isFromConnectApp && showValue === 0 && expandMinimizeShow === 2 && chatSelectionID !== undefined && chatSelectionID !== null && chatSelectionID !== "") {
                    CheckTextSMSEligibility(SuccessData => {
                        if (SuccessData !== undefined && SuccessData !== null && SuccessData !== "") {
                            if (chatSelection.AllData.isCustomerOnline === false && SuccessData.isTextMessagingActivated === false) {
                                return;
                            }
                            else {
                                window.onTextMarketingAlerts(SuccessData, false);
                            }
                        }
                    });
                }
            } else {
                if (expandMinimizeShow === 2 && showValue === 0) {
                    setIsNewChat(true);
                }
            }
        }

        setTimeout(function () {
            setExpandMinimizeShow(showValue);
            if (expandMinimizeShow === 2) {
                setPageIndex(pageIndex);
            }
        }, 30);
        if (showValue === 0) {
            CommonMethod.RightSidePanelSetHeight();
        }
        if (showValue === 3) {
            dispatch(ResetTypingUser())
        }
        var jsonData = JSON.stringify({ showValue: showValue, ClearChatSelection: ClearChatSelection, isPageLoad: isPageLoad, isCustomerChat: isCustomerChat, isIframeOpened: isIframeOpened }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
        window.parent.postMessage('{"eventType": "iFrameReset","Data": "' + jsonData + '"}', '*');
    }
    const RedirectToNewCustomer = (custHashId = "") => {
        CommonMethod?.Onclick("btnaddToNewChat");
        CommonMethod?.Onclick(LeftControlNames.NewCustomer);
        CommonMethodRef.current?.SelectCustommerBYHasID(custHashId)
    }
    useEffect(() => {
        GetBadContainWords();
        if (typeof window !== "undefined" && typeof window.parent !== "undefined" && !isCustomerChat) {
            ExpandMinimizeButtonClick(defaultSize, null, true);
            // setIsIframeOpened(true);
        }
        window.gotoChat = (val, size = 2) => {
            // val is Customer HasID Only
            ExpandMinimizeButtonClick(2, false);
            if (window.onCkeditoerResize) window.onCkeditoerResize();
            var isCustomer = $(".leftside-menu_options.active").text() || "";
            if (isCustomer.includes("Customer")) {
                var temparr = convertionDataRef.current.find((has) => has.customerIdHash === val);
                if (temparr && CommonMethod.IsElement(temparr.customerIdHash)) {
                    CommonMethod?.Onclick(temparr.customerIdHash);
                }
                else {
                    RedirectToNewCustomer(val);
                }
            } else {
                RedirectToCustommerbyHasFlagRef.current = val;
                onChange(LeftControlNames.Customer);
            }
        };
    }, []);

    const ExpandMinimizeChatBoxButtonClick = (steps) => {
        if (window.top != window.self && isCustomerChat) {
            if (steps < 0) {
                return;
            } else if (steps > 2) {
                return;
            } else {
                if (steps === 0) {
                    window.DeleteChannelDetail(false);
                    setChatAreaShow(false);
                    setTempChatData([]);
                    setMessageConvertions([]);
                    setPageIndex(1);
                    dispatch(ResetTypingUser())
                } else if (steps === 1) {
                    setChatAreaShow(true);
                    setConnection(null);
                    if (chatSelection.ConversationId !== null && chatSelection.ConversationId !== "") {
                        window.GetConversationById();
                    }
                }
                //Logic for iframe Class add
                window.parent.postMessage('{"eventType": "ExpandMinimizeChatForCustomer","Data": ' + steps + '}', '*');
                setExpandMinimizeChatBox(steps);
            }
        }
    };

    const ChatBoxCloseClick = (isFromMerChant = false) => {
        if (isFromMerChant === false && isCustomerChat) {
            window.DeleteChannelDetail(true);
        }
        else if (isFromMerChant === true) {
            window.DeleteChannelDetail(false);
            setIsIframeOpened(false);
            window.parent.postMessage('{"eventType": "closeChat","Data": ' + isFromMerChant + '}', '*');
            setTimeout(() => {
                setExpandMinimizeShow(0);
                setConvertionData([]);
                setTempConvertionData([]);
                setConvertionPageIndex(1);
                props.setlastScroll(0);
                convertionDataRef.current = [];
                setIsNewChat(false);
                setNewChat(false);
                ChatboadSelection(SelectionControlTypeEnum.None, "", "", "", "", false, false, {});
                setFirstShow(false);
            }, 50);
        }
    }
    useEffect(() => {
        if (isFromConnectApp) {
            window.setMobileData = (res) => {
                let Response = JSON.parse(res);
                if (Response.backToWeb) {
                    if (Response.backToWeb === 9 || Response.backToWeb === "9") {
                        CommonMethod.MobileCallBackForBack();
                    }
                }
                if (Response.callbackIndex === 109) {
                    if (Response.actionResponse && Response.actionResponse.selectedTab) {
                        if (Response.actionResponse.selectedTab === '2') {
                            CommonMethod.Actionclick("btnaddToNewChat")
                        }
                        if (Response.actionResponse.selectedTab === '1') {
                            ChatboadSelection(SelectionControlTypeEnum.None, "", "", "", "", false, false, {});
                            setIsNewChat(false);
                        }
                    }
                }
                // Mobile Callback for Sent PhotoGallary access Request
                if (Response.callbackIndex === MobileCallBackAction.AccessPermission) {
                    if (Response && Response.permission && Response.type) {
                        if (Response.permission === '1') {
                            CommonMethod.AccessPermissionGranted();
                        } else {

                            switch (Response.type) {
                                case '1':
                                    utils.WarningBanner('Permission for accessing Camera denied');
                                    break;
                                case '2':
                                    utils.WarningBanner('Permission for accessing photos denied');
                                    break;
                                default:
                                    utils.WarningBanner('Permission for accessing denied');
                                    break;
                            }
                        }
                        AddCommonMethod("AccessPermissionGranted", () => { console.log("Photo Access Permission Common Method Clear"); })
                    }
                }
            }
        }
        AddCommonMethod("CheckModifyRights", () => {
            if (sideoption.current === LeftControlNames.Customer) {
                if (ModifyCustomer) {
                    return true;
                } else {
                    return false;
                }
            } else if (sideoption.current === LeftControlNames.Employee) {
                if (ModifyEmployee) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        });
        AddCommonMethod("CheckViewRights", () => {
            if (sideoption.current === LeftControlNames.Customer) {
                if (ViewCustomer) {
                    return true;
                } else {
                    return false;
                }
            } else if (sideoption.current === LeftControlNames.Employee) {
                if (ViewEmployee) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        });
        utils.updateEvent("ChatBoxCloseClick", () => {
            ChatBoxCloseClick(true)
        });
    }, [])
    useEffect(() => {

        setnewChatEmployeeSelection(false);
    }, [isNewChat, isFromConnectApp])
    useEffect(() => {
        if (convertionData?.length === 0) {
            setNewChatStart(true);
        } else {
            setNewChatStart(false);
        }
    }, [convertionData])

    useEffect(() => {
        // This Use For Orientation Changes For isIpad/isIpadold/isTablet
        if (isFromConnectApp && (isIpad || isIpadold || isTablet)) {
            let orientationQuery = window.matchMedia('(orientation: portrait)');
            setExpandMinimizeShow(defaultSize);
            if (newChat || document.getElementById("messagesAreaheaderback") !== null) {
                setTimeout(() => {
                    var temNewChat = newChat || isNewChat;
                    if (temNewChat) {
                        CommonMethods.Actionclick("messagesAreaheaderback");
                    }
                    CommonMethod.RemoveElementbyClass("select2-container");
                    setNewChat(false);
                    (temNewChat && !orientationQuery.matches) && CommonMethods.Actionclick("btnaddToNewChat");
                    if (orientationQuery.matches && chatSelectionID !== "" && !temNewChat) {
                        setChatAreaShow(true);
                    }
                    if ((FirstUserData.current !== undefined && FirstUserData.current !== null && FirstUserData.current !== "") || (chatSelectionID !== undefined && chatSelectionID !== null && chatSelectionID !== "")) {
                        if (UserTextMarketingData !== undefined && UserTextMarketingData !== null && UserTextMarketingData !== "") {
                            setTimeout(() => {
                                window.onTextMarketingAlerts(UserTextMarketingData?.current)
                            }, 200);
                        }
                    }
                }, 10);
            }
        }
    }, [defaultSize])

    return (
        <>
            <Routes>
                <Route path="/*" element={
                    <>
                        {!isCustomerChat && <div className={"vConnectMainWrap container-fluid p-0 " + `${chatAreaShow ? "chatActive " : ""}` + `${expandMinimizeShow === 1 ? "rightSideOnly " : ""}` + `${expandMinimizeShow === 2 ? "rightSideOnly smallScreen " : ""}` + `${expandMinimizeShow === 3 ? "rightSideOnly smallScreen minimize " : ""}`}>
                            <div className="row g-0">
                                {expandMinimizeShow !== 0 &&
                                    <div className='rightSideOnly-header'>
                                        <div className='rightSideOnly-header_title'>
                                            Messages
                                        </div>
                                        <div className='rightSideOnly-header_iconWrap'>

                                            <div className="extendButton fullscreen" onClick={() => ExpandMinimizeButtonClick(expandMinimizeShow === 2 ? 0 : expandMinimizeShow - 1)}>
                                                <i className="fa-solid fa-arrow-up-right-and-arrow-down-left-from-center"></i>
                                            </div>

                                            {expandMinimizeShow !== 3 &&
                                                <div className="extendButton arrowDown" onClick={() => ExpandMinimizeButtonClick(expandMinimizeShow === 0 ? 2 : expandMinimizeShow + 1)}>
                                                    <i className="fa-solid fa-chevron-down"></i>
                                                </div>
                                            }

                                            <div className="extendButton close" onClick={() => ChatBoxCloseClick(true)}>
                                                <i className="fa-solid fa-xmark"></i>
                                            </div>

                                        </div>
                                    </div>}
                                {/* add/remove class "collapse" for belowe div on click "leftside-toggle" class */}
                                {/* left Navigation Options */}
                                <LeftControlRenderByKey />
                                {/* Leftnav Selected Options */}
                                {ShowFirst && isFromConnectApp === true && isNewChat === true &&
                                    <div className='rightSideOnly-nav'>
                                        <div className='msg-header' style={{ display: isNewChatStart && !$(".select2backdrop").is(":visible") ? "none" : "flex" }}>
                                            {(!isNewChatStart || isNewChatFreeez.current) ?
                                                <div className='icon-width' id='messagesAreaheaderback' onClick={() => {
                                                    if (convertionData.length > 0) {
                                                        setIsNewChat(false);
                                                        setChatAreaShow(false);
                                                        isNewChatFreeez.current = false;
                                                        CommonMethod.RemoveElementbyClass("ddlEmployee"); $(".select2-dropdown--below").removeClass("width100");
                                                        AddCommonMethod("MobileCallBackForBack", () => { console.log("Mobile CallBack For Back Method Clear") });
                                                        if (isFromConnectApp && isAndroidIOSTabIpad()) {
                                                            setTimeout(() => {
                                                                let orientationQuery = window.matchMedia('(orientation: portrait)');
                                                                orientationQuery.matches && CommonMethod.RemoveElementbyClass("select2-container");
                                                            }, 300);
                                                        }
                                                        if (employeeGroupDetails?.current && employeeGroupDetails?.current !== "") {
                                                            setConversetionChatSelection(employeeGroupDetails.current, true);
                                                            setTimeout(() => {
                                                                CommonMethod.Actionclick("messagesAreaGroupDetail");
                                                            }, 50);
                                                            employeeGroupDetails.current = "";
                                                        }
                                                    } else {
                                                        isNewChatFreeez.current = false;
                                                        CustomerId.current = "";
                                                        setCustomer("")
                                                        setnewChatEmployeeSelection(false);
                                                        setChatAreaShow(false);
                                                        setIsNewChat(true);
                                                        setNewChatStart(true);
                                                        setNewChatRender(val => !val);
                                                        CommonMethod.CloseSelect2();
                                                        CommonMethod.RemoveElementbyClass("ddlEmployee"); $(".select2-dropdown--below").removeClass("width100");
                                                    }
                                                    utils.CallBackGivenToMobileSelectTab(1);
                                                    if (isAndroidIOSMobile) {
                                                        HandleHistory("back");
                                                    }

                                                }}><i className="fa-solid fa-angle-left" aria-hidden="true"></i></div> :
                                                <></>
                                            }
                                            <div id='employeeSelectionDone' style={{ display: "none" }} onClick={() => {
                                                if (newChatData[0] && newChatData[0].name !== 'New Chat') { setnewChatEmployeeSelection(true); utils.CallBackGivenToMobileSelectTab(1); }
                                                else {
                                                    CommonMethod.Actionclick('messagesAreaheaderback');
                                                }
                                            }}
                                            ></div>
                                            {isAndroidIOSMobile === true && ((LeftControlNames.Customer === isLeftOption && customer !== "") || (LeftControlNames.Employee === isLeftOption && newChatEmployeeSelection)) ?
                                                LeftControlNames.Customer === isLeftOption ?
                                                    <div className='user-title'>
                                                        {customerData !== null &&
                                                            <Fragment>
                                                                <Usericon URL={customerData.cdnUrl} Image={customerData.profileImage} FirstName={customerData?.firstName} LastName={customerData?.lastName} />
                                                            </Fragment>
                                                        }
                                                        <div className='user-heading-name'>
                                                            {customerData?.fullName}
                                                        </div>
                                                    </div>
                                                    : newChatData[0] && newChatData[0].isGroupChat === false ?
                                                        <div className='user-title'>
                                                            {newChatData[0] !== null &&
                                                                <Fragment>
                                                                    <Usericon URL={newChatData[0].cdnUrl} Image={newChatData[0].profileImage} FirstName={newChatData[0]?.firstName} LastName={newChatData[0]?.lastName} />
                                                                </Fragment>
                                                            }
                                                            <div className='user-heading-name'>
                                                                {newChatData[0]?.name}
                                                            </div>
                                                        </div>
                                                        : <div className='user-title'>
                                                            <div className="avatar avatar-group avatar-md" data-avatar={newChatData[0].members.length > 4 ? 4 : newChatData[0].members.length}>
                                                                {newChatData[0].members.map((a, index) => {
                                                                    if (index < 3) {
                                                                        return (
                                                                            <Fragment>
                                                                                <div className="avatar-group-img-wrap">
                                                                                    {a.photo !== undefined && a.photo !== null && a.photo !== "" ?
                                                                                        <img alt='avtar' className="avatar-group-member" src={a.cdnUrl + 'Original/' + a.photo} />
                                                                                        : <div> {utils.setLetter(a.firstName || "", a.lastName || "")}</div>
                                                                                    }
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                })}
                                                                {newChatData[0].members.length > 3 &&
                                                                    <div className="avatar-group-img-wrap">
                                                                        <div className="avatar-group-img-wrap-text">
                                                                            +{newChatData[0].members.length - 3}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className='newchat-title'>
                                                                {newChatData[0]?.name}
                                                            </div>
                                                        </div>
                                                : <div className={((LeftControlNames.Customer === isLeftOption && customer !== "") || (LeftControlNames.Employee === isLeftOption && newChatEmployeeSelection) || isNewChatStart) ? "msg-title" : 'msg-title new-chat-text'} onClick={() => CommonMethod.OpenSelect2()}>New Chat</div>
                                            }
                                        </div>
                                        <div style={{ display: "none" }} id='newChatAreaback' onClick={() => { setIsNewChat(false); setChatAreaShow(false); isNewChatFreeez.current = false; CommonMethod.RemoveElementbyClass("ddlEmployee"); CommonMethod.RemoveElementbyClass("ddlSelectCustomer1"); AddCommonMethod("MobileCallBackForBack", () => { console.log("Mobile CallBack For Back Method Clear") }) }} ></div>
                                        {isNewChatStart && isAndroidIOSMobile === true && isNewChatFreeez.current === false ?
                                            <>
                                                {convertionDataCount === 0 ?
                                                    <div className="welcomeScreen-block">
                                                        <div>
                                                            <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_coming_soon_in_app-new.png" alt="" />

                                                            <h2 className="welcomeScreen-block_title">Welcome to Vagaro Connect</h2>
                                                            <p className="welcomeScreen-block_info">Start a new chat with your customers or<br /> teams to let them know you are here!</p>
                                                            <div className="start-chatting-btn">
                                                                <button
                                                                    id='divStartChating'
                                                                    onClick={() => {
                                                                        setNewChatStart(false);
                                                                        utils.CallBackGivenToMobileSelectTab(2)
                                                                        if (isLeftOption === LeftControlNames.Employee) {
                                                                            GetEmployee(false);
                                                                        }
                                                                    }} className="start-chating vcgradientbtn vcprimary-btn">
                                                                    <div className='vcsecondaryInner'>
                                                                        Start Chatting
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {chatSelectionID === "" &&
                                                            <div className="chatarea col">
                                                                <div className="d-flex">
                                                                    <Convertions {...props}
                                                                        leftsideConversetionPenalRef={leftsideConversetionPenalRef}
                                                                        setConversetionChatSelection={setConversetionChatSelection}
                                                                        convertionData={[]}
                                                                        setConvertionData={[]}
                                                                        convertionDataRef={convertionDataRef}
                                                                        isNewChat={isNewChat}
                                                                        setIsNewChat={setIsNewChat}
                                                                        SendMessage={SendMessage}
                                                                        isLeftOption={isLeftOption}
                                                                        ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                                        expandMinimizeShow={expandMinimizeShow}
                                                                        onChange={onChange}
                                                                        upgradeUpCellShow={upgradeUpCellShow}
                                                                        setupgradeUpCellShow={setupgradeUpCellShow}
                                                                        ChatBoxCloseClick={ChatBoxCloseClick}
                                                                        convertionPageIndex={convertionPageIndex}
                                                                        setConvertionPageIndex={setConvertionPageIndex}
                                                                        paging={paging}
                                                                        isConversionDataChanged={isConversionDataChanged}
                                                                        tempEmployye={tempEmployye}
                                                                        settempEmployye={settempEmployye}
                                                                        tempCustomer={tempCustomer}
                                                                        settempCustomer={settempCustomer}
                                                                        setPinCount={setPinCount}
                                                                        pinCount={pinCount}
                                                                        unReadCountCustomer={unReadCountCustomer}
                                                                        setUnReadCountCustomer={setUnReadCountCustomer}
                                                                        unReadCountEmployee={unReadCountEmployee}
                                                                        setUnReadCountEmployee={setUnReadCountEmployee}
                                                                        unReadConversionCustomer={unReadConversionCustomer}
                                                                        setUnReadConvertionCustomer={setUnReadConvertionCustomer}
                                                                        unReadConversionEmployee={unReadConversionEmployee}
                                                                        setUnReadConvertionEmployee={setUnReadConvertionEmployee}
                                                                        searchText={searchText}
                                                                        setsearchText={setsearchText}
                                                                        tempConvertionData={tempConvertionData}
                                                                        setTempConvertionData={setTempConvertionData}
                                                                        setrestrictResize={setrestrictResize}
                                                                        ViewEmployee={ViewEmployee}
                                                                        ViewCustomer={ViewCustomer}
                                                                        newChatData={newChatData}
                                                                        handleAccordionToggle={handleAccordionToggle}
                                                                        activeKey={activeKey}
                                                                        setActiveKey={setActiveKey}
                                                                        // setIsIframeOpened={setIsIframeOpened}
                                                                        isScrollEnable={isScrollEnable}
                                                                        setnewChatData={setnewChatData}
                                                                        CustomerId={CustomerId}
                                                                        setIsViewChanging={setIsViewChanging}
                                                                        ReadUnreadCount={ReadUnreadCount}
                                                                        isAndroidIOSMobile={isAndroidIOSMobile}
                                                                        conClickScroll={conClickScroll}
                                                                        ReSizechat1={ReSizechat1}
                                                                        setCustomer={setCustomer}
                                                                        setCustomerData={setCustomerData}
                                                                        istextmarketing={istextmarketing}
                                                                        CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                                        cancelToken={cancelToken}
                                                                        setCancelToken={setCancelToken}
                                                                        markReadAndUnreadChat={markReadAndUnreadChat}
                                                                        ReactBusinessId={props.ReactBusinessId}
                                                                        noRecord={true}
                                                                        setnoRecord={setnoRecord}
                                                                        noRecordunread={noRecordunread}
                                                                        setnoRecordUnread={setnoRecordUnread}
                                                                        chatListSelection={chatListSelection}
                                                                        setChatListSelection={setChatListSelection}
                                                                        setPaging={setPaging}
                                                                        setscrollupdate={setscrollupdate}
                                                                        setTempChatData={setTempChatData}
                                                                        mergeConvertionData={mergeConvertionData}
                                                                        pushNotificationMessageId={pushNotificationMessageId}
                                                                        MerchantChannelId={MerchantChannelId}
                                                                        setNewChatStart={setNewChatStart}
                                                                        DefultShow={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            isAndroidIOSMobile === true && ((LeftControlNames.Customer === isLeftOption && customer !== "") || (LeftControlNames.Employee === isLeftOption && newChatEmployeeSelection)) ?
                                                <></>
                                                :
                                                <>
                                                    {(isIpad || isIpadold || isTablet) && window.matchMedia('(orientation: portrait)')?.matches === false ?
                                                        <div className="chatarea col emptyStateBlock">
                                                            <div className="d-flex">
                                                                <Convertions {...props}
                                                                    leftsideConversetionPenalRef={leftsideConversetionPenalRef}
                                                                    setConversetionChatSelection={setConversetionChatSelection}
                                                                    convertionData={[]}
                                                                    setConvertionData={setConvertionData}
                                                                    convertionDataRef={convertionDataRef}
                                                                    isNewChat={isNewChat}
                                                                    setIsNewChat={setIsNewChat}
                                                                    SendMessage={SendMessage}
                                                                    isLeftOption={isLeftOption}
                                                                    ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                                    expandMinimizeShow={expandMinimizeShow}
                                                                    onChange={onChange}
                                                                    upgradeUpCellShow={upgradeUpCellShow}
                                                                    setupgradeUpCellShow={setupgradeUpCellShow}
                                                                    ChatBoxCloseClick={ChatBoxCloseClick}
                                                                    convertionPageIndex={convertionPageIndex}
                                                                    setConvertionPageIndex={setConvertionPageIndex}
                                                                    paging={paging}
                                                                    isConversionDataChanged={isConversionDataChanged}
                                                                    tempEmployye={tempEmployye}
                                                                    settempEmployye={settempEmployye}
                                                                    tempCustomer={tempCustomer}
                                                                    settempCustomer={settempCustomer}
                                                                    setPinCount={setPinCount}
                                                                    pinCount={pinCount}
                                                                    unReadCountCustomer={unReadCountCustomer}
                                                                    setUnReadCountCustomer={setUnReadCountCustomer}
                                                                    unReadCountEmployee={unReadCountEmployee}
                                                                    setUnReadCountEmployee={setUnReadCountEmployee}
                                                                    unReadConversionCustomer={unReadConversionCustomer}
                                                                    setUnReadConvertionCustomer={setUnReadConvertionCustomer}
                                                                    unReadConversionEmployee={unReadConversionEmployee}
                                                                    setUnReadConvertionEmployee={setUnReadConvertionEmployee}
                                                                    searchText={searchText}
                                                                    setsearchText={setsearchText}
                                                                    tempConvertionData={tempConvertionData}
                                                                    setTempConvertionData={setTempConvertionData}
                                                                    setrestrictResize={setrestrictResize}
                                                                    ViewEmployee={ViewEmployee}
                                                                    ViewCustomer={ViewCustomer}
                                                                    newChatData={newChatData}
                                                                    handleAccordionToggle={handleAccordionToggle}
                                                                    activeKey={activeKey}
                                                                    setActiveKey={setActiveKey}
                                                                    // setIsIframeOpened={setIsIframeOpened}
                                                                    isScrollEnable={isScrollEnable}
                                                                    setnewChatData={setnewChatData}
                                                                    CustomerId={CustomerId}
                                                                    setIsViewChanging={setIsViewChanging}
                                                                    ReadUnreadCount={ReadUnreadCount}
                                                                    isAndroidIOSMobile={isAndroidIOSMobile}
                                                                    conClickScroll={conClickScroll}
                                                                    ReSizechat1={ReSizechat1}
                                                                    setCustomer={setCustomer}
                                                                    setCustomerData={setCustomerData}
                                                                    istextmarketing={istextmarketing}
                                                                    CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                                    cancelToken={cancelToken}
                                                                    setCancelToken={setCancelToken}
                                                                    markReadAndUnreadChat={markReadAndUnreadChat}
                                                                    ReactBusinessId={props.ReactBusinessId}
                                                                    noRecord={convertionDataCount === 0 ? false : true}
                                                                    setnoRecord={setnoRecord}
                                                                    noRecordunread={noRecordunread}
                                                                    setnoRecordUnread={setnoRecordUnread}
                                                                    chatListSelection={chatListSelection}
                                                                    setChatListSelection={setChatListSelection}
                                                                    setPaging={setPaging}
                                                                    setscrollupdate={setscrollupdate}
                                                                    setTempChatData={setTempChatData}
                                                                    mergeConvertionData={mergeConvertionData}
                                                                    pushNotificationMessageId={pushNotificationMessageId}
                                                                    MerchantChannelId={MerchantChannelId}
                                                                    DefultShow={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <nav className='rightSideOnly-navBlock' {...handlers}>
                                                            <a id='navBlock_option_Customer' className={`rightSideOnly-navBlock_option ${isLeftOption === LeftControlNames.Customer && 'active'}`} href={false} onClick={() => {
                                                                // CommonMethod.Actionclick("Customer");
                                                                if (("Customer" === LeftControlNames.Customer && ViewCustomer === false)) {
                                                                    if ((newChat || isNewChat)) {
                                                                        onChange(LeftControlNames.Customer);
                                                                        return;
                                                                    }
                                                                }

                                                                if (isLeftOption !== LeftControlNames.Customer) {
                                                                    CommonMethod.RemoveElementbyClass("select2-container")
                                                                } else {
                                                                    CommonMethod.OpenSelect2();
                                                                }
                                                                setnewChatEmployeeSelection(false);
                                                                onChange(LeftControlNames.Customer);
                                                                isNewChatFreeez.current = true;
                                                            }}>Customers {unReadCountCustomer > 0 && <span className="vCbadge"> {unReadCountCustomer} </span>}</a>
                                                            <a id='navBlock_option_Employee' className={`rightSideOnly-navBlock_option ${isLeftOption === LeftControlNames.Employee && 'active'}`} href={false} onClick={() => {
                                                                // CommonMethod.Actionclick("ddlEmployee");
                                                                if (isLeftOption !== LeftControlNames.Employee) {
                                                                    CommonMethod.RemoveElementbyClass("select2-container")
                                                                } else {
                                                                    CommonMethod.OpenSelect2();
                                                                }
                                                                setnewChatEmployeeSelection(false);
                                                                onChange(LeftControlNames.Employee);
                                                                isNewChatFreeez.current = true;
                                                            }}>Teams {unReadCountEmployee > 0 && <span className="vCbadge"> {unReadCountEmployee} </span>} </a>
                                                        </nav>
                                                    }
                                                </>
                                        }
                                    </div>
                                }
                                {isNewChat && ShowFirst && isLeftOption === LeftControlNames.Employee &&
                                    <>
                                        {isNewChatStart && isAndroidIOSMobile === true && isNewChatFreeez.current === false ? <></> :
                                            <>
                                                {convertionDataCount !== 0 && expandMinimizeShow !== 2 && isFromConnectApp === false &&
                                                    <div className="chatarea col emptyStateBlock">
                                                        <div className="d-flex">
                                                            <Convertions {...props}
                                                                leftsideConversetionPenalRef={leftsideConversetionPenalRef}
                                                                setConversetionChatSelection={setConversetionChatSelection}
                                                                convertionData={[]}
                                                                setConvertionData={setConvertionData}
                                                                convertionDataRef={convertionDataRef}
                                                                isNewChat={isNewChat}
                                                                setIsNewChat={setIsNewChat}
                                                                SendMessage={SendMessage}
                                                                isLeftOption={isLeftOption}
                                                                ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                                expandMinimizeShow={expandMinimizeShow}
                                                                onChange={onChange}
                                                                upgradeUpCellShow={upgradeUpCellShow}
                                                                setupgradeUpCellShow={setupgradeUpCellShow}
                                                                ChatBoxCloseClick={ChatBoxCloseClick}
                                                                convertionPageIndex={convertionPageIndex}
                                                                setConvertionPageIndex={setConvertionPageIndex}
                                                                paging={paging}
                                                                isConversionDataChanged={isConversionDataChanged}
                                                                tempEmployye={tempEmployye}
                                                                settempEmployye={settempEmployye}
                                                                tempCustomer={tempCustomer}
                                                                settempCustomer={settempCustomer}
                                                                setPinCount={setPinCount}
                                                                pinCount={pinCount}
                                                                unReadCountCustomer={unReadCountCustomer}
                                                                setUnReadCountCustomer={setUnReadCountCustomer}
                                                                unReadCountEmployee={unReadCountEmployee}
                                                                setUnReadCountEmployee={setUnReadCountEmployee}
                                                                unReadConversionCustomer={unReadConversionCustomer}
                                                                setUnReadConvertionCustomer={setUnReadConvertionCustomer}
                                                                unReadConversionEmployee={unReadConversionEmployee}
                                                                setUnReadConvertionEmployee={setUnReadConvertionEmployee}
                                                                searchText={searchText}
                                                                setsearchText={setsearchText}
                                                                tempConvertionData={tempConvertionData}
                                                                setTempConvertionData={setTempConvertionData}
                                                                setrestrictResize={setrestrictResize}
                                                                ViewEmployee={ViewEmployee}
                                                                ViewCustomer={ViewCustomer}
                                                                newChatData={newChatData}
                                                                handleAccordionToggle={handleAccordionToggle}
                                                                activeKey={activeKey}
                                                                setActiveKey={setActiveKey}
                                                                // setIsIframeOpened={setIsIframeOpened}
                                                                isScrollEnable={isScrollEnable}
                                                                setnewChatData={setnewChatData}
                                                                CustomerId={CustomerId}
                                                                setIsViewChanging={setIsViewChanging}
                                                                ReadUnreadCount={ReadUnreadCount}
                                                                isAndroidIOSMobile={isAndroidIOSMobile}
                                                                conClickScroll={conClickScroll}
                                                                ReSizechat1={ReSizechat1}
                                                                setCustomer={setCustomer}
                                                                setCustomerData={setCustomerData}
                                                                istextmarketing={istextmarketing}
                                                                CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                                cancelToken={cancelToken}
                                                                setCancelToken={setCancelToken}
                                                                markReadAndUnreadChat={markReadAndUnreadChat}
                                                                ReactBusinessId={props.ReactBusinessId}
                                                                noRecord={true}
                                                                setnoRecord={setnoRecord}
                                                                noRecordunread={noRecordunread}
                                                                setnoRecordUnread={setnoRecordUnread}
                                                                chatListSelection={chatListSelection}
                                                                setChatListSelection={setChatListSelection}
                                                                setPaging={setPaging}
                                                                setscrollupdate={setscrollupdate}
                                                                setTempChatData={setTempChatData}
                                                                mergeConvertionData={mergeConvertionData}
                                                                pushNotificationMessageId={pushNotificationMessageId}
                                                                MerchantChannelId={MerchantChannelId}
                                                                DefultShow={true}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        <div className={(!isNewChatStart && newChatEmployeeSelection) ? "welcomeScreen col welcomeScreen-Blank" : "welcomeScreen col"}>
                                            {isNewChatStart && isAndroidIOSMobile === true && isNewChatFreeez.current === false ?
                                                <></>
                                                :
                                                <>
                                                    <div className='employeeSelectWrap'>
                                                        {expandMinimizeShow !== 0 && isAndroidIOSMobile === false && <a href={false} id='messagesAreaheaderback' className='messagesArea-header_back' onClick={() => { setIsNewChat(false); StoreLocalConversation(0); setnewChatSelectedEmployee(""); setnewChatData(NewDummyData) }}>
                                                            <i className="fa-solid fa-angle-left"></i>
                                                        </a>}
                                                        {/* {expandMinimizeShow === 2 && isAndroidIOSMobile === false && convertionData.length === 0 && <a href={false} id='messagesAreaheaderback' className='messagesArea-header_back' onClick={() => {
                                            onChange(isLeftOption === LeftControlNames.Customer ? LeftControlNames.Employee : LeftControlNames.Customer);
                                        }}>
                                            <i className="fa-solid fa-angle-left"></i>
                                        </a>} */}
                                                        <MultiGroupSelectEmployee
                                                            className=""
                                                            classNamePrefix="vg-select"
                                                            aria-label="Default select example"
                                                            options={ServiceproviderasperMultilocation}
                                                            onChange={(serviceprovider, selectednew) => {
                                                                serviceProvider(serviceprovider, selectednew);
                                                            }}
                                                            onChangeSpName={(SpName) => {
                                                                setserviceproviderName(SpName);
                                                            }}
                                                            // value={props.providervalue}
                                                            isMulti
                                                            ControlID="ddlEmployee"
                                                            searchInputPlaceholder={"Search"}
                                                            LoginUserIdHash={LoginUserIdHash}
                                                            placeholder="Select an teammates"
                                                            isandroidiospro={isAndroidIOSMobile}
                                                            isFromConnectApp={isFromConnectApp}
                                                            expandMinimizeShow={expandMinimizeShow}
                                                            isNewChat={isNewChat}
                                                        />
                                                    </div>

                                                    {convertionDataCount === 0 && isAndroidIOSMobile === false &&
                                                        <div className="welcomeScreen-block">
                                                            <div>
                                                                <img className='app-hide' src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_coming_soon_in_app-new.png" alt="" />
                                                                <img className='aap-show' src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_coming_soon_in_app-new.png" alt="" />


                                                                <h2 className="welcomeScreen-block_title">Welcome to Vagaro Connect</h2>
                                                                <p className="welcomeScreen-block_info">Start a new chat with your customers or<br /> teams to let them know you are here!</p>
                                                                <div className="start-chatting-btn">
                                                                    <button className="start-chating vcgradientbtn vcsecondary-btn">Start Chatting</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {isAndroidIOSMobile === true ?
                                                        LeftControlNames.Employee === isLeftOption && CustomerId.current !== "" && newChatEmployeeSelection ?
                                                            <div className={isAndroidIOSTabIpad() ? 'typeChatWrap' : `typeChatWrap${expandMinimizeShow !== 0 ? ' collapsibleChat' : ""}`}>
                                                                <EditoerInput {...props}
                                                                    customer={CustomerId} SendMessage={SendMessage}
                                                                    isNewChat={isNewChat} convertionData={convertionData}
                                                                    LeftOption={isLeftOption} customerData={customerDataRef.current}
                                                                    SendSMS={SendSMS} middleArea={middleArea} expandMinimizeShow={expandMinimizeShow}
                                                                    ManageCustomerViewRights={ManageCustomerViewRights}
                                                                    ManageCustomerModifyRights={ManageCustomerModifyRights} groupMemberArrRef={groupMemberArrRef.current}
                                                                />
                                                            </div>
                                                            : <></>
                                                        : <div className={isAndroidIOSTabIpad() ? 'typeChatWrap' : `typeChatWrap${expandMinimizeShow !== 0 ? ' collapsibleChat' : ""}`}>
                                                            <EditoerInput {...props}
                                                                customer={CustomerId} SendMessage={SendMessage}
                                                                isNewChat={isNewChat} convertionData={convertionData}
                                                                LeftOption={isLeftOption} customerData={customerDataRef.current}
                                                                SendSMS={SendSMS} middleArea={middleArea} expandMinimizeShow={expandMinimizeShow}
                                                                ManageCustomerViewRights={ManageCustomerViewRights}
                                                                ManageCustomerModifyRights={ManageCustomerModifyRights}
                                                                groupMemberArrRef={groupMemberArrRef.current}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </>

                                }
                                {isNewChat && ShowFirst && isLeftOption === LeftControlNames.Customer && ViewCustomer &&
                                    <>
                                        {convertionDataCount !== 0 && expandMinimizeShow !== 2 && isFromConnectApp === false &&
                                            <div className="chatarea col emptyStateBlock">
                                                <div className="d-flex">
                                                    <Convertions {...props}
                                                        leftsideConversetionPenalRef={leftsideConversetionPenalRef}
                                                        setConversetionChatSelection={setConversetionChatSelection}
                                                        convertionData={[]}
                                                        setConvertionData={setConvertionData}
                                                        convertionDataRef={convertionDataRef}
                                                        isNewChat={isNewChat}
                                                        setIsNewChat={setIsNewChat}
                                                        SendMessage={SendMessage}
                                                        isLeftOption={isLeftOption}
                                                        ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                        expandMinimizeShow={expandMinimizeShow}
                                                        onChange={onChange}
                                                        upgradeUpCellShow={upgradeUpCellShow}
                                                        setupgradeUpCellShow={setupgradeUpCellShow}
                                                        ChatBoxCloseClick={ChatBoxCloseClick}
                                                        convertionPageIndex={convertionPageIndex}
                                                        setConvertionPageIndex={setConvertionPageIndex}
                                                        paging={paging}
                                                        isConversionDataChanged={isConversionDataChanged}
                                                        tempEmployye={tempEmployye}
                                                        settempEmployye={settempEmployye}
                                                        tempCustomer={tempCustomer}
                                                        settempCustomer={settempCustomer}
                                                        setPinCount={setPinCount}
                                                        pinCount={pinCount}
                                                        unReadCountCustomer={unReadCountCustomer}
                                                        setUnReadCountCustomer={setUnReadCountCustomer}
                                                        unReadCountEmployee={unReadCountEmployee}
                                                        setUnReadCountEmployee={setUnReadCountEmployee}
                                                        unReadConversionCustomer={unReadConversionCustomer}
                                                        setUnReadConvertionCustomer={setUnReadConvertionCustomer}
                                                        unReadConversionEmployee={unReadConversionEmployee}
                                                        setUnReadConvertionEmployee={setUnReadConvertionEmployee}
                                                        searchText={searchText}
                                                        setsearchText={setsearchText}
                                                        tempConvertionData={tempConvertionData}
                                                        setTempConvertionData={setTempConvertionData}
                                                        setrestrictResize={setrestrictResize}
                                                        ViewEmployee={ViewEmployee}
                                                        ViewCustomer={ViewCustomer}
                                                        newChatData={newChatData}
                                                        handleAccordionToggle={handleAccordionToggle}
                                                        activeKey={activeKey}
                                                        setActiveKey={setActiveKey}
                                                        // setIsIframeOpened={setIsIframeOpened}
                                                        isScrollEnable={isScrollEnable}
                                                        setnewChatData={setnewChatData}
                                                        CustomerId={CustomerId}
                                                        setIsViewChanging={setIsViewChanging}
                                                        ReadUnreadCount={ReadUnreadCount}
                                                        isAndroidIOSMobile={isAndroidIOSMobile}
                                                        conClickScroll={conClickScroll}
                                                        ReSizechat1={ReSizechat1}
                                                        setCustomer={setCustomer}
                                                        setCustomerData={setCustomerData}
                                                        istextmarketing={istextmarketing}
                                                        CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                        cancelToken={cancelToken}
                                                        setCancelToken={setCancelToken}
                                                        markReadAndUnreadChat={markReadAndUnreadChat}
                                                        ReactBusinessId={props.ReactBusinessId}
                                                        noRecord={true}
                                                        setnoRecord={setnoRecord}
                                                        noRecordunread={noRecordunread}
                                                        setnoRecordUnread={setnoRecordUnread}
                                                        chatListSelection={chatListSelection}
                                                        setChatListSelection={setChatListSelection}
                                                        setPaging={setPaging}
                                                        setscrollupdate={setscrollupdate}
                                                        setTempChatData={setTempChatData}
                                                        mergeConvertionData={mergeConvertionData}
                                                        pushNotificationMessageId={pushNotificationMessageId}
                                                        MerchantChannelId={MerchantChannelId}
                                                        DefultShow={true}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className={(!isNewChatStart && CustomerId.current !== "") ? "welcomeScreen col welcomeScreen-Blank" : "welcomeScreen col"}>
                                            {isNewChatStart && isAndroidIOSMobile === true && isNewChatFreeez.current === false ?
                                                <></>
                                                :
                                                <>
                                                    <div className='employeeSelectWrap'>
                                                        {expandMinimizeShow !== 0 && isAndroidIOSMobile === false && <a href={false} id='messagesAreaheaderback' className='messagesArea-header_back' onClick={() => {
                                                            setIsNewChat(false); $(".select2-dropdown--below").removeClass("width100");
                                                            $(".select2-results").css("visibility", "hidden"); $('.select2backdrop ').hide().remove();
                                                            $('.select2-container').removeClass("driveFormMselect");
                                                            setCustomerData(null);
                                                            customerDataRef.current = "";
                                                            setnewChatData(NewDummyData)
                                                            StoreLocalConversation(0);
                                                        }}>
                                                            <i className="fa-solid fa-angle-left"></i>
                                                        </a>}
                                                        {/* {expandMinimizeShow === 2 && isFromConnectApp === false &&  convertionData.length === 0 &&  <a href={false} id='messagesAreaheaderback' className='messagesArea-header_back' onClick={() => {
                                            onChange(isLeftOption === LeftControlNames.Customer ? LeftControlNames.Employee : LeftControlNames.Customer);
                                        }}>
                                            <i className="fa-solid fa-angle-left"></i>
                                        </a>} */}
                                                        <MerchantsSingleSelecttwo
                                                            ControlID="Customer"
                                                            placeholder="Select a customer"
                                                            MerchantID={merchantId}
                                                            OnChangeCustomer={OnChangeCustomer}
                                                            MultiCustomersId={"ddlSelectCustomer1"}
                                                            ManageCustomerViewRights={ManageCustomerViewRights}
                                                            isandroidiospro={isAndroidIOSMobile}
                                                            expandMinimizeShow={expandMinimizeShow}
                                                            isNewChat={isNewChat}
                                                        />
                                                    </div>
                                                    {convertionDataCount === 0 && isAndroidIOSMobile === false &&
                                                        <div className="welcomeScreen-block">
                                                            <div>
                                                                <img className='app-hide' src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_coming_soon_in_app-new.png" alt="" />
                                                                <img className='aap-show' src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/connect_coming_soon_in_app-new.png" alt="" />
                                                                <h2 className="welcomeScreen-block_title">Welcome to Vagaro Connect</h2>
                                                                <p className="welcomeScreen-block_info">Start a new chat with your customers or<br /> teams to let them know you are here!</p>
                                                                <div className="start-chatting-btn">
                                                                    <button className="start-chating vcgradientbtn vcsecondary-btn">Start Chatting</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {isAndroidIOSMobile === true ?
                                                        CustomerId.current !== "" ?
                                                            <div className={isAndroidIOSTabIpad() ? 'typeChatWrap' : `typeChatWrap${expandMinimizeShow !== 0 ? ' collapsibleChat' : ""}`}>
                                                                <EditoerInput {...props} customer={CustomerId}
                                                                    SendMessage={SendMessage} isNewChat={isNewChat}
                                                                    convertionData={convertionData} LeftOption={isLeftOption}
                                                                    customerData={customerDataRef.current} SendSMS={SendSMS}
                                                                    middleArea={middleArea} expandMinimizeShow={expandMinimizeShow} ReactBusinessId={props.ReactBusinessId}
                                                                    ManageCustomerViewRights={ManageCustomerViewRights}
                                                                    ManageCustomerModifyRights={ManageCustomerModifyRights}
                                                                    groupMemberArrRef={groupMemberArrRef.current}
                                                                    MerchantChannelId={MerchantChannelId}
                                                                />
                                                            </div> : <></>
                                                        :
                                                        <div className={isAndroidIOSTabIpad() ? 'typeChatWrap' : `typeChatWrap${expandMinimizeShow !== 0 ? ' collapsibleChat' : ""}`}>
                                                            <EditoerInput {...props} customer={CustomerId}
                                                                SendMessage={SendMessage} isNewChat={isNewChat}
                                                                convertionData={convertionData} LeftOption={isLeftOption}
                                                                customerData={customerDataRef.current} SendSMS={SendSMS}
                                                                middleArea={middleArea} expandMinimizeShow={expandMinimizeShow} ReactBusinessId={props.ReactBusinessId} ManageCustomerViewRights={ManageCustomerViewRights}
                                                                ManageCustomerModifyRights={ManageCustomerModifyRights}
                                                                groupMemberArrRef={groupMemberArrRef.current}
                                                                MerchantChannelId={MerchantChannelId}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                                {expandMinimizeShow === 2 && convertionDataCount === 0 && convertionData.length === 0 && isFromConnectApp === false &&
                                    <div className="chatarea col emptyStateBlock" style={{ minWidth: "100%" }}>
                                        <div className="d-flex" >
                                            <Convertions {...props}
                                                leftsideConversetionPenalRef={leftsideConversetionPenalRef}
                                                setConversetionChatSelection={setConversetionChatSelection}
                                                convertionData={[]}
                                                setConvertionData={setConvertionData}
                                                convertionDataRef={convertionDataRef}
                                                isNewChat={isNewChat}
                                                setIsNewChat={setIsNewChat}
                                                SendMessage={SendMessage}
                                                isLeftOption={isLeftOption}
                                                ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                expandMinimizeShow={expandMinimizeShow}
                                                onChange={onChange}
                                                upgradeUpCellShow={upgradeUpCellShow}
                                                setupgradeUpCellShow={setupgradeUpCellShow}
                                                ChatBoxCloseClick={ChatBoxCloseClick}
                                                convertionPageIndex={convertionPageIndex}
                                                setConvertionPageIndex={setConvertionPageIndex}
                                                paging={paging}
                                                isConversionDataChanged={isConversionDataChanged}
                                                tempEmployye={tempEmployye}
                                                settempEmployye={settempEmployye}
                                                tempCustomer={tempCustomer}
                                                settempCustomer={settempCustomer}
                                                setPinCount={setPinCount}
                                                pinCount={pinCount}
                                                unReadCountCustomer={unReadCountCustomer}
                                                setUnReadCountCustomer={setUnReadCountCustomer}
                                                unReadCountEmployee={unReadCountEmployee}
                                                setUnReadCountEmployee={setUnReadCountEmployee}
                                                unReadConversionCustomer={unReadConversionCustomer}
                                                setUnReadConvertionCustomer={setUnReadConvertionCustomer}
                                                unReadConversionEmployee={unReadConversionEmployee}
                                                setUnReadConvertionEmployee={setUnReadConvertionEmployee}
                                                searchText={searchText}
                                                setsearchText={setsearchText}
                                                tempConvertionData={tempConvertionData}
                                                setTempConvertionData={setTempConvertionData}
                                                setrestrictResize={setrestrictResize}
                                                ViewEmployee={ViewEmployee}
                                                ViewCustomer={ViewCustomer}
                                                newChatData={newChatData}
                                                handleAccordionToggle={handleAccordionToggle}
                                                activeKey={activeKey}
                                                setActiveKey={setActiveKey}
                                                // setIsIframeOpened={setIsIframeOpened}
                                                isScrollEnable={isScrollEnable}
                                                setnewChatData={setnewChatData}
                                                CustomerId={CustomerId}
                                                setIsViewChanging={setIsViewChanging}
                                                ReadUnreadCount={ReadUnreadCount}
                                                isAndroidIOSMobile={isAndroidIOSMobile}
                                                conClickScroll={conClickScroll}
                                                ReSizechat1={ReSizechat1}
                                                setCustomer={setCustomer}
                                                setCustomerData={setCustomerData}
                                                istextmarketing={istextmarketing}
                                                CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                cancelToken={cancelToken}
                                                setCancelToken={setCancelToken}
                                                markReadAndUnreadChat={markReadAndUnreadChat}
                                                ReactBusinessId={props.ReactBusinessId}
                                                noRecord={true}
                                                setnoRecord={setnoRecord}
                                                noRecordunread={noRecordunread}
                                                setnoRecordUnread={setnoRecordUnread}
                                                chatListSelection={chatListSelection}
                                                setChatListSelection={setChatListSelection}
                                                setPaging={setPaging}
                                                setscrollupdate={setscrollupdate}
                                                setTempChatData={setTempChatData}
                                                mergeConvertionData={mergeConvertionData}
                                                pushNotificationMessageId={pushNotificationMessageId}
                                                MerchantChannelId={MerchantChannelId}
                                                DefultShow={true}
                                                WelcomeScreen={true}
                                            />
                                        </div>
                                    </div>
                                }

                                {isNewChat === false && ShowFirst &&
                                    <div className="chatarea col">
                                        <div className="d-flex">
                                            <Convertions {...props}
                                                leftsideConversetionPenalRef={leftsideConversetionPenalRef}
                                                setConversetionChatSelection={setConversetionChatSelection}
                                                convertionData={convertionData}
                                                setConvertionData={setConvertionData}
                                                convertionDataRef={convertionDataRef}
                                                isNewChat={isNewChat}
                                                setIsNewChat={setIsNewChat}
                                                SendMessage={SendMessage}
                                                isLeftOption={isLeftOption}
                                                ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                expandMinimizeShow={expandMinimizeShow}
                                                onChange={onChange}
                                                upgradeUpCellShow={upgradeUpCellShow}
                                                setupgradeUpCellShow={setupgradeUpCellShow}
                                                ChatBoxCloseClick={ChatBoxCloseClick}
                                                convertionPageIndex={convertionPageIndex}
                                                setConvertionPageIndex={setConvertionPageIndex}
                                                paging={paging}
                                                isConversionDataChanged={isConversionDataChanged}
                                                tempEmployye={tempEmployye}
                                                settempEmployye={settempEmployye}
                                                tempCustomer={tempCustomer}
                                                settempCustomer={settempCustomer}
                                                setPinCount={setPinCount}
                                                pinCount={pinCount}
                                                unReadCountCustomer={unReadCountCustomer}
                                                setUnReadCountCustomer={setUnReadCountCustomer}
                                                unReadCountEmployee={unReadCountEmployee}
                                                setUnReadCountEmployee={setUnReadCountEmployee}
                                                unReadConversionCustomer={unReadConversionCustomer}
                                                setUnReadConvertionCustomer={setUnReadConvertionCustomer}
                                                unReadConversionEmployee={unReadConversionEmployee}
                                                setUnReadConvertionEmployee={setUnReadConvertionEmployee}
                                                searchText={searchText}
                                                setsearchText={setsearchText}
                                                tempConvertionData={tempConvertionData}
                                                setTempConvertionData={setTempConvertionData}
                                                setrestrictResize={setrestrictResize}
                                                ViewEmployee={ViewEmployee}
                                                ViewCustomer={ViewCustomer}
                                                newChatData={newChatData}
                                                handleAccordionToggle={handleAccordionToggle}
                                                activeKey={activeKey}
                                                setActiveKey={setActiveKey}
                                                // setIsIframeOpened={setIsIframeOpened}
                                                isScrollEnable={isScrollEnable}
                                                setnewChatData={setnewChatData}
                                                CustomerId={CustomerId}
                                                setIsViewChanging={setIsViewChanging}
                                                ReadUnreadCount={ReadUnreadCount}
                                                isAndroidIOSMobile={isAndroidIOSMobile}
                                                conClickScroll={conClickScroll}
                                                ReSizechat1={ReSizechat1}
                                                setCustomer={setCustomer}
                                                setCustomerData={setCustomerData}
                                                istextmarketing={istextmarketing}
                                                CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                cancelToken={cancelToken}
                                                setCancelToken={setCancelToken}
                                                markReadAndUnreadChat={markReadAndUnreadChat}
                                                ReactBusinessId={props.ReactBusinessId}
                                                noRecord={noRecord}
                                                setnoRecord={setnoRecord}
                                                noRecordunread={noRecordunread}
                                                setnoRecordUnread={setnoRecordUnread}
                                                chatListSelection={chatListSelection}
                                                setChatListSelection={setChatListSelection}
                                                setPaging={setPaging}
                                                setscrollupdate={setscrollupdate}
                                                setTempChatData={setTempChatData}
                                                mergeConvertionData={mergeConvertionData}
                                                pushNotificationMessageId={pushNotificationMessageId}
                                                MerchantChannelId={MerchantChannelId}
                                                countryID={CountryID}
                                                DefultShow={convertionDataCount > 0 ? true : false}
                                            />

                                            <ChatArea {...props} LeftOption={isLeftOption}
                                                EditInput={EditoerInput}
                                                messageConvertions={messageConvertions}
                                                setMessageConvertions={setMessageConvertions}
                                                pageIndex={pageIndex}
                                                setPageIndex={setPageIndex}
                                                tempChatData={tempChatData}
                                                setTempChatData={setTempChatData}
                                                SendMessage={SendMessage}
                                                BtnRightsidepenal={BtnRightsidepenal}
                                                ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                expandMinimizeShow={expandMinimizeShow}
                                                isNewChat={isNewChat}
                                                convertionData={convertionData}
                                                UserFromConversation={UserFromConversation}
                                                setUserFromConversation={setUserFromConversation}
                                                chatAreaShow={chatAreaShow}
                                                setChatAreaShow={setChatAreaShow}
                                                setConvertionData={setConvertionData}
                                                setShowFirstLoader={setShowFirstLoader}
                                                middleArea={middleArea}
                                                searchText={searchText}
                                                setsearchText={setsearchText}
                                                customerData={customerDataRef.current}
                                                ServiceproviderasperMultilocation={ServiceproviderasperMultilocation}
                                                serviceProvider={serviceProvider}
                                                OnChangeCustomer={OnChangeCustomer}
                                                setserviceproviderName={setserviceproviderName}
                                                newChatData={newChatData}
                                                customer={newChat ? CustomerId : undefined}
                                                sendResponse={sendResponse}
                                                SendSMS={SendSMS}
                                                ManageCustomerViewRights={ManageCustomerViewRights}
                                                LoginUserIDV2={LoginUserIDV2}
                                                ChatBoxCloseClick={ChatBoxCloseClick}
                                                conClickScroll={conClickScroll}
                                                isViewChanging={isViewChanging}
                                                setIsViewChanging={setIsViewChanging}
                                                UpsertChannel={UpsertChannel}
                                                groupMemberArrRef={groupMemberArrRef.current}
                                                serviceProviderHashId={serviceProviderHashId.current}
                                                isAndroidIOSMobile={isAndroidIOSMobile}
                                                CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                ReactBusinessId={props.ReactBusinessId}
                                                pushNotificationMessageId={pushNotificationMessageId}
                                                rightsidePenal={rightsidePenal}
                                                isFromAppResumeCallBack={isFromAppResumeCallBack}
                                                employeeGroupDetails={employeeGroupDetails}
                                                setConversetionChatSelection={setConversetionChatSelection}
                                                ManageCustomerModifyRights={ManageCustomerModifyRights}
                                                setnewChatSelectedEmployee={setnewChatSelectedEmployee}
                                                MerchantChannelId={MerchantChannelId}
                                                countryID={CountryID}
                                                RenderMentiontag={RenderMentiontag}
                                                CustomerId={CustomerId}
                                                employeeNewChat={EmployeeNewChat}
                                            />


                                            {!newChat &&
                                                <div ref={rightsidePenal} className={'rightSlide rightSlide-close'} >
                                                    {/* right side Customer detail Commopnent */}
                                                    {chatSelection.Type === LeftControlNames.Employee ?
                                                        <EmployeeDetails {...props} setConversetionChatSelection={setConversetionChatSelection} isNewChat={isNewChat} allserviceprovider={allserviceproviderforaddmember} convertionData={convertionData} setConvertionData={setConvertionData} employeeData={employeeData} employeeNewChat={EmployeeNewChat} SendMessage={SendMessage} isLeftOption={isLeftOption} ModifyEmployee={ModifyEmployee} FullName={FullName} searchText={searchText} setsearchText={setsearchText} isViewChanging={isViewChanging} setTempConvertionData={setTempConvertionData} setChatAreaShow={setChatAreaShow} CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat} ReactBusinessId={props.ReactBusinessId} isFromAppResumeCallBack={isFromAppResumeCallBack} employeeGroupDetails={employeeGroupDetails} UpsertChannel={UpsertChannel} MerchantChannelId={MerchantChannelId} CountryID={CountryID} />
                                                        :
                                                        <CustommerDetails {...props} setConversetionChatSelection={setConversetionChatSelection} isNewChat={isNewChat} isLeftOption={isLeftOption} Domain={Domain} ReactUserID={ReactUserID} ManageOtherPeopleCalendarViewRights={ManageOtherPeopleCalendarViewRights} searchText={searchText} setsearchText={setsearchText} isViewChanging={isViewChanging} rightsidePenal={rightsidePenal} convertionData={convertionData} setConvertionData={setConvertionData} ReactBusinessId={props.ReactBusinessId} isFromAppResumeCallBack={isFromAppResumeCallBack} UpsertChannel={UpsertChannel} MerchantChannelId={MerchantChannelId} CountryID={CountryID} SendMessage={SendMessage} />
                                                    }

                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {(expandMinimizeShow !== 0 && expandMinimizeShow !== 2) && chatAreaShow === false && isNewChat === false && ShowFirst === true &&
                                    <div className='collapsibleChat'>
                                        <EditoerInput {...props} customer={CustomerId}
                                            SendMessage={SendMessage} isNewChat={isNewChat}
                                            expandMinimizeShow={expandMinimizeShow} convertionData={convertionData}
                                            callBackChatConversation={window.callBackChatConversation}
                                            LeftOption={isLeftOption} customerData={customerDataRef.current}
                                            SendSMS={SendSMS} middleArea={middleArea} CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat} ReactBusinessId={props.ReactBusinessId}
                                            ManageCustomerViewRights={ManageCustomerViewRights}
                                            ManageCustomerModifyRights={ManageCustomerModifyRights} groupMemberArrRef={groupMemberArrRef.current}
                                            MerchantChannelId={MerchantChannelId}
                                        />
                                    </div>
                                }
                                {ShowFirst === false && <StenCiledContaint isFromConnectApp={isFromConnectApp} />}
                            </div>

                        </div>
                        }
                        {
                            isCustomerChat &&
                            <>
                                <div className="vConnectMainWrap">
                                    <div className={`smallChatBoxWrap${expandMinimizeChatBox === 2 ? " customer-extand" : ""}${expandMinimizeChatBox === 0 ? " customer-minimize" : ""}`}>
                                        <div className="smallChatBox-header">
                                            <div className="smallChatBox-header_title">
                                                Messages
                                            </div>

                                            <div className="smallChatBox-header_actions">
                                                <nav>
                                                    {isLivestreamEnable &&
                                                        <a href={false} className="contact-icon liveStreamCallButton" onClick={() => LiveStreamjoinroom(true, chatSelection?.conversationId)}>
                                                            <i class="fa-regular fa-video"></i>
                                                        </a>
                                                    }
                                                    {expandMinimizeChatBox !== 1 &&
                                                        <a href={false} className="extendButton fullscreen" onClick={() => ExpandMinimizeChatBoxButtonClick(expandMinimizeChatBox + 1)}>
                                                            <i className="fa-solid fa-arrow-up-right-and-arrow-down-left-from-center"></i>
                                                        </a>
                                                    }
                                                    {expandMinimizeChatBox !== 0 &&
                                                        <a className='extendButton arrowDown' href={false} onClick={() => ExpandMinimizeChatBoxButtonClick(expandMinimizeChatBox - 1)}>
                                                            <i className="fa-solid fa-chevron-down"></i>
                                                        </a>
                                                    }
                                                    <a className="extendButton close" href={false} onClick={() => ChatBoxCloseClick()}>
                                                        <i className="fa-solid fa-xmark" aria-hidden="true"></i>
                                                    </a>
                                                </nav>

                                            </div>
                                        </div>

                                        <div className="smallChatBox-middle">
                                            <div className='chatarea'>
                                                <ChatArea {...props}
                                                    LeftOption={isLeftOption}
                                                    EditInput={EditoerInput}
                                                    messageConvertions={messageConvertions}
                                                    setMessageConvertions={setMessageConvertions}
                                                    pageIndex={pageIndex}
                                                    setPageIndex={setPageIndex}
                                                    tempChatData={tempChatData}
                                                    setTempChatData={setTempChatData}
                                                    SendMessage={SendMessage}
                                                    BtnRightsidepenal={BtnRightsidepenal}
                                                    ExpandMinimizeButtonClick={ExpandMinimizeButtonClick}
                                                    isNewChat={isNewChat}
                                                    isCustomerChat={isCustomerChat}
                                                    UserFromConversation={UserFromConversation}
                                                    setUserFromConversation={setUserFromConversation}
                                                    chatAreaShow={chatAreaShow}
                                                    expandMinimizeShow={expandMinimizeShow}
                                                    setShowFirstLoader={setShowFirstLoader}
                                                    middleArea={middleArea}
                                                    searchText={searchText}
                                                    setsearchText={setsearchText}
                                                    customer={CustomerId}
                                                    customerData={customerDataRef.current}
                                                    sendResponse={sendResponse}
                                                    SendSMS={SendSMS}
                                                    ManageCustomerViewRights={ManageCustomerViewRights}
                                                    LoginUserIDV2={LoginUserIDV2}
                                                    ChatBoxCloseClick={ChatBoxCloseClick}
                                                    setIsViewChanging={setIsViewChanging}
                                                    conClickScroll={conClickScroll}
                                                    isViewChanging={isViewChanging}
                                                    isAndroidIOSMobile={isAndroidIOSMobile}
                                                    CheckLoginUserIsRemovedFromChat={CheckLoginUserIsRemovedFromChat}
                                                    rightsidePenal={rightsidePenal}
                                                    ManageCustomerModifyRights={ManageCustomerModifyRights}
                                                    countryID={CountryID}
                                                    CustomerId={CustomerId}
                                                    employeeNewChat={EmployeeNewChat}
                                                // RenderMentiontag={RenderMentiontag}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="smallChatBoxWrap d-none">
                                    <div className="smallChatBox">
                                        <div className="smallChatBox-header">
                                            <div className="smallChatBox-header_title">
                                                Messages
                                            </div>

                                            <div className="smallChatBox-header_actions">
                                                <nav>
                                                    {isLivestreamEnable &&
                                                        <a href={false} className="contact-icon liveStreamCallButton" onClick={() => LiveStreamjoinroom(true, chatSelection?.conversationId)}>
                                                            <i class="fa-regular fa-video"></i>
                                                        </a>
                                                    }
                                                    <a href="">
                                                        <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/chevron-down.svg" alt="" />
                                                    </a>
                                                    <a href="">
                                                        <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/closeIcon.svg" alt="" />
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="smallChatBox-middle">
                                            <div className="smallChatBox-middle_nameArea">
                                                <div className="smallChatBox-middle_nameArea-imgWrap">
                                                    <img src="https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/77west.png" alt="" />
                                                </div>
                                                Salon 77 West Pleasanton
                                            </div>

                                            <div className="smallChatBox-middle_messagesWrap">
                                                <div className="sent messagesBox">
                                                    <div className="messagesBox-block">
                                                        <div className="messages">
                                                            Hi I was wondering what’s your next appointment for haircut?
                                                        </div>
                                                        <div className="time">
                                                            10:42 AM
                                                            <span className="chechIcon"></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="recived messagesBox">

                                                    <div className="messagesBox-block">
                                                        <div className="name">
                                                            Salon 77 West Pleasanton
                                                        </div>
                                                        <div className="messages">
                                                            Hi! Before we start, can I get your full name and email address to help verify your identity?
                                                        </div>
                                                        <div className="time">10:45 AM</div>
                                                    </div>
                                                </div>

                                                <div className="sent messagesBox">
                                                    <div className="messagesBox-block">
                                                        <div className="messages">
                                                            Mike Meyers <br />
                                                            mkm1226@gmail.com
                                                        </div>
                                                        <div className="time">
                                                            10:46 AM
                                                            <span className="chechIcon"></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="recived messagesBox">

                                                    <div className="messagesBox-block">
                                                        <div className="name">
                                                            Salon 77 West Pleasanton
                                                        </div>
                                                        <div className="messages">
                                                            Nancy’s next availability for haircut is 10/2 at 2pm. Does that time work for you?
                                                        </div>
                                                        <div className="time">10:45 AM</div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                        <div className="smallChatBox-footer">
                                            <nav className="smallChatBox-footer_actions">
                                                <a href="">
                                                    <i className="fas fa-paperclip"></i>
                                                </a>
                                                <a href="">
                                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.0625 1.8125C16.0879 1.8125 16.9375 2.66211 16.9375 3.6875V13.0625C16.9375 14.1172 16.0879 14.9375 15.0625 14.9375H1.9375C0.882812 14.9375 0.0625 14.1172 0.0625 13.0625V3.6875C0.0625 2.66211 0.882812 1.8125 1.9375 1.8125H15.0625ZM15.5312 13.0625V3.6875C15.5312 3.45312 15.2969 3.21875 15.0625 3.21875H1.9375C1.67383 3.21875 1.46875 3.45312 1.46875 3.6875V13.0625C1.46875 13.3262 1.67383 13.5312 1.9375 13.5312H15.0625C15.2969 13.5312 15.5312 13.3262 15.5312 13.0625ZM13.6562 5.67969C13.9785 5.67969 14.2422 5.94336 14.2422 6.26562C14.2422 6.61719 13.9785 6.85156 13.6562 6.85156H11.6641V7.78906H13.1875C13.5098 7.78906 13.7734 8.05273 13.7734 8.375C13.7734 8.72656 13.5098 8.96094 13.1875 8.96094H11.6641V10.4844C11.6641 10.8359 11.4004 11.0703 11.0781 11.0703C10.7266 11.0703 10.4922 10.8359 10.4922 10.4844V6.26562C10.4922 5.94336 10.7266 5.67969 11.0781 5.67969H13.6562ZM8.96875 5.67969C9.29102 5.67969 9.55469 5.94336 9.55469 6.26562V10.4844C9.55469 10.8359 9.29102 11.0703 8.96875 11.0703C8.61719 11.0703 8.38281 10.8359 8.38281 10.4844V6.26562C8.38281 5.94336 8.61719 5.67969 8.96875 5.67969ZM7.09375 8.02344C7.41602 8.02344 7.67969 8.28711 7.67969 8.60938V9.86914C7.67969 10.0449 7.5918 10.1914 7.50391 10.3086C6.97656 10.8066 6.27344 11.0703 5.51172 11.0703C4.75 11.0703 4.04688 10.8066 3.51953 10.3086C3.02148 9.78125 2.75781 9.10742 2.75781 8.375C2.75781 7.67188 3.02148 6.99805 3.51953 6.4707C4.54492 5.47461 6.30273 5.47461 7.29883 6.4707C7.5332 6.70508 7.5332 7.08594 7.29883 7.32031C7.09375 7.55469 6.71289 7.55469 6.47852 7.32031C5.89258 6.73438 4.92578 6.73438 4.36914 7.32031C4.07617 7.61328 3.92969 7.99414 3.92969 8.375C3.92969 8.78516 4.07617 9.16602 4.36914 9.45898C4.86719 9.98633 5.89258 10.0449 6.50781 9.60547V9.19531H5.39453C5.07227 9.19531 4.80859 8.96094 4.80859 8.60938C4.80859 8.28711 5.07227 8.02344 5.39453 8.02344H7.09375Z" fill="#A2A0A2" />
                                                    </svg>
                                                </a>
                                            </nav>

                                            <div className="smallChatBox-footer_fieldWrap">
                                                <input type="text" className="inputField" placeholder="Enter a message." />
                                                <a href="" className="smileIcon">
                                                    <i className="far fa-smile" aria-hidden="true"></i>
                                                </a>
                                            </div>


                                        </div>

                                    </div>

                                </div>

                            </>
                        }
                        <input type="hidden" id="newChat" value={props.newChat} />
                        <input type="hidden" id="hdnChatConversionId" value="" />
                        {isFeaturePopup && ReactBusinessId !== 226 &&
                            <NewFeaturePopup SettingNavigate={() => onSettingNavigate()} />
                        }
                    </>
                } />
                <Route path="/setting/*" element={<VagaroAI {...props} isFromConnectApp={isFromConnectApp} isIOSMobile={isIOSMobile} isAndroidIOSMobile={isAndroidIOSMobile} defaultSize={defaultSize} ReactBusinessId={ReactBusinessId} ReactV2UId={LoginUserIDV2} ReactV2BId={merchantId} ModifyFeatureSubscription={ModifyFeatureSubscription} />} />
            </Routes>
        </>
    );
}

const MentionPopup = (props) => {
    const { elementHTML, element, chatSelectionIDhas, convertionData, isIOSMobile, AddCommonMethod, DefultShow = false } = props;
    const [show, setShow] = useState(DefultShow);
    const [popoverHeight, setPopoverHeight] = useState(260);
    const [placement, setPlacement] = useState('bottom');
    const target = useRef(null);
    const popoverRef = useRef(null);
    const tablet = isAndroidIOSTabIpad()
    const ipad = isIOSTabIpad()
    let lastTap = 0;

    useEffect(() => {
        if (popoverRef.current) {
            setPopoverHeight(popoverRef.current.offsetHeight);
        }
    }, [popoverRef.current]);

    // const checkSpace = () => {
    //     const rect = target.current.getBoundingClientRect();
    //     const spaceAtBottom = window.innerHeight - rect.bottom;
    //     if (spaceAtBottom < popoverHeight + 100) {
    //         setPlacement('top');
    //     } else {
    //         setPlacement('bottom');
    //     }
    // };

    const checkSpace = () => {
        const rect = target.current.getBoundingClientRect();
        const spaceAtBottom = window.innerHeight - rect.bottom;
        const spaceAtTop = rect.top;

        if (spaceAtBottom < popoverHeight + 100 && spaceAtTop >= popoverHeight + 100) {
            setPlacement('top');
        } else if (spaceAtBottom >= popoverHeight) {
            setPlacement('bottom');
        } else {
            setPlacement('bottom');  // Default to bottom if neither space is sufficient
        }
    };

    useLayoutEffect(() => {
        if (placement === 'top') {
            setTimeout(() => {
                adjustPopsoverPosition();
            }, 0);
        }
    }, [placement])


    const adjustPopsoverPosition = () => {
        console.log("itstop");

        const popover = popoverRef.current;
        const rect = popover.getBoundingClientRect();

        if (rect.top < 0) {
            popover.style.top = `${Math.abs(rect.top) + 10}px`; // Adjust downwards to ensure it's not cut off
        }
    };


    const handleClick = (event) => {
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - lastTap;

        if (timeDiff < 300 && timeDiff > 0) {
            event.preventDefault();
            event.stopImmediatePropagation();
        } else {
            setShow(prev => !prev);
        }

        lastTap = currentTime;
    }
    useEffect(() => {
        let check_class_name = element.classList.contains('mentionNameInmessage')
        if (element && (elementHTML || check_class_name)) {
            if (props.isAndroidIOSMobile === true) {
                if (chatSelectionIDhas?.toString() !== '1') {
                    element.addEventListener('click', (e) => { handleClick(e) });
                    element.addEventListener('touchend', (e) => {
                        if (isIOSMobile && !ipad && e.cancelable) {
                            e.target.click();
                        }
                    });
                    element.addEventListener('touchstart', (e) => {
                        if (ipad && e.cancelable) {
                            e.target.click();
                        }
                    });
                    return () => {
                        element.removeEventListener('click', (e) => { handleClick(e) });
                        element.removeEventListener('touchend', (e) => {
                            if (isIOSMobile && e.cancelable) {
                                e.target.click();
                            }
                        });
                        element.removeEventListener('touchstart', (e) => {
                            if (ipad && e.cancelable) {
                                e.target.click();
                            }
                        });
                    };
                } else {
                    element.addEventListener('click', () => CommonMethods.Actionclick("messagesAreaGroupDetail"));
                    return () => {
                        element.removeEventListener('click', () => CommonMethods.Actionclick("messagesAreaGroupDetail"));
                    };
                }
            } else {
                element.addEventListener('mouseenter', () => setShow(prev => !prev));
                return () => {
                    element.removeEventListener('mouseenter', () => setShow(prev => !prev));
                };
            }
        }
    }, [element]);

    useEffect(() => {
        props.onToggle(show);
        if (show) {
            if (typeof checkSpace === 'function') {
                checkSpace();
            }
            AddCommonMethod("CloseEmployeedetails", () => { setShow(false) });
        }
    }, [show]);


    return (
        <span onMouseLeave={() => setShow(false)}>
            <OverlayTrigger
                placement={placement}
                show={show}
                overlay={
                    <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className='employeeDetailPophoverWrap'>
                        <Popover id="popover-basic" className='employeeDetailPophover' ref={popoverRef}>
                            <EmployeeDetailPopover  {...props} convertionData={convertionData} />
                        </Popover>
                    </div>
                }
            >
                {elementHTML ?
                    <span ref={target} dangerouslySetInnerHTML={{ __html: elementHTML }}></span>
                    :
                    <span ref={target}>{props.element.dataset.name}</span>
                }
            </OverlayTrigger>
        </span>
    );
};


export default VagaroConnect;
